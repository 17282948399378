import React from 'react'
import PropTypes from 'prop-types';
import DiagramBlock from "./DiagramBlock.jsx"
import Arrow from "./Arrow.jsx"

export default class DiagramTree extends React.Component {

    constructor(props) {
        super(props);
        this.wrap = this.wrap.bind(this);
        this.blocks = this.props.blocks;
        this.rendered_blocks = [];
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props !== nextProps;
    }

    componentDidMount() {
        this.rendered_blocks = [];
        this.blocks = this.props.blocks;
        console.log(this.blocks);
    }

    wrap(parent = 0, index = 0, current_level = 0, only_arrows = false) {
        let w = 170;  // ширина блока
        let h = 44 + 75;  // высота уровня (стрелок и блока)
        let counts = [];
        let items = parent == 0 ? [0] : parent.next;
        let links = parent == 0 ? [0] : parent.links;
        let inner_blocks = !only_arrows ? links.map(function (block_id, index) {
            if (block_id != null) {
                let block = this.blocks[block_id];
                let res = <div key={index} style={{ minWidth: parent != 0 && !parent.next.includes(block_id) ? 0 : w + "px" }}></div>;
                if (this.rendered_blocks.includes(block_id) === false) {
                    if ((block.type == "condition" && (block.links[0] != null || block.links[1] != null)) || block.links[0] != null)
                        res = this.wrap(block, index, current_level + 1, parent != 0 && !parent.next.includes(block_id));
                    counts.push(block.countBlocksWidth);
                }
                return res;
            }
        }.bind(this)) : false;
        let count = counts.length > 0 ? counts.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
        let inner_container_width = count == 0 ? w : count * w;
        let styles_for_container = { width: inner_container_width + "px", display: "flex", alignItems: "flex-start", justifyContent: "center" };
        let main_width = items.length > 0 ? items[0] == 0 ? this.blocks[items[0]].countBlocksWidth : parent.countBlocksWidth : 1;
        if (only_arrows) main_width = 0;
        return <div className="blocks_wrapper" key={index} style={{ minWidth: main_width * w + "px" }}>
            {(!only_arrows && items[0] != 0) && <div className="blocks_arrows">
                {parent.links.map(function (block_id, index) {
                    let block = this.blocks[block_id];
                    if (block_id != null) {
                        let pos = "center";
                        let arrow_direction = 0;
                        let arrow_width = 0;
                        let arrow_text = "";
                        let is_custom = false;
                        let height_type = (parent.next[0] == null && parent.next[1] == null) || parent.next.length == 0;  // эта переменная определяет какую часть стретки увеличивать в высоту, если тру, то верхнюю
                        let height_by_levels = block.level - parent.level - 1;
                        let margin_top_by_levels = parent.level - (current_level - 1) < 0 ? 0 : parent.level - (current_level - 1);
                        if (height_by_levels == 0)
                            margin_top_by_levels = block.level - current_level < 0 ? 0 : block.level - current_level;

                        let arrow_height = height_by_levels * h;

                        // ожидаемое количество не кастомных стрелок
                        let len = parent.links[0] == null || parent.links[1] == null || !parent.next.includes(parent.links[0]) || !parent.next.includes(parent.links[1]) ? 1 : 2;

                        // кастомная срелка, если блок не пренадлежит этому родителю
                        if (this.rendered_blocks.includes(block_id) === true || !parent.next.includes(block_id)) {
                            arrow_direction = block.location - parent.location > 0 ? 1 : -1;
                            arrow_text = parent.type == "operation" ? "" : parent.links[1] == block.inner_id ? "Да" : "Нет";
                            arrow_width = (Math.abs(block.location - parent.location) * w + 2) / 2;
                            arrow_height -= 12;
                            is_custom = true;
                        } else if (parent.next.includes(block_id)) {  // обычная стрелка вниз/влево/вправо
                            if (parent.type == "condition") {  // если родитель - условие
                                if (len == 1) {
                                    arrow_direction = 0;
                                    arrow_width = 9.5;
                                    arrow_text = parent.next[0] == null ? "Да" : "Нет";
                                } else {
                                    // стрелка "нет"
                                    if (index == 0 && parent.links[0] != null) {
                                        pos = "flex-end";
                                        arrow_direction = -1;
                                        arrow_width = counts[1] * w / 2;
                                        arrow_text = "Нет";
                                    } else { // стрелка "да"
                                        pos = "flex-start";
                                        arrow_direction = 1;
                                        arrow_width = counts[0] * w / 2;
                                        arrow_text = "Да";
                                    }
                                }
                            } else {  // если родитель действие или начало
                                arrow_width = 9.5
                            }
                        }
                        let style_for_wrapper_arrows = { width: 1 / items.length * 100 + "%", display: "flex", justifyContent: pos, marginTop: margin_top_by_levels * h + "px" };
                        if (is_custom) {

                            style_for_wrapper_arrows["position"] = "absolute";
                            if (arrow_direction > 0) {
                                style_for_wrapper_arrows["marginLeft"] = (arrow_width - 3) / 2 + "px"; //(100 - (1 / items.length *100)) + "%";
                            } else if (arrow_direction < 0) {
                                style_for_wrapper_arrows["marginLeft"] = -(arrow_width - 3) / 2 + 1 + "px";
                            }
                            arrow_width += 3;
                        }

                        // эти параметры нужны для удаления стрелки
                        let params = {
                            "deleteable": is_custom,
                            "parent": parent,
                            "child": block,
                        };
                        return <div style={style_for_wrapper_arrows}
                            key={index}>
                            <Arrow params={params} onDel={this.props.onUnbind} is_custom={is_custom} direction={arrow_direction} width={arrow_width} height={arrow_height} height_type={height_type} text={arrow_text} />
                        </div>
                    }
                }.bind(this))}
            </div>}
            {!only_arrows && <div className="blocks_level">
                {items.map(function (block_id, index) {
                    if (block_id != null) {
                        if (this.rendered_blocks.includes(block_id) === false) {
                            let block = this.blocks[block_id];
                            this.rendered_blocks.push(block_id);
                            let margin_top_by_levels = block.level - current_level < 0 ? 0 : block.level - current_level;

                            return <div style={{
                                width: counts[index] / count * 100 + "%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: margin_top_by_levels * h + "px"
                            }} key={index}>
                                <DiagramBlock data={block} onClick={this.props.onClick} onEdit={this.props.onEdit}
                                    onDel={this.props.onDel} onAdd={this.props.onMenu} />
                            </div>
                        } else {
                            return <div key={index}></div>;
                        }
                    }
                }.bind(this))}
            </div>}
            {!only_arrows && <div style={styles_for_container}>
                {inner_blocks}
            </div>}
        </div>
    }

    render() {

        //this.tree = this.wrapBlocks(this.props.blocks, [0]);
        //console.log(this.blocks);
        let wrapped_data = this.wrap();
        //let blocks = wrapped_data[1];
        //let count_len = wrapped_data[0];
        return (
            <div className="diagram_area" style={{ width: this.blocks[0].countBlocksWidth * 170 + 100 + "px" }}>
                {wrapped_data}
            </div>
        );
    }
}

DiagramTree.propTypes = {
    blocks: PropTypes.object.isRequired,
    onMenu: PropTypes.func.isRequired,
    onDel: PropTypes.func.isRequired,
    onUnbind: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    start: PropTypes.array.isRequired
};

