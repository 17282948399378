import React from "react";
import Selection from "./Selection";
import shortid from "shortid";
import ModalConfirmation from "../parts/ModalConfirmation.jsx";
import { Link } from "react-router-dom";

export default class Data extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    var entity_number = location.pathname;
    entity_number = entity_number.substring(1, entity_number.length - 1);
    entity_number = entity_number.split("/");
    entity_number = entity_number[entity_number.length - 1];
    this.state = {
      save_button: false,
      entity_number: entity_number,
      data: {},
      alias: "",
      key_replacement: {},
      loading: true,
      modal_confirmation: false,
    };
  }

  componentDidMount() {
    // console.log('Data: componentWillMount');
    UI.query("/api/sources/?entity_number=" + this.state.entity_number).then(
      function (data) {
        console.log("data: UI.get data, ", data);
        let s = this.state;
        s.data = data["data"];
        Object.keys(data["data"]).map((item) => {
          s.key_replacement[item] = item;
        });
        s.old_data = JSON.stringify(data["data"]);
        s.alias = data["alias"];
        s.loading = false;
        this.setState(s, this.initiateMaterialize());
      }.bind(this),
      function () {
        this.props.history.push("/");
      }.bind(this),
    );
  }

  check_save = () => {
    console.log("check save");
    if (JSON.stringify(this.state.data) != this.state.old_data) {
      let save_status = true;
      var keys = Object.keys(this.state.key_replacement);
      for (let i; i < keys.length; i++) {
        if (keys[i] == "") {
          save_status = false;
          break;
        }
      }
      this.setState({ save_button: save_status });
    }
  };

  saveEntities = () => {
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    Object.keys(this.state.key_replacement).map((key, index) => {
      if (key != this.state.key_replacement[key]) {
        new_data[this.state.key_replacement[key]] = new_data[key];
        delete new_data[key];
      }
    });
    var parcel = {
      new_data: new_data,
      entity_number: this.state.entity_number,
    };
    UI.post("/api/sources/", parcel).then(
      function (data) {
        this.setState({
          data: data.data,
          old_data: JSON.stringify(data.data),
          save_button: false,
          modal_confirmation: true,
        });
      }.bind(this),
    );
  };

  handleDataKeyChange = (response) => {
    // console.log('handleDataKeyChange, ', response)
    // let new_data = JSON.parse(JSON.stringify(this.state.data))
    // new_data[response.new_value] = data[response.old_value]
    // delete new_data[response.old_value]
    // this.setState({
    //   data: new_data,
    //   save_button: true
    // });
    var key_replacement = this.state.key_replacement;
    key_replacement[response.old_value] = response.new_value;
    this.setState(
      {
        key_replacement: key_replacement,
        // save_button: true
      },
      this.check_save(),
    );
  };

  handleDataItemChange = (response) => {
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    new_data[response.parent][response.item] = response.value;
    if (response.item == "type" && response.value == "multiselect") {
      new_data[response.parent]["selection"] = [""];
    } else if (response.item == "type" && response.value != "multiselect") {
      if ("selection" in new_data[response.parent]) {
        delete new_data[response.parent]["selection"];
      }
    }
    this.setState(
      {
        data: new_data,
        // save_button: true
      },
      this.check_save(),
    );
  };

  handleOptionChange = (response) => {
    // console.log('data: handle option change');
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    new_data[response.parent][response.item][response.key] = response.value;
    this.setState(
      {
        data: new_data,
        // save_button: true
      },
      this.check_save(),
    );
  };

  handleAddField = () => {
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    let new_key = shortid.generate();
    new_data[new_key] = {
      // new_data[""] = {
      title: "",
      type: "string",
    };
    let s = this.state;
    s.data = new_data;
    s.key_replacement[new_key] = "";
    this.setState(s, this.check_save());
  };

  handleDeleteDataField = (response) => {
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    let new_key_replacement = JSON.parse(JSON.stringify(this.state.key_replacement));
    // delete new_data_block[response.key]
    delete new_data[response.key];
    delete new_key_replacement[response.key];
    this.setState(
      {
        data: new_data,
        key_replacement: new_key_replacement,
        // save_button: true
      },
      () => {
        this.check_save();
      },
    );
  };

  handleAddOption = (response) => {
    console.log("handleAddOption: response, ", response);
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    // do {
    //   var new_id = Math.floor(Math.random() * (9999999 - 1000000) + 1000000);
    // } while (new_id in new_data[response.parent]["selection"]);
    var new_id = parseInt(Object.keys(new_data[response.parent]["selection"]).sort()[Object.keys(new_data[response.parent]["selection"]).length - 1]) + 1;
    console.log("new_id, ", new_id);
    new_data[response.parent]["selection"][new_id] = "";
    this.setState({
      data: new_data,
      // save_button: true
    });
  };

  handleDeleteOption = (response) => {
    // console.log("data: handle delete option");
    let new_data = JSON.parse(JSON.stringify(this.state.data));
    delete new_data[response.parent]["selection"][response.key];
    this.setState({
      data: new_data,
      // save_button: true
    });
    this.check_save();
  };

  closeModalConfirmation = () => {
    this.setState({ modal_confirmation: false });
  };

  render() {
    var entity_number = this.state.entity_number;
    let data = !this.state.loading && this.state.data;
    let data_array =
      !this.state.loading &&
      Object.keys(data).map((key, index) => {
        let i = data[key];
        if (i.selection) {
          var selection = i.selection;
          var button = (
            <div style={{ cursor: "pointer" }} onClick={(e) => this.handleAddOption({ parent: key })}>
              + Добавить
            </div>
          );
        } else {
          var selection = [];
          var button = "";
        }
        let default_value = "";
        switch (i.type) {
          case "select":
            default_value = "multiselect";
            break;
          case "textarea":
            default_value = "string";
            break;
          case "text":
            default_value = "string";
            break;
          default:
            default_value = i.type;
        }
        return (
          <div className="data-row" key={key}>
            <div className="data-field-id">
              {/* <label className="active">Введите ID поля</label> */}
              <input
                defaultValue={this.state.key_replacement[key]}
                onBlur={(e) =>
                  this.handleDataKeyChange({
                    old_value: key,
                    new_value: e.target.value,
                  })
                }></input>
            </div>
            <div className="data-field-name">
              {/* <label className="active">имя поля:</label> */}
              <input
                defaultValue={i.title}
                onBlur={(e) =>
                  this.handleDataItemChange({
                    parent: key,
                    item: "title",
                    value: e.target.value,
                  })
                }></input>
            </div>
            <div className="data-field-type">
              {/* <label className="active">Тип поля:</label> */}
              <select
                className="select-dropdown"
                defaultValue={default_value}
                onChange={(e) =>
                  this.handleDataItemChange({
                    parent: key,
                    item: "type",
                    value: e.target.value,
                  })
                }>
                <option value="string">Текстовое</option>
                <option value="date">Дата</option>
                <option value="number">Число</option>
                <option value="multiselect">Мультиселект</option>
                <option value={default_value}>Другое</option>
              </select>
            </div>

            {/* <div className="selection"> */}
            {i.type == "multiselect" ? (
              <Selection key={key} index={key} selection={selection} button={button} onChange={this.handleOptionChange} onClick={this.handleDeleteOption}></Selection>
            ) : (
              <div></div>
            )}
            {/* {selection.map((item, index) =>
                <div key={index}>
                  <input defaultValue={item} onChange={(e) => this.handleOptionChange({ parent: key, item: "selection", index: index, value: e.target.value })}></input>
                  <svg className="delete_input_field" onClick={() => this.handleDeleteOption({ parent: key, item: "selection", index: index })} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.54" fillRule="evenodd" clipRule="evenodd" d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z" fill="black" />
                  </svg>
                </div>)}
              {button}
            </div> */}
            {/* <div style={{ flexGrow: "999", minWidth: "40px" }}></div> */}
            {/* <div className="segments-cell-dots"> */}
            <div className="data-row-delete" onClick={() => this.handleDeleteDataField({ key: key })}>
              Удалить
            </div>

            {/* </div> */}
          </div>
        );
      });
    return (
      <React.Fragment>
        <div className="content">
          <div className="page_header">
            <div className="page_title">
              {" "}
              <Link className="back" to={URLMAP.get("sources")}>
                <div style={{ display: "flex" }}>
                  {/* <svg
              height="24"
              viewBox="0 -4 483.6783 483"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              id="svg_resize"
            >
              <path
                d="m187.679688 84.015625h-8v-75.679687l-171.679688 171.679687 171.679688 171.683594v-75.683594h21.519531c107.996093-.007813 206.921875 60.402344 256.238281 156.480469l18.242188 35.519531v-96c0-159.054687-128.945313-288-288-288zm0 0"
                fill="#6dccf8"
              ></path>
              <path
          d="m187.679688 76.015625v-67.679687c-.003907-3.234376-1.953126-6.148438-4.941407-7.386719s-6.429687-.554688-8.714843 1.730469l-171.679688 171.683593c-3.125 3.121094-3.125 8.1875 0 11.308594l171.679688 171.679687c2.285156 2.289063 5.726562 2.972657 8.714843 1.734376 2.988281-1.238282 4.9375-4.152344 4.941407-7.386719v-67.683594h13.519531c105.070312-.277344 201.378906 58.535156 249.117187 152.136719l18.242188 35.519531c1.683594 3.292969 5.40625 5 9 4.128906 3.59375-.867187 6.125-4.085937 6.121094-7.785156v-96c-.1875-163.398437-132.601563-295.8125-296-296zm280 358.914063-3.128907-6.082032c-50.464843-98.953125-152.273437-161.128906-263.351562-160.832031h-21.519531c-4.417969 0-8 3.582031-8 8v56.371094l-152.371094-152.371094 152.371094-152.367187v56.367187c0 4.421875 3.582031 8 8 8h8c154.566406.179687 279.820312 125.433594 280 280zm0 0"
          fill="#6dccf8"
        ></path>
            </svg> */}
                  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.0004 6H10.9004C10.3481 6 9.90039 6.44772 9.90039 7C9.90039 7.55228 10.3481 8 10.9004 8H17.0004C17.5527 8 18.0004 7.55228 18.0004 7C18.0004 6.44772 17.5527 6 17.0004 6Z"
                      fill="#878799"
                    />
                    <path
                      d="M1.8385 6.71523C1.67474 6.61846 1.67474 6.38153 1.8385 6.28477L9.87282 1.53722C10.0395 1.43874 10.25 1.55887 10.25 1.75245V11.2476C10.25 11.4411 10.0395 11.5613 9.87282 11.4628L1.8385 6.71523Z"
                      stroke="#878799"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
              </Link>
              Редактирование полей
            </div>
            {this.state.save_button && (
              <button className="save-entities-button" onClick={this.saveEntities}>
                Сохранить
              </button>
            )}
          </div>

          <div className="sub_header">
            {!this.state.loading && (
              <div
                id="enity_name"
                className="page_sub_title"
                onClick={this.startEditing}
                name="name"
                style={{
                  // maxWidth: "320px",
                  width: `${this.state.alias.length * 18}px`,
                  // textOverflow: "ellipsis",
                  // overflow: "hidden",
                  // whiteSpace: "nowrap"
                }}>
                {this.state.alias}
              </div>
            )}
          </div>
          <div className="page_main">
            <div className="data-table-headings">
              <div className="data-field-id">ID поля</div> <div className="data-field-name">Название поля</div>
              <div className="data-field-type">Тип поля</div> <div className="data-cell-created">Параметры</div> <div style={{ flexGrow: "999" }}></div>
              <div className="segments-cell-dots"></div>
            </div>
            <div className="all-data-div">{data_array}</div>
          </div>
          <div className="page_footer">
            <button className="add-data_field-button" onClick={this.handleAddField}>
              Добавить поле
            </button>
            {this.state.modal_confirmation && <ModalConfirmation text="Сохранено" onDelete={this.closeModalConfirmation} />}
          </div>
        </div>
      </React.Fragment>
    );
  }
  initiateMaterialize = () => {
    var elems = document.querySelectorAll("select");
    var options = {};
    var instances = M.FormSelect.init(elems, options);
  };
  componentDidUpdate() {
    var elems = document.querySelectorAll("select");
    var options = {};
    var instances = M.FormSelect.init(elems, options);
  }
}
