import React, { PureComponent } from "react";
// import Data from "./Data";
import { Link } from "react-router-dom";
import ModalAgreement from "../parts/ModalAgreement.jsx";

export default class Entity extends PureComponent {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.state = {
      modal_agreement: false,
    };
  }
  handleEntityChildrenChange = (response) => {
    // console.log("handleEntityChildrenChange");
    //copy initial entity
    let new_entity = JSON.parse(JSON.stringify(this.props.entity));
    //update entity here
    new_entity[response.item] = response.value;
    //return updated entity to App
    this.props.onChange({ index: this.props.index, new_entity: new_entity });
  };

  closeModalAgreement = () => {
    this.setState({ modal_agreement: false });
  };

  openModalAgreement = () => {
    this.setState({ modal_agreement: true });
  };

  confirmDelete = (data) => {
    this.setState({ modal_agreement: false }, this.props.onDelete(data));
  };

  // handleFieldsChildrenChange = (response) => {
  //   //copy initial entity
  //   let new_entity = JSON.parse(JSON.stringify(this.props.entity))
  //   //update entity here
  //   new_entity.fields[response.item] = response.value
  //   //return updated entity to App
  //   this.props.onChange({ index: this.props.index, new_entity: new_entity })
  // };
  // rememberCurrentEntityName() {
  //   UI.current_entity = {
  //     name: this.props.data.name,
  //     id: this.props.data.id
  //   };
  // }

  render() {
    let phone;
    if (typeof this.props.entity == "object" && "fields" in this.props.entity && "phone" in this.props.entity.fields) {
      phone = (
        <div className="input-field">
          <div className="field-name">Телефон:</div>
          {/* <input value={this.props.entity.fields.phone} onChange={(e) => this.handleFieldsChildrenChange({ item: "phone", value: e.target.value })}></input> */}
          <span>{this.props.entity.fields.phone}</span>
          {/* <label className="active">Телефон:</label> */}
        </div>
      );
    }
    let email;
    if (typeof this.props.entity == "object" && "fields" in this.props.entity && "email" in this.props.entity.fields) {
      email = (
        <div className="input-field">
          <div className="field-name">Почта:</div>
          {/* <input value={this.props.entity.fields.email} onChange={(e) => this.handleFieldsChildrenChange({ item: "email", value: e.target.value })}></input> */}
          <span>{this.props.entity.fields.email}</span>
          {/* <label className="active">Почта:</label> */}
        </div>
      );
    }

    return (
      <div className="entity">
        {/* <button className="btn" onClick={() => this.props.onDelete({ index: this.props.index })}>
          Похерить
        </button> */}
        <div className="input-field source-name">
          <input
            placeholder="Название"
            value={this.props.entity.name}
            onChange={(e) =>
              this.handleEntityChildrenChange({
                item: "name",
                value: e.target.value,
              })
            }></input>
          {/* <label className="active">Название:</label> */}
          <div className="dots dropdown-trigger" data-target={"dropdown-" + this.props.index}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12Z"
                fill="#C4C4C4"
              />
            </svg>
          </div>
          <ul id={"dropdown-" + this.props.index} className="dropdown-content">
            <li name="role">
              <Link
                to={URLMAP.get("data", this.props.index)}
                // onClick={
                //   (this.rememberCurrentEntityName)
                // }
              >
                Редактировать
              </Link>
            </li>
            {this.props.index != 0 && (
              <li name="role" onClick={this.openModalAgreement}>
                <span>Удалить</span>
              </li>
            )}
          </ul>
        </div>
        <div className="input-field">
          <div className="field-name">Алиас:</div>
          <input
            value={this.props.entity.alias}
            onChange={(e) =>
              this.handleEntityChildrenChange({
                item: "alias",
                value: e.target.value,
              })
            }></input>
        </div>
        <div className="fields">
          {/* <div>
            external id: <input value={this.props.entity.fields.ext_id} onChange={(e) => this.handleFieldsChildrenChange({ item: "ext_id", value: e.target.value })}></input>
          </div> */}
          {phone}
          {email}
          {/* <Data data={this.props.entity.fields.data} onChange={this.handleFieldsChildrenChange}></Data> */}
        </div>
        {this.state.modal_agreement && (
          <ModalAgreement
            title="Удалить источник?"
            text="Удаление источника приведет к его потере и связанных с ним данных"
            onDelete={this.closeModalAgreement}
            confirm={this.confirmDelete}
            index={this.props.index}
          />
        )}
      </div>
    );
  }
  componentDidMount() {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }
  componentDidUpdate() {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }
}
