import PropTypes from 'prop-types';
import Rules from "./Rules.js"

export default class Block {

    constructor(props) {
        this.serialize = this.serialize.bind(this);
        this.del = this.del.bind(this);
        this.reset = this.reset.bind(this);
        //this.setLevel = this.setLevel.bind(this);
        this.setMainParent = this.setMainParent.bind(this);
        this.setParents = this.setParents.bind(this);
        this.setChildren = this.setChildren.bind(this);
        this.setCountBlocksWidth = this.setCountBlocksWidth.bind(this);
        this.setMode = this.setMode.bind(this);
        this.clone = this.clone.bind(this);

        this.inner_id = props.inner_id;
        this.mode = props.mode;
        this.limit = props.limit;
        this.count_customers = props.count_customers;
        this.type = props.type;
        this.next = props.next;
        this.rules = new Rules({mode: this.mode, task: this.task});
        this.rules.init(props.rules);
        this.links = props.links;
        this.children = [].concat(this.next); // TODO возожно нужно не next, а links
        this.parents = [];
    }

    setMode(mode) {
        this.mode = mode;
        //this.rules.clear();
        this.rules.setMode(this.mode);
    }

    setType(type) {
        this.type = type;
        this.rules.clear();
        this.rules.type = this.type;
    }

    serialize() {
        if (this.type == "condition") {
            this.next[1] = this.next[1] == undefined ? null : this.next[1];
            this.links[1] = this.links[1] == undefined ? null : this.links[1];
        }
        this.next[0] = this.next[0] == undefined ? null : this.next[0];
        this.links[0] = this.links[0] == undefined ? null : this.links[0];
        return {
            inner_id: this.inner_id,
            mode: this.mode,
            next: this.next,
            links: this.links,
            rules: this.rules.serialize(),
            type: this.type,
            limit: this.limit,
        }
    }

    del() {
        let parent_blocks = [this.parent];
        parent_blocks.forEach(function (block) {
            ["next", "links"].forEach(function(prop) {
                if (block[prop].includes(this.inner_id)) {
                    let index = block[prop].indexOf(this.inner_id);
                    block[prop][index] = null;
                    return false;
                }
            }.bind(this));
            block.reset();
        }.bind(this));
    }

    /*setLevel(level) {
        this.level = this.level == undefined ? level : this.level < level ? level : this.level;
    }*/

    setMainParent(parent) {
        this.parent = parent;
        if (parent.type == "condition")
            this.pos = parent.next[1] == this.inner_id;
        this.setParents(parent);
    }

    setParents(parent) {
        // let current_parent = parent;
        this.parents.push(parent.inner_id);
        /*while (current_parent != undefined) {
            if (!this.parents.includes(current_parent.inner_id))
                this.parents.push(current_parent.inner_id);
            current_parent = current_parent.parent;
        }*/
    }

    setChildren(new_children) {
        new_children.forEach(function (code) {
            if (!this.children.includes(code))
                this.children.push(code);
        }.bind(this));
    }

    setCountBlocksWidth(counts) {
        let count = counts.length > 0 ? counts.reduce((accumulator, currentValue) => accumulator + currentValue) : 0;
        let count_next = 0;
        this.next.forEach(function (item) {
            if (typeof item == "number")
                count_next++;
        });
        let main_count = count_next > count ? count_next : count;
        if (main_count == 0)
            main_count = 1;
        this.countBlocksWidth = main_count;
        return main_count;
    }

    reset() {
        this.children = [].concat(this.next);
        this.parents = [];
    }

    clone() {
        let new_block = new Block(this.serialize());
        new_block.rules = this.rules;
        new_block.parent = this.parent;
        new_block.pos = this.pos;
        new_block.parents = this.parents;
        new_block.children = this.children;
        new_block.count_customers = this.count_customers;
        return new_block;
    }
}

Block.PropTypes = {
    inner_id: PropTypes.number.isRequired,
    mode: PropTypes.string.isRequired,
    next: PropTypes.array.isRequired,
    rules: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    links: PropTypes.string.isRequired,
    count_customers: PropTypes.number.isRequired,
};