export default class UI {

    pass(callback) {
        console.log("pass");
        if (callback != undefined && callback !== null && typeof callback === 'function') {
            callback();
        }
    }

    // проверка поля на заполненность
    isFilled(obj) {
        if (typeof obj != "number") {
            if (obj == undefined || obj.length == 0)
                return false;
            if (typeof obj == "string") {
                if (obj.trim().length == 0) {
                    return false;
                } else {
                    return true;
                }
            } else if (obj.value.length == 0) {
                obj.classList.add("field_error");
                return false;
            } else
                obj.classList.remove("field_error");
        }
        return true;
    };

    // делаем запрос в Promise
    query(url, data = null, method = "GET") {
        return new Promise(function (resolve, reject) {
            let headers = {
                Authorization: `JWT ${localStorage.getItem('token')}`
            };
            let options = {
                method: method
            };
            if (typeof data == "object" && data !== null && Object.keys(data).length > 0) {
                options["body"] = JSON.stringify(data);
                if (method == "GET")
                    options["method"] = "POST";
                headers['Content-Type'] = 'application/json';
            }
            options["headers"] = headers;
            fetch(url, options)
                .then(res => {
                    let status = "error";
                    let resp = null;
                    if (res.status < 300) {
                        resp = res.json();
                        status = resp["status"] || "success";
                        if (status == "success") {
                            resolve(resp);
                        } else {
                            reject(resp);
                        }
                    } else {
                        res.json().then(function (obj) { reject(obj) });
                    }
                })

        });
    }

    get(url) {
        return this.query(url, "GET");
    }
    post(url, data) {
        return this.query(url, data, "POST");
    }
    put(url, data) {
        return this.query(url, data, "PUT");
    }
    delete(url, data) {
        return this.query(url, data, "DELETE");
    }

    // делает запрос (функция устарела)
    // pquery(method, url, data, callback) {
    //     let headers = {
    //         Authorization: `JWT ${localStorage.getItem('token')}`
    //     };
    //     let options = {
    //         method: method
    //     };
    //     if (data != undefined && method == "POST") {
    //         options["body"] = JSON.stringify(data);
    //         headers['Content-Type'] = 'application/json';
    //     }
    //     options["headers"] = headers;
    //     fetch(url, options)
    //         .then(res => {
    //             let data = {
    //                 status: "error"
    //             };
    //             if (res.status == 200) {
    //                 let resp = res.json();
    //                 if ( !("includes" in resp) && ("status" in resp)) {  // если пришел объект
    //                     data["status"] = resp["status"];
    //                 } else {  // если пришел массив или в объекте нет статуса
    //                     data["status"] = "success";
    //                 }
    //                 return [data["status"], resp];
    //             }
    //             return [data["status"], null];
    //         })
    //         .then(d => {
    //             if (callback != undefined && callback !== null && typeof callback === 'function')
    //                 if (d[1] != null) {
    //                     d[1].then(function (data) {
    //                         callback(d[0], data);
    //                     })
    //                 } else {
    //                     callback(d[0], null);
    //                 }
    //         });
    // }

}
