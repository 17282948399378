import React from 'react'
import PropTypes from 'prop-types';
const Velocity = require('../../../../node_modules/velocity-react/lib/velocity-animate-shim');

export default class HistoryItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "open": false,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentWillAppear(callback) {
        let root = document.getElementById(this.props.data.id);
        Velocity(root, 'fadeIn', { duration: 500, display: "flex" }).then(callback);
    }
    componentWillEnter(callback) {
        let root = document.getElementById(this.props.data.id);
        Velocity(root, 'fadeIn', { duration: 0, display: "flex" });
        Velocity(root, 'slideDown', { duration: 500, display: "flex" }).then(callback);
    }
    componentWillLeave(callback) {
        let root = document.getElementById(this.props.data.id);
        Velocity(root, 'fadeIn', { duration: 0, display: "flex" });
        Velocity(root, 'slideUp', { duration: 300, display: "flex" }).then(callback);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props.data !== nextProps.data;
    }

    toggle() {
        let s = this.state;
        if (this.props.data.action != "error") {
            s.open = !s.open;
            this.setState(s);
        }
    }

    render() {
        const log = this.props.data;
        return (
            // <div onClick={this.toggle} className={"row_item " + style_class} id={log.id} style={{ opacity: "0", cursor: "pointer" }}>
            <div id={log.id} className="history-row" >
                <div className="history-row-main">
                    <div className="history-table-cell history-cell-date">{log.date}</div>
                    <div className="history-table-cell history-cell-author" title={log.author}>{log.author}</div>
                    {/* <div className="history-table-cell history-cell-time">{log.date.substring(11)}</div> */}
                    <div className="history-table-cell history-cell-note" title={log.note}>{log.note}</div>
                    <div className="history-table-cell history-cell-note" title={log.target}>{log.target}</div>
                    <div className="history-table-cell history-cell-look" onClick={this.toggle}>
                        <img
                            className={this.state.open ? "angles-down" : ""}
                            src="../static/images/down-arrows-icon.svg"
                            alt="Посмотреть"
                        // height="24"
                        // width="24"
                        ></img>
                    </div>
                    {/* В случае ошибки */}
                    {/* <div className="row__pre_title">{log.action == "error" && <b style={{ fontSize: "12px", color: "#6a4242", marginRight: "5px" }}>error:</b>} {log.note}</div> */}

                </div>


                {this.state.open && <div className="row__options">
                    {/* {User.is_a && log.crm_name && <div className="options_group">
                        <div className="options__title">CRM:</div>
                        <div className="options__value">{log.crm_name}</div>
                    </div>} */}
                    {/* {log.author && <div className="options_group">
                        <div className="options__title">Пользователь:</div>
                        <div className="options__value"> {log.author}</div>
                    </div>} */}
                    <div className="options_group">
                        <div className="options__title">Старое значение:</div>
                        <div className="options__value">{JSON.stringify(log.old_values)}</div>
                    </div>
                    <div className="options_group">
                        <div className="options__title">Новое значение:</div>
                        <div className="options__value">{JSON.stringify(log.new_values)}</div>
                    </div>
                </div>}
            </div>
        )
    }
}


HistoryItem.propTypes = {
    data: PropTypes.object.isRequired
};