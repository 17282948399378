import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import ModelConfirm from './ModelConfirm.jsx'
import ModelForm from './ModelForm.jsx'
import ModelBlockEdit from './ModelBlockEdit.jsx'
import ModelInfo from './ModelInfo.jsx'
import '../../../styles/parts/_models.scss'
import ReactTransitionGroup from 'react-addons-transition-group'
// import { TransitionGroup } from 'react-transition-group'

export default class Model extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "confirm_model": {
                "status": false,
                "handler": () => { },
                "onCancel": () => { },
                "msg": "",
                "text": "",
                confirm_button: "Удалить"
            },
            "form_model": {
                "status": false,
                "fields": [],
                "handler": () => { },
                "onCancel": () => { },
                "texts": {
                    "title": "",
                    "btn_ok": "",
                }
            },
            "block_edit_model": {
                "status": false,
                "handler": () => { },
                "onCancel": () => { },
                "data": {},
            },
            "info_model": {
                "status": false,
                "data": {},
            }
        };
        this.showFormModel = this.showFormModel.bind(this);
        this.showConfirmModel = this.showConfirmModel.bind(this);
        this.showBlockEditModel = this.showBlockEditModel.bind(this);
        //this.hideConfirmModel = this.hideConfirmModel.bind(this);
        this.hideModel = this.hideModel.bind(this);
        this.showInfoModel = this.showInfoModel.bind(this);
        UI.showConfirmModel = this.showConfirmModel;
        UI.showFormModel = this.showFormModel;
        UI.showBlockEditModel = this.showBlockEditModel;
        UI.showInfoModel = this.showInfoModel;
    }

    showConfirmModel(handler, onCancel, msg, text, confirm_button="Удалить") {
        let s = this.state;
        s.confirm_model.status = true;
        s.confirm_model.handler = function () {
            this.hideModel();
            handler();
        }.bind(this);
        s.confirm_button = confirm_button
        s.confirm_model.onCancel = onCancel;
        s.confirm_model.msg = msg;
        s.confirm_model.text = text;
        this.setState(s);
    }

    // hideConfirmModel() {
    //     let s = this.state;
    //     s.confirm_model.status = false;
    //     s.confirm_model.handler = () => { };
    //     if (typeof s.confirm_model.onCancel === "function")
    //         s.confirm_model.onCancel();
    //     this.setState(s);
    // }

    showFormModel(fields, handler, onCancel, texts) {
        let s = this.state;
        s.form_model.status = true;
        s.form_model.fields = fields;
        s.form_model.handler = function () {
            this.hideModel();
            handler();
        }.bind(this);
        s.form_model.onCancel = onCancel;
        s.form_model.texts.title = texts["title"];
        s.form_model.texts.btn_ok = texts["btn_ok"];
        s.form_model.texts.btn_cancel = texts["btn_cancel"];
        this.setState(s);
    }

    hideModel() {
        let s = this.state;
        let model = undefined;
        if (s.form_model.status) {
            model = s.form_model
        } else if (s.confirm_model.status) {
            model = s.confirm_model
        } else if (s.block_edit_model.status) {
            model = s.block_edit_model
        } else if (s.info_model.status) {
            model = s.info_model
        }
        if (model != undefined) {
            model.status = false;
            if (model.handler != undefined)
                model.handler = () => { };
            if (typeof model.onCancel === "function")
                model.onCancel();
            this.setState(s);
        }
    }

    showBlockEditModel(data) {
        return new Promise(function (resolve, reject) {
            let s = this.state;
            s.block_edit_model.status = true;
            s.block_edit_model.data = data;
            s.block_edit_model.onCancel = reject;
            s.block_edit_model.handler = function () {
                resolve();
                this.hideModel();
            }.bind(this);
            this.setState(s);
        }.bind(this));
    }

    showInfoModel(msg) {
        let s = this.state;
        s.info_model.status = true;
        s.info_model.data = msg;
        this.setState(s);
    }

    render() {
        return (
            <ReactTransitionGroup>
                {this.state.confirm_model.status && <ModelConfirm handlerConfirm={this.state.confirm_model.handler} handlerCancel={this.hideModel} msg={this.state.confirm_model.msg} text={this.state.confirm_model.text} confirm_button_text={this.state.confirm_button}/>}
                {this.state.form_model.status && <ModelForm handlerConfirm={this.state.form_model.handler} handlerCancel={this.hideModel} fields={this.state.form_model.fields} texts={this.state.form_model.texts} />}
                {this.state.block_edit_model.status && <ModelBlockEdit handlerConfirm={this.state.block_edit_model.handler} handlerCancel={this.hideModel} data={this.state.block_edit_model.data} />}
                {this.state.info_model.status && <ModelInfo handlerCancel={this.hideModel} msg={this.state.info_model.data} />}
            </ReactTransitionGroup>
        );
    }
}