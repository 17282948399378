import React from "react";
// import Btn from "../parts/Btn.jsx";
import $ from "jquery";
// import ModalConfirmation from "../parts/ModalConfirmation.jsx";

export default class Balance extends React.Component {
  constructor() {
    super();
    this.state = {
      tariff: "",
      max_users: "0",
      balance: "0",
      type: "day",
      bill_logs: [],
      max_items: 5
    };
  }

  componentDidMount() {
    UI.get("/api/crm/" + this.state.type + "/").then((data) => {
      this.setState({
        tariff: data.crm.tariff,
        max_users: data.crm.max_users,
        balance: data.crm.balance,
        bill_logs: data.crm.bill_logs,
      });
    }
    );
  }

  handleOptionChange = (e) => {
    var type = $(e.target).val();
    UI.get("/api/crm/" + type + "/").then((data) => {
      this.setState({
        tariff: data.crm.tariff,
        max_users: data.crm.max_users,
        balance: data.crm.balance,
        bill_logs: data.crm.bill_logs,
        type: type
      });
    }
    );
  }

  moreItems = () => {
    this.setState({
      max_items: this.state.max_items + 5
    });
  }

  render() {
    return (
      <div className="content">
        <div className="page_header"><div className="page_title">Баланс</div></div>
        <div className="sub_header"></div>

        {/* <div className="page_header"></div> */}
        <div className="page_main_flex">
          <div className="billing_container main">
            <div className="tariff">
              <div>Тариф:</div>
              <div>{this.state.tariff}</div>
            </div>
            <div className="max_users">
              <div>Лимит пользователей:</div>
              <div>{this.state.max_users}</div>
            </div>
            <div className="balance">
              <div>Текущий баланс:</div>
              <div>{this.state.balance}р.</div>
            </div>
            {/* <div className="spent_today">
              <div>Расходы за сегодня:</div>
              <div>{this.state.spent_today}р.</div>
            </div> */}
          </div>
          <div className="billing_container">
            <div className="balance_details">
              <div>Детализация счета:</div>
              <div className="period">
                <label>
                  <input type="radio" name="period" value="year" checked={this.state.type == "year"} onChange={this.handleOptionChange} />
                  <span>Год</span>
                </label>
                <label>
                  <input type="radio" name="period" value="month" checked={this.state.type == "month"} onChange={this.handleOptionChange} />
                  <span>Месяц</span>
                </label>
                <label >
                  <input type="radio" name="period" value="day" checked={this.state.type == "day"} onChange={this.handleOptionChange} />
                  <span>День</span>
                </label>
                <label>
                  <input type="radio" name="period" value="all" checked={this.state.type == "all"} onChange={this.handleOptionChange} />
                  <span>Все</span>
                </label>
              </div>
              {/* <div></div> */}
            </div>
            {this.state.bill_logs.map((item, index) => {
              if (index < this.state.max_items) {
                return (
                  <div key={index} className="balance">
                    <div>{item.period}</div>
                    <div className={item.sum > 0 ? "green_text" : "red_text"}>{item.sum > 0 ? "+" : "- "}{Math.abs(item.sum)}р.</div>
                  </div>
                )
              } else if (index == this.state.max_items) {
                return (
                  <div key={index} className="more_balance" onClick={this.moreItems}>
                    {/* еще &#8595; */}
                    Еще
                    <div>
                      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z" fill="#878799" />
                      </svg>
                    </div>


                  </div>
                )
              }
            }
            )}
            {/* Вариант с вынесеной датой и индивидуальным временем
              <div key={index}>
                {index == 0 || item.day != this.state.bill_logs[index - 1].day ? <div>{item.day}:</div> : false}
                <div className="balance">
                  <div>{item.time}</div>
                  <div className={item.sum > 0 ? "green_text" : "yellow_text"}>{item.sum}р.</div>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    );
  }
}
