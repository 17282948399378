import React from 'react'
import Btn from "../../parts/Btn.jsx"
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import modes from "./modes.json"

export default class Block extends React.Component {

    constructor(props) {
        super(props);
        this.preHandler = this.preHandler.bind(this);
        this.preHandler = this.preHandler.bind(this);
        this.afterHandler = this.afterHandler.bind(this);
        this.preClick = this.preClick.bind(this);
        this.preDel = this.preDel.bind(this);
        this.preEdit = this.preEdit.bind(this);
        this.modes = modes;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props !== nextProps;
    }

    afterHandler() {

    }

    preHandler(e) {
        e.stopPropagation();
        let menu_blocks_width = document.querySelector(".js-menu_blocks").offsetWidth;
        let x_px = e.pageX - menu_blocks_width / 2;
        let y_px = e.pageY + 20;
        let x = x_px * 100 / window.innerWidth;
        let y = y_px * 100 / window.innerHeight;
        this.props.onAdd({
            x: x+"%",
            y: y+"%",
            block_id: this.props.data.inner_id,
        });
    }

    preEdit() {
        this.props.onEdit(this.props.data.inner_id);
    }

    preDel(hideIndicator) {
        UI.showConfirmModel(function () {
            this.props.onDel(this.props.data.inner_id);
            hideIndicator();
        }.bind(this), hideIndicator, "Удалить?");
    }

    preClick() {
        this.props.onClick(this.props.data.inner_id);
    }

    render() {
        const data = this.props.data;
        let countChildren = 0;
        data.links.forEach(function (item_code) {
            if (item_code != null) {
                countChildren+=1;
            }
        });

        let classes = "block ";
        if (data.marked === true) {
            classes += "block__marked ";
        }
        let title = "";
        if (data.mode != null) {
            title = this.modes[data.type][data.mode];
        } else {
            title = this.modes["block"][this.props.type];
        }


        if (["start","condition","operation"].includes(data.type) == false) {
            throw new DOMException("undefined type '" + data.type + "' of component 'Block', is allowed types: start, condition, operation");
        }
        let is_arrow = false;
        let max_children = 1;
        switch (data.type) {
            case "start":
                classes += "block_start";
                title = "Начало";
                break;
            case "condition":
                classes += "block_condition";
                //title = "Условие";
                max_children = 2;
                break;
            case "operation":
                classes += "block_operation";
                //title = "Действие";
                break;
        }
        if (countChildren < max_children)
            is_arrow = true;
        const block__panel = data.type != "start" ? <div className="block__panel">
            <Btn size="min" text="&#128736;" handler={this.preEdit}/>
            { countChildren == 0 && <Btn size="min" color="red" handler={this.preDel} indicator={true} text="&#10008;"/>}
        </div> : false;
        const arrow = is_arrow && <div className="block__arrow block__arrow_bottom" onClick={this.preHandler}></div>;
        const count_customers = data.type == "operation" && <div className="block__info">{data.count_customers}</div>;
        return (
            <div className={classes} onClick={data.marked === true ? this.preClick : ()=>{}}>
                <div className="block_title">{title + "-" + data.inner_id}</div>
                {count_customers}
                {block__panel}
                {arrow}
            </div>
        )
    }
}

Block.propTypes = {
    data: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDel: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

Block.defaultProps = {
};