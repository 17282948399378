export default class ChannelAccount {

    constructor() {
        this.auth_url = undefined;
    }

    openWindow() {
        if (this.auth_url)
            window.open(this.auth_url, "_blank");
    }

    auth() {
        this.openWindow();
    }

}