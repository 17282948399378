import React from 'react'
import { Link, Route, Switch, Redirect } from 'react-router-dom'
import "../../../styles/pages/_404.scss"

export default class NotFound404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="content">
                <div className="reg-page-title">MoreADS</div>
                <div className="error_wrapper">
                    <div className="error_title error-title">Страница не найдена</div>
                    <div className="error-line">В адресе есть ошибка. Возможно страница еще не создана или удалена.</div>
                    <div className="error-line">Начните с одной из популярных страниц:</div>
                    <div className="error-links">
                        <a href="..">Главная</a>
                        <a href="..\segments">Сегменты</a>
                    </div>
                </div>
                <svg className="error-picture" width="640" height="348" viewBox="0 0 640 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                        <path d="M322.054 348C495.595 348 636.278 338.687 636.278 327.199C636.278 315.71 495.595 306.397 322.054 306.397C148.512 306.397 7.82935 315.71 7.82935 327.199C7.82935 338.687 148.512 348 322.054 348Z" fill="#F2F2F2" />
                        <path d="M310.01 99.48C258.815 99.48 222.057 132.324 222.057 208.96C222.057 295.814 258.815 318.805 310.01 318.805C361.206 318.805 400.154 293.625 400.154 208.96C400.154 118.457 361.206 99.48 310.01 99.48ZM310.347 289.975C274.887 289.975 256.362 269.173 256.362 209.016C256.362 155.936 275.646 127.945 311.105 127.945C346.564 127.945 365.848 146.331 365.848 209.016C365.848 267.657 345.806 289.975 310.347 289.975Z" fill="#2F2E41" />
                        <path d="M191.401 258.226H170.233V195.897C170.233 192.587 168.918 189.412 166.577 187.071C164.236 184.73 161.061 183.415 157.75 183.415H152.79C151.151 183.415 149.528 183.738 148.013 184.365C146.499 184.993 145.123 185.912 143.964 187.071C142.804 188.23 141.885 189.606 141.258 191.121C140.63 192.635 140.307 194.258 140.307 195.897V258.226H80.6017C79.3734 258.226 78.166 257.909 77.0965 257.305C76.0271 256.701 75.132 255.831 74.498 254.779C73.864 253.727 73.5127 252.529 73.4781 251.301C73.4435 250.073 73.7269 248.857 74.3007 247.771L137.492 128.176C138.28 126.685 138.758 125.049 138.896 123.369C139.035 121.688 138.831 119.997 138.298 118.397C137.764 116.797 136.912 115.322 135.793 114.06C134.674 112.799 133.311 111.777 131.786 111.057L128.055 109.296C125.159 107.928 121.847 107.726 118.806 108.732C115.764 109.737 113.226 111.874 111.716 114.698L34.6453 258.866C33.3329 261.321 32.6462 264.062 32.6462 266.846C32.6462 269.068 33.0841 271.269 33.9347 273.323C34.7854 275.377 36.0322 277.243 37.604 278.814C39.1758 280.386 41.0418 281.633 43.0955 282.483C45.1492 283.334 47.3503 283.772 49.5731 283.772H140.307V323.185C140.307 325.149 140.694 327.095 141.446 328.91C142.198 330.726 143.3 332.375 144.69 333.765C146.079 335.154 147.729 336.256 149.544 337.008C151.36 337.76 153.305 338.147 155.27 338.147H155.27C157.235 338.147 159.181 337.76 160.996 337.008C162.812 336.256 164.461 335.154 165.851 333.765C167.24 332.375 168.342 330.726 169.094 328.91C169.846 327.095 170.233 325.149 170.233 323.185V283.772H191.401C194.788 283.772 198.037 282.426 200.433 280.031C202.828 277.635 204.174 274.387 204.174 270.999C204.174 267.612 202.828 264.363 200.433 261.967C198.037 259.572 194.788 258.226 191.401 258.226Z" fill="#6DCCF8" />
                        <path d="M583.36 258.226H562.193V195.897C562.193 192.587 560.877 189.412 558.536 187.071C556.195 184.73 553.02 183.415 549.709 183.415H544.75C543.11 183.415 541.487 183.738 539.973 184.365C538.458 184.993 537.082 185.912 535.923 187.071C534.764 188.23 533.844 189.606 533.217 191.121C532.589 192.635 532.266 194.258 532.266 195.897V258.226H472.561C471.333 258.226 470.125 257.909 469.056 257.305C467.986 256.701 467.091 255.831 466.457 254.779C465.823 253.727 465.472 252.529 465.437 251.301C465.403 250.073 465.686 248.857 466.26 247.771L529.452 128.176C530.24 126.685 530.717 125.049 530.855 123.369C530.994 121.688 530.79 119.997 530.257 118.397C529.723 116.797 528.871 115.322 527.752 114.06C526.633 112.799 525.27 111.777 523.745 111.057L520.014 109.296C517.118 107.928 513.806 107.726 510.765 108.732C507.724 109.737 505.185 111.874 503.675 114.698L426.604 258.866C425.292 261.321 424.605 264.062 424.605 266.846C424.605 271.335 426.389 275.64 429.563 278.814C432.738 281.988 437.043 283.772 441.532 283.772H532.266V323.185C532.266 327.153 533.843 330.959 536.649 333.765C539.455 336.57 543.261 338.147 547.229 338.147C551.198 338.147 555.004 336.57 557.81 333.765C560.616 330.959 562.193 327.153 562.193 323.185V283.772H583.36C586.747 283.772 589.996 282.426 592.392 280.031C594.787 277.635 596.133 274.387 596.133 270.999C596.133 267.612 594.787 264.363 592.392 261.967C589.996 259.572 586.748 258.226 583.36 258.226Z" fill="#6DCCF8" />
                        <path d="M197.24 250.928H176.072V188.599C176.072 186.959 175.75 185.336 175.122 183.822C174.495 182.307 173.575 180.931 172.416 179.772C171.257 178.613 169.881 177.694 168.366 177.066C166.852 176.439 165.229 176.116 163.589 176.116H158.629C155.319 176.116 152.144 177.431 149.803 179.772C147.462 182.113 146.146 185.288 146.146 188.599V250.928H86.4407C85.2125 250.928 84.0051 250.61 82.9356 250.006C81.8662 249.402 80.9711 248.532 80.3371 247.48C79.7031 246.428 79.3518 245.23 79.3172 244.002C79.2826 242.774 79.566 241.559 80.1398 240.473L143.332 120.877C144.119 119.386 144.597 117.751 144.735 116.07C144.874 114.389 144.67 112.698 144.137 111.098C143.603 109.498 142.751 108.023 141.632 106.762C140.513 105.5 139.15 104.479 137.625 103.758L133.894 101.997C130.998 100.629 127.686 100.427 124.645 101.433C121.604 102.439 119.065 104.575 117.555 107.4L40.4844 251.567C39.172 254.022 38.4854 256.763 38.4854 259.547V259.547C38.4854 264.036 40.2687 268.341 43.4431 271.515C46.6175 274.69 50.923 276.473 55.4123 276.473H146.146V315.886C146.146 317.851 146.533 319.796 147.285 321.612C148.037 323.427 149.14 325.076 150.529 326.466C151.918 327.855 153.568 328.957 155.383 329.709C157.199 330.461 159.144 330.848 161.109 330.848H161.109C165.078 330.848 168.884 329.272 171.69 326.466C174.496 323.66 176.072 319.854 176.072 315.886V276.473H197.24C198.917 276.473 200.578 276.143 202.128 275.501C203.678 274.859 205.086 273.918 206.272 272.732C207.458 271.546 208.399 270.138 209.041 268.588C209.683 267.039 210.013 265.378 210.013 263.7V263.7C210.013 262.023 209.683 260.362 209.041 258.812C208.399 257.263 207.458 255.855 206.272 254.669C205.086 253.483 203.678 252.542 202.128 251.9C200.578 251.258 198.917 250.928 197.24 250.928V250.928Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M594.308 250.928H573.141V188.599C573.141 185.288 571.826 182.113 569.485 179.772C567.144 177.431 563.969 176.116 560.658 176.116H555.698C554.059 176.116 552.436 176.439 550.921 177.066C549.406 177.694 548.03 178.613 546.871 179.772C545.712 180.931 544.793 182.307 544.165 183.822C543.538 185.336 543.215 186.959 543.215 188.599V250.928H483.509C482.281 250.928 481.074 250.61 480.004 250.006C478.935 249.402 478.04 248.532 477.406 247.48C476.772 246.428 476.42 245.23 476.386 244.002C476.351 242.774 476.635 241.559 477.208 240.473L540.4 120.877C541.188 119.386 541.666 117.751 541.804 116.07C541.942 114.389 541.739 112.698 541.205 111.098C540.672 109.498 539.82 108.023 538.701 106.762C537.582 105.5 536.218 104.479 534.693 103.758L530.963 101.997C528.066 100.629 524.755 100.427 521.713 101.433C518.672 102.439 516.134 104.575 514.624 107.4L437.553 251.567C436.241 254.022 435.554 256.763 435.554 259.547C435.554 264.036 437.337 268.341 440.512 271.515C443.686 274.69 447.992 276.473 452.481 276.473H543.215V315.886C543.215 319.854 544.791 323.66 547.598 326.466C550.404 329.272 554.21 330.848 558.178 330.848V330.848C562.146 330.848 565.952 329.272 568.758 326.466C571.565 323.66 573.141 319.854 573.141 315.886V276.473H594.308C595.986 276.473 597.647 276.143 599.196 275.501C600.746 274.859 602.154 273.918 603.34 272.732C604.527 271.546 605.467 270.138 606.109 268.588C606.751 267.039 607.082 265.378 607.082 263.7V263.7C607.082 260.313 605.736 257.064 603.34 254.669C600.945 252.273 597.696 250.928 594.308 250.928V250.928Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M321.689 92.9111C270.493 92.9111 233.735 125.755 233.735 202.391C233.735 289.245 270.493 312.236 321.689 312.236C372.884 312.236 411.832 287.056 411.832 202.391C411.832 111.888 372.884 92.9111 321.689 92.9111ZM322.025 283.407C286.566 283.407 268.041 262.605 268.041 202.447C268.041 149.367 287.324 121.376 322.784 121.376C358.243 121.376 377.527 139.762 377.527 202.447C377.526 261.088 357.485 283.407 322.025 283.407V283.407Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M599.774 249.907C601.579 250.563 603.511 250.79 605.419 250.572C607.326 250.354 609.157 249.696 610.767 248.65C612.377 247.603 613.722 246.197 614.695 244.543C615.669 242.888 616.245 241.03 616.378 239.114C616.511 237.199 616.197 235.279 615.462 233.505C614.726 231.732 613.589 230.154 612.139 228.895C610.689 227.636 608.967 226.731 607.108 226.252C605.248 225.772 603.303 225.731 601.425 226.131L601.736 232.512L598.717 227.043C595.754 228.435 593.465 230.947 592.353 234.026C591.958 235.104 591.718 236.233 591.639 237.379C591.449 240.068 592.14 242.745 593.608 245.006C595.076 247.266 597.241 248.987 599.774 249.907Z" fill="#57B894" />
                        <path d="M614.712 323.913C613.647 318.486 618.265 313.675 622.826 310.548C627.388 307.421 632.718 304.349 634.274 299.042C636.511 291.416 629.848 284.431 624.662 278.408C620.815 273.938 617.511 269.028 614.819 263.78C613.741 261.676 612.749 259.476 612.465 257.129C612.056 253.748 613.142 250.384 614.237 247.16C617.882 236.417 621.779 225.765 625.929 215.203" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M600.285 248.264C602.09 248.92 604.022 249.148 605.929 248.93C607.837 248.712 609.668 248.053 611.278 247.007C612.888 245.961 614.232 244.555 615.206 242.9C616.18 241.245 616.756 239.387 616.889 237.472C617.022 235.556 616.708 233.636 615.972 231.863C615.237 230.089 614.099 228.511 612.649 227.252C611.2 225.993 609.477 225.089 607.618 224.609C605.759 224.129 603.814 224.088 601.936 224.488L602.246 230.869L599.227 225.401C596.264 226.793 593.975 229.304 592.863 232.383C592.468 233.462 592.228 234.591 592.15 235.737C591.96 238.425 592.651 241.103 594.119 243.363C595.587 245.624 597.751 247.344 600.285 248.264V248.264Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M613.198 212.999C613.906 211.045 615.094 209.301 616.654 207.927C618.213 206.553 620.093 205.594 622.121 205.138L623.142 211.204L625.034 204.833C627.662 204.872 630.209 205.745 632.308 207.326C634.407 208.907 635.95 211.114 636.713 213.629C637.476 216.143 637.42 218.835 636.553 221.316C635.686 223.797 634.053 225.938 631.89 227.43C629.727 228.922 627.146 229.689 624.519 229.618C621.892 229.548 619.355 228.645 617.275 227.039C615.195 225.433 613.679 223.208 612.946 220.684C612.213 218.161 612.301 215.469 613.198 212.999H613.198Z" fill="#57B894" />
                        <path d="M613.878 210.809C614.586 208.855 615.775 207.111 617.334 205.737C618.894 204.363 620.774 203.404 622.802 202.947L623.822 209.014L625.715 202.643C628.342 202.682 630.89 203.555 632.989 205.135C635.088 206.716 636.63 208.924 637.393 211.438C638.156 213.953 638.1 216.645 637.233 219.125C636.367 221.606 634.734 223.747 632.571 225.24C630.408 226.732 627.826 227.498 625.199 227.428C622.572 227.358 620.036 226.454 617.955 224.848C615.875 223.243 614.359 221.017 613.627 218.494C612.894 215.97 612.982 213.279 613.878 210.809H613.878Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M622.486 278.531C625.348 279.569 628.492 279.518 631.318 278.387C634.144 277.256 636.456 275.125 637.812 272.4C639.168 269.674 639.473 266.545 638.671 263.609C637.868 260.673 636.012 258.135 633.458 256.478L632.004 261.374L630.973 255.237C630.963 255.232 630.952 255.228 630.941 255.225C629.411 254.668 627.785 254.419 626.158 254.491C624.531 254.563 622.934 254.955 621.459 255.644C619.983 256.334 618.658 257.307 617.559 258.509C616.46 259.711 615.608 261.117 615.053 262.648C614.498 264.179 614.249 265.804 614.322 267.431C614.395 269.058 614.788 270.655 615.478 272.13C616.169 273.605 617.143 274.929 618.345 276.027C619.548 277.126 620.955 277.977 622.486 278.531H622.486Z" fill="#57B894" />
                        <path d="M623.076 276.312C625.938 277.35 629.082 277.299 631.908 276.168C634.734 275.037 637.046 272.906 638.402 270.181C639.758 267.455 640.063 264.326 639.261 261.39C638.458 258.454 636.602 255.916 634.048 254.259L632.594 259.155L631.563 253.018C631.553 253.013 631.542 253.009 631.531 253.006C630.001 252.45 628.375 252.2 626.748 252.272C625.121 252.344 623.524 252.736 622.049 253.426C620.573 254.115 619.248 255.088 618.149 256.29C617.05 257.492 616.198 258.898 615.643 260.429C615.088 261.96 614.839 263.585 614.912 265.212C614.985 266.839 615.378 268.436 616.069 269.911C616.759 271.386 617.733 272.71 618.935 273.809C620.138 274.907 621.545 275.758 623.076 276.312H623.076Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M609.58 299.118C610.072 301.918 611.511 304.463 613.658 306.327C615.804 308.192 618.525 309.261 621.367 309.357C624.208 309.453 626.995 308.568 629.262 306.853C631.529 305.137 633.136 302.694 633.816 299.934C634.495 297.174 634.205 294.264 632.993 291.693C631.781 289.121 629.722 287.044 627.161 285.811C624.6 284.577 621.693 284.261 618.927 284.917C616.161 285.572 613.704 287.159 611.969 289.41L617.492 297.724L609.945 293.311C609.36 295.19 609.234 297.181 609.58 299.118Z" fill="#57B894" />
                        <path d="M610.09 297.476C610.582 300.275 612.022 302.821 614.168 304.685C616.314 306.549 619.036 307.619 621.877 307.714C624.718 307.81 627.506 306.926 629.772 305.21C632.039 303.495 633.647 301.052 634.326 298.292C635.005 295.531 634.715 292.622 633.503 290.05C632.292 287.479 630.233 285.402 627.672 284.168C625.111 282.934 622.203 282.619 619.437 283.274C616.671 283.93 614.214 285.516 612.479 287.768L618.003 296.081L610.455 291.669C609.87 293.547 609.745 295.539 610.09 297.476V297.476Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M5.36542 280.106C6.55101 280.537 7.82051 280.686 9.07385 280.543C10.3272 280.4 11.5301 279.967 12.5878 279.28C13.6455 278.592 14.529 277.669 15.1688 276.582C15.8086 275.494 16.1871 274.273 16.2744 273.015C16.3618 271.757 16.1557 270.495 15.6724 269.33C15.189 268.165 14.4416 267.128 13.4891 266.301C12.5366 265.474 11.405 264.879 10.1835 264.564C8.96196 264.249 7.68395 264.222 6.45016 264.485L6.65405 268.677L4.67049 265.084C2.72375 265.999 1.2198 267.649 0.48916 269.672C0.229678 270.38 0.0719324 271.122 0.0206289 271.875C-0.104574 273.642 0.349463 275.401 1.31393 276.886C2.27841 278.371 3.70074 279.502 5.36542 280.106Z" fill="#57B894" />
                        <path d="M15.1803 328.729C14.4801 325.164 17.5141 322.003 20.5111 319.949C23.5081 317.894 27.0099 315.876 28.0325 312.389C29.5021 307.378 25.1246 302.789 21.7174 298.832C19.1896 295.895 17.0188 292.669 15.2505 289.221C14.4817 287.867 13.958 286.387 13.7037 284.851C13.435 282.63 14.1486 280.42 14.8676 278.301C17.2626 271.243 19.8233 264.244 22.5497 257.305" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M5.70112 279.027C6.88671 279.458 8.1562 279.607 9.40954 279.464C10.6629 279.321 11.8658 278.888 12.9235 278.201C13.9812 277.513 14.8647 276.59 15.5045 275.502C16.1442 274.415 16.5227 273.194 16.6101 271.936C16.6975 270.677 16.4914 269.416 16.008 268.251C15.5247 267.086 14.7773 266.049 13.8248 265.222C12.8723 264.395 11.7407 263.8 10.5192 263.485C9.29765 263.17 8.01964 263.143 6.78586 263.406L6.98975 267.598L5.00619 264.005C3.05944 264.92 1.5555 266.57 0.824854 268.593C0.565375 269.301 0.407631 270.043 0.356325 270.796C0.231113 272.562 0.685147 274.322 1.64962 275.807C2.61409 277.292 4.03644 278.422 5.70112 279.027V279.027Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M14.1847 255.857C14.65 254.573 15.4309 253.427 16.4555 252.525C17.48 251.622 18.7153 250.992 20.0475 250.692L20.7182 254.678L21.9616 250.492C23.688 250.517 25.3616 251.091 26.7408 252.13C28.1199 253.168 29.1333 254.618 29.6346 256.271C30.1358 257.923 30.099 259.691 29.5295 261.321C28.96 262.951 27.8872 264.358 26.466 265.338C25.0449 266.319 23.3489 266.822 21.6229 266.776C19.8969 266.73 18.2303 266.136 16.8636 265.081C15.4969 264.026 14.5009 262.564 14.0194 260.906C13.5379 259.248 13.5958 257.48 14.1847 255.857Z" fill="#57B894" />
                        <path d="M14.632 254.418C15.0973 253.134 15.8782 251.988 16.9027 251.086C17.9273 250.183 19.1626 249.553 20.4948 249.253L21.1654 253.239L22.4089 249.053C24.1353 249.078 25.8089 249.652 27.188 250.691C28.5672 251.729 29.5806 253.179 30.0818 254.832C30.5831 256.484 30.5463 258.252 29.9768 259.882C29.4072 261.512 28.3345 262.919 26.9133 263.899C25.4921 264.88 23.7961 265.383 22.0701 265.337C20.3442 265.291 18.6775 264.697 17.3108 263.642C15.9442 262.587 14.9482 261.125 14.4667 259.467C13.9852 257.809 14.0431 256.041 14.632 254.418V254.418Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M20.2877 298.912C22.1679 299.594 24.2333 299.561 26.0903 298.818C27.9473 298.075 29.466 296.675 30.3569 294.884C31.2478 293.093 31.4486 291.038 30.9211 289.108C30.3936 287.179 29.1746 285.512 27.4964 284.423L26.541 287.64L25.8635 283.608C25.8568 283.604 25.8499 283.602 25.8428 283.6C24.8371 283.234 23.7692 283.07 22.7 283.117C21.6309 283.164 20.5816 283.422 19.612 283.875C18.6425 284.328 17.7717 284.967 17.0494 285.757C16.3271 286.546 15.7675 287.471 15.4025 288.477C15.0376 289.482 14.8745 290.55 14.9225 291.619C14.9705 292.689 15.2288 293.738 15.6825 294.707C16.1361 295.676 16.7764 296.546 17.5665 297.268C18.3567 297.989 19.2814 298.548 20.2877 298.912Z" fill="#57B894" />
                        <path d="M20.675 297.455C22.5553 298.137 24.6207 298.103 26.4777 297.36C28.3347 296.617 29.8534 295.217 30.7443 293.426C31.6352 291.636 31.836 289.58 31.3085 287.651C30.7809 285.722 29.5619 284.054 27.8838 282.966L26.9284 286.182L26.2509 282.15C26.2442 282.147 26.2373 282.144 26.2302 282.142C25.2247 281.777 24.1572 281.614 23.0886 281.662C22.0201 281.71 20.9714 281.968 20.0025 282.421C18.0457 283.336 16.5325 284.99 15.7958 287.021C15.0592 289.052 15.1594 291.292 16.0744 293.248C16.9895 295.205 18.6443 296.718 20.675 297.455V297.455Z" stroke="#3F3D56" strokeMiterlimit="10" />
                        <path d="M11.8077 312.439C12.131 314.278 13.077 315.951 14.487 317.176C15.897 318.4 17.6853 319.103 19.552 319.166C21.4187 319.229 23.2502 318.648 24.7394 317.521C26.2287 316.394 27.285 314.789 27.7313 312.975C28.1776 311.162 27.9867 309.25 27.1907 307.56C26.3947 305.871 25.0419 304.506 23.3592 303.696C21.6765 302.885 19.7663 302.678 17.9489 303.109C16.1315 303.539 14.5174 304.582 13.3774 306.061L17.0065 311.523L12.0478 308.624C11.6633 309.858 11.581 311.166 11.8077 312.439Z" fill="#57B894" />
                        <path d="M12.143 311.359C12.4662 313.199 13.4122 314.871 14.8222 316.096C16.2322 317.321 18.0205 318.024 19.8872 318.086C21.7539 318.149 23.5854 317.568 25.0746 316.441C26.5639 315.314 27.6202 313.709 28.0665 311.896C28.5128 310.082 28.3219 308.17 27.5259 306.481C26.7299 304.791 25.3771 303.427 23.6944 302.616C22.0117 301.806 20.1015 301.598 18.2841 302.029C16.4667 302.46 14.8526 303.502 13.7126 304.981L17.3417 310.443L12.383 307.544C11.9985 308.778 11.9162 310.087 12.143 311.359V311.359Z" stroke="#3F3D56" strokeMiterlimit="10" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="640" height="348" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
        )
    }

}