import React from "react";
// import LoadingIndicator from "../parts/LoadingIndicator.jsx";
import ReactMinimalPieChart from "react-minimal-pie-chart";

export default class HomeSegments extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var active_segments = this.props.segments.filter((item) => {
      if (item.is_active) {
        return item;
      }
    });
    var inactive_segments = this.props.segments.filter((item) => {
      if (!item.is_active) {
        return item;
      }
    });
    return (
      <div className="home_segments">
        <div className="first_column">
          <div className="text">Всего сегментов</div>
          <div className="number_of_segments">{this.props.segments.length}</div>
        </div>

        <div className="second_column">
          <div className="segment_types">
            <div className="text">Активно</div>
            <div className="text">Ожидает</div>
          </div>
          <div className="pie_chart">
            <div className="active_segments">
              <div className="number_of_active_segments">{active_segments.length}</div>
            </div>
            <div className="pie">
              <ReactMinimalPieChart
                animate={true}
                animationDuration={500}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={[
                  {
                    color: "#70D457",
                    title: active_segments.length,
                    value: active_segments.length,
                  },
                  {
                    color: "#D8DB44",
                    title: inactive_segments.length,
                    value: inactive_segments.length,
                  },
                ]}
                label={false}
                labelPosition={100}
                lengthAngle={360}
                lineWidth={22}
                onClick={undefined}
                onMouseOut={undefined}
                onMouseOver={undefined}
                paddingAngle={5}
                radius={37}
                rounded={false}
                startAngle={90 - (((active_segments.length / (active_segments.length + inactive_segments.length)) * 360) / 2 - 90)}
                viewBoxSize={[100, 100]}
              />
            </div>
            <div className="active_segments">
              <div className="number_of_inactive_segments">{inactive_segments.length}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
