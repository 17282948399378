import React from "react";
import PropTypes from "prop-types";
import Btn from "./Btn";
import Calendar from "./datefield/Calendar";

export default class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.hideCalendar = this.hideCalendar.bind(this);
    this.changeMod = this.changeMod.bind(this);
    this.toggleCalendarFrom = this.toggleCalendarFrom.bind(this);
    this.toggleCalendarTo = this.toggleCalendarTo.bind(this);
    this.handlerChangeCalendarFrom = this.handlerChangeCalendarFrom.bind(this);
    this.handlerChangeCalendarTo = this.handlerChangeCalendarTo.bind(this);
    this.modes = {
      RELATIVE: 1,
      RANGE: 2
    };
    this.objs = {
      FROM: "from",
      TO: "to"
    };
    this.state = {
      mode: this.modes.RANGE,
      is_opened_from: false,
      is_opened_to: false
    };
    this.currentValues = [];
    if (
      typeof this.props.defaultValue == "object" &&
      "includes" in this.props.defaultValue
    ) {
      this.currentValues = this.props.defaultValue;
      if (this.props.defaultValue[0] != undefined) {
        if (Calendar.isRelativeMode(this.props.defaultValue[0]))
          this.state.mode = this.modes.RELATIVE;
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState || nextProps !== this.props;
  }

  toggleCalendar(obj_field) {
    if (this.props.is_disabled == false) {
      let s = Object.assign({}, this.state);
      if (obj_field == this.objs.FROM) {
        s.is_opened_from = !s.is_opened_from;
        s.is_opened_to = false;
        this.setState(s);
      } else {
        s.is_opened_to = !s.is_opened_to;
        s.is_opened_from = false;
        this.setState(s);
      }
    }
  }

  handlerChangeCalendarFrom(value) {
    this.currentValues[0] = value;
    this.props.onChange({
      name: this.props.name,
      value: this.currentValues
    });
    this.forceUpdate();
  }

  handlerChangeCalendarTo(value) {
    this.currentValues[1] = value;
    this.forceUpdate();
    this.props.onChange({
      name: this.props.name,
      value: this.currentValues
    });
  }

  toggleCalendarFrom() {
    this.toggleCalendar(this.objs.FROM);
  }

  toggleCalendarTo() {
    this.toggleCalendar(this.objs.TO);
  }

  hideCalendar() {
    if (this.props.is_disabled == false) {
      let s = Object.assign({}, this.state);
      if (s.is_opened_from || s.is_opened_to) {
        s.is_opened_from = false;
        s.is_opened_to = false;
        this.setState(s);
      }
    }
  }

  changeMod() {
    if (this.props.is_disabled == false) {
      let s = Object.assign({}, this.state);
      s.mode =
        s.mode == this.modes.RANGE ? this.modes.RELATIVE : this.modes.RANGE;
      this.currentValues = [];
      this.setState(s);
    }
  }

  render() {
    return (
      <div className="datefield" onMouseLeave={this.hideCalendar}>
        <div className="btn-div">
          <Btn
            iconClass="btn"
            handler={this.changeMod}
            text="&#x21c4;"
            size="micro"
            color="light_blue"
          />
        </div>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ alignSelf: "center" }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.66659H12.6667C13.4 2.66659 14 3.26659 14 3.99992V13.3333C14 14.0666 13.4 14.6666 12.6667 14.6666H3.33333C2.59333 14.6666 2 14.0666 2 13.3333L2.00667 3.99992C2.00667 3.26659 2.59333 2.66659 3.33333 2.66659H4V1.33325H5.33333V2.66659H10.6667V1.33325H12V2.66659ZM3.33333 6.66658V13.3333H12.6667V6.66658H3.33333ZM12.6667 5.33325H3.33333V3.99992H12.6667V5.33325ZM11.3333 8.66659H8V11.9999H11.3333V8.66659Z"
            fill="#6DCCF8"
          />
        </svg>

        <div className="wrapper_calendar">
          <input
            type="text"
            value={Calendar.parseDateToStr(this.currentValues[0]) || ""}
            onClick={this.toggleCalendarFrom}
            className={
              "metrix_input_text field_calender_ico field_mid" +
              (this.state.mode == this.modes.RANGE ? "" : " word_date")
            }
            readOnly="readOnly"
          />
          {this.state.is_opened_from && (
            <Calendar
              has_dates={this.state.mode == this.modes.RANGE}
              has_relatives={this.state.mode == this.modes.RELATIVE}
              value={this.currentValues[0] || {}}
              onChange={this.handlerChangeCalendarFrom}
            />
          )}
        </div>
        {this.state.mode == this.modes.RANGE && "-"}
        {this.state.mode == this.modes.RANGE && (
          <div className="wrapper_calendar">
            <input
              type="text"
              value={Calendar.parseDateToStr(this.currentValues[1]) || ""}
              onClick={this.toggleCalendarTo}
              className="metrix_input_text field_calender_ico field_mid"
              readOnly="readOnly"
            />
            {this.state.is_opened_to && (
              <Calendar
                has_relatives={false}
                value={this.currentValues[1] || {}}
                onChange={this.handlerChangeCalendarTo}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

DateField.propTypes = {
  onChange: PropTypes.func.isRequired,
  is_disabled: PropTypes.bool,
  name: PropTypes.string.isRequired
  // defaultValue: PropTypes.array,
};

DateField.defaultProps = {
  is_disabled: false
};
