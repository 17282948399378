import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import RuleVal from "./RuleVal"

export default class RuleValAdd extends RuleVal {

    constructor(props) {
        super(props);
        this.title = "Канал";
        this.val = {
            "group_id": "0",
            "code": ""
        };
        if (UI.channel_codes == undefined) {
            UI.channel_codes = {}
        }
        if (UI.channel_groups == undefined) {
            UI.channel_groups = {}
        }
    }

    isEmpty() {
        return this.val.group_id == "0" || this.val.code == "";
    }

    handlerChange(e) {
        this.val[e.target.name] = e.target.value;
        if (e.target.name == "group_id") {
            Object.keys(UI.channel_groups).forEach(function (code) {
                if (e.target.value in UI.channel_groups[code])
                    this.val["code"] = code;
            }.bind(this));
        }
    }

    render() {
        return <div className="rule_wrapper">
            <span className="param__field_text">Выберите группу:</span>
            <select defaultValue={this.val.group_id} name="group_id" className="metrics__field_list" onChange={this.handlerChange}>
                <option value="0">---------</option>
                { typeof UI.channel_codes == 'object' && Object.keys(UI.channel_codes).map(function (code, index) {
                    return <optgroup key={index} label={UI.channel_codes[code]}>
                        { typeof UI.channel_groups[code] == 'object' &&  Object.keys(UI.channel_groups[code]).map(function (group_id, index) {
                            return <option key={index} value={group_id}>{UI.channel_groups[code][group_id]}</option>
                        }.bind(this)) }
                    </optgroup>
                }.bind(this)) }
            </select>
        </div>
    }
}