import React from "react";
import LoadingIndicator from "../parts/LoadingIndicator.jsx";
import HomeSegments from "./HomeSegments.jsx";
import HomeLeads from "./HomeLeads.jsx";
import HomeChannels from "./HomeChannels.jsx";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      loading: true,
      leads: "",
      customers: "",
      vk_segments: "",
      fb_segments: "",
      ya_segments: "",
      vk_customers: "",
      fb_customers: "",
      ya_customers: "",
    };
  }

  componentDidMount() {
    UI.query("/api/home/").then((data) => {
      console.log("data from home, ", data);
      this.setState({
        segments: data.segments,
        loading: false,
        leads: data.leads,
        customers: data.customers,
        vk_segments: data.vk_segments,
        fb_segments: data.fb_segments,
        ya_segments: data.ya_segments,
        vk_customers: data.vk_customers,
        fb_customers: data.fb_customers,
        ya_customers: data.ya_customers,
      });
    });
  }

  render() {
    return (
      <div className="home_content">
        {/* <div className="home_child"> */}
        <HomeSegments segments={this.state.segments} />
        <HomeLeads leads={this.state.leads} customers={this.state.customers} />
        {/* </div> */}
        {/* <div className="home_child"> */}
        <div className="home_hz"></div>
        <HomeChannels
          vk_segments={this.state.vk_segments}
          fb_segments={this.state.fb_segments}
          ya_segments={this.state.ya_segments}
          vk_customers={this.state.vk_customers}
          fb_customers={this.state.fb_customers}
          ya_customers={this.state.ya_customers}
        />
        {/* </div> */}
      </div>
    );
  }
}
