export default class URLMAP {
    static map() {
        return {
            "home": "/",
            "login": "/login/",
            "reg": "/reg/",
            "restore": "/restore/",
            "sources": "/sources/",
            "data": "/sources/(\\d+)/",
            "segments": "/segments/",
            "segment": "/segments/(\\d+)/",
            "diagram": "/segments/(\\d+)/diagram/",
            "channels": "/channels/",
            "users": "/users/",
            "history": "/history/",
            "settings": "/settings/",
            "balance": "/balance/"
        }
    };

    // получаем ключ урла из функции URL.map() и параметры, если они есть и возвращаем готовый урл
    static get(key, ...args) {
        let map = URLMAP.map();
        let pattern = map[key];
        if (args.length > 0) {
            args.forEach(function (item) {
                pattern = pattern.replace(/\(.*\)/, item);
            });
        }
        return pattern;
    }

    // проверяем является ли текущая страница той, которая соответствует ключу
    static is(key) {
        let path = location.pathname;
        let map = URLMAP.map();
        let pattern = map[key];
        return new RegExp(pattern).test(path);
    }

};