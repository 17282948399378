import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import RuleVal from "./RuleVal"

export default class RuleValNote extends RuleVal {

    constructor(props) {
        super(props);
        this.title = "Текст примечания";
        this.val = {
            "text": ""
        };
    }

    isEmpty() {
        return this.val.text.length == 0;
    }

    render() {
        return <textarea defaultValue={this.val.text} onChange={this.handlerChange} name="text" className="param_val__textarea"/>
    }
}