import React from "react";

export default class Raquo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { current_class: "door down" };
    // this.state = { current_class: this.props.current_class };
    this.changeArrowDirection = this.changeArrowDirection.bind(this);
  }

  render() {
    return (
      <div className="raquo_div" onClick={this.changeArrowDirection}>
        <svg className={this.state.current_class} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM5.57141 2.28571C5.01913 2.28571 4.57141 2.73343 4.57141 3.28572V12.7143C4.57141 13.2666 5.01913 13.7143 5.57141 13.7143H5.85713C6.40941 13.7143 6.85713 13.2666 6.85713 12.7143V3.28571C6.85713 2.73343 6.40941 2.28571 5.85713 2.28571H5.57141Z" fill="#12B0F9" />
        </svg>
        <div className={"raquo_text " + this.props.left_column}>Скрыть</div>
      </div>

    );
  }

  changeArrowDirection() {
    let arrow_direction = "door ";
    // arrow_direction +=
    let direction =
      this.state.current_class === "door right" ? "down" : "right";
    arrow_direction += direction;
    this.setState({ current_class: arrow_direction });
    this.props.direction_up(direction);
  }
}
