import React from "react";
import Btn from "../parts/Btn.jsx";
import PropTypes from "prop-types";
import $ from "jquery";

export default class UserItemTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.saveUser = this.saveUser.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.state = {};
    this.data = {
      fullname: "",
      role: "user",
      crm_name: this.props.crms.length > 0 ? this.props.crms[0].name : null,
      crm_id: this.props.crms.length > 0 ? this.props.crms[0].id : null,
      email: ""
    };
  }

  saveUser(hideIndicator) {
    var root = document.querySelector(".js-new_user");
    var data_to_send = this.data;
    if (!UI.isFilled(data_to_send["fullname"])) {
      $('input.full_name').addClass('row_error');
      hideIndicator();
    }
    else if (!UI.isFilled(data_to_send["email"])) {
      $('input.full_name').removeClass('row_error');
      $('input.email').addClass('row_error');
      hideIndicator();
    } else if (
      UI.isFilled(data_to_send["fullname"]) &&
      UI.isFilled(data_to_send["role"]) &&
      UI.isFilled(data_to_send["crm_name"]) &&
      UI.isFilled(data_to_send["crm_id"]) &&
      UI.isFilled(data_to_send["email"])
    ) {
      $('input.email').removeClass('row_error');
      UI.post("/api/users/", data_to_send).then(
        data => {
          var new_user = data_to_send;
          new_user["id"] = data.id;
          new_user["is_active"] = true;
          new_user["created_at"] = data.created_at;
          new_user["crm_name"] = this.props.crms.map(function (item) {
            if (item.id == new_user["crm_id"]) return item.name;
          });
          hideIndicator();
          this.props.addHandler(new_user);
          // root.classList.remove("row_error");
        },
        data => {
          // root.classList.add("row_error");
          hideIndicator();
          this.props.failure(data.message);

        }
      );
    } else {
      // root.classList.add("row_error");
      this.props.failure("Недостаточно данных.");
      hideIndicator();
    }
  }

  checkField(e) { }

  handlerChange(e) {
    let s = this.data;
    s[e.target.name] = e.target.value;
    this.setState(this.state);
  }

  imitateCancelButton = e => {
    $(
      $(e.target)
        .closest(".new_user")
        .find("div.new_user_buttons")
        .find("button")[1]
    ).click();
  };

  render() {
    const user = this.data;
    const crms_list = this.props.crms.map(item => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
    return (
      <div className="model_wrapper js-model_confirm">
        <div className="new_user js-new_user" id={user.id}>
          <div className="new_user_title_row">
            <div className="new_user_title">Добавление пользователя</div>
            <div
              className="close_modal_new_segment"
              onClick={this.imitateCancelButton}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </div>
          </div>
          {User.is_a && (
            <div className="row__sup_title">
              <select
                name="crm_id"
                onChange={this.handlerChange}
                defaultValue={user.crm_id}
              >
                {crms_list}
              </select>
            </div>
          )}
          <div className="add_user_data">
            <div className="row__pre_title field_user_role">
              <select
                name="role"
                onChange={this.handlerChange}
                defaultValue={user.role.toLowerCase()}
              >
                <option value="moderator">moderator</option>
                <option value="user">user</option>
              </select>
            </div>
            <div className="new_user_row">
              <div>ФИО:</div>
              <input
                type="text"
                className="input_text full_name"
                onChange={this.handlerChange}
                name="fullname"
                onBlur={this.checkField}
                defaultValue={user.fullname}
              />
            </div>
            <div className="new_user_row">
              <div>Email:</div>
              <input
                type="email"
                className="input_text email"
                onChange={this.handlerChange}
                name="email"
                onBlur={this.checkField}
                defaultValue={user.email}
              />
            </div>
          </div>
          <div className="new_user_buttons">
            <Btn
              color="green"
              size="min"
              handler={this.saveUser}
              indicator={true}
              text="Создать"
            />
            <Btn
              color="red"
              size="min"
              handler={this.props.hideHandler}
              text="Отменить"
            />
          </div>
        </div>
      </div>
    );
  }
}

UserItemTemplate.propTypes = {
  hideHandler: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  crms: PropTypes.array.isRequired
};
