import React from "react";
import SegmentItem from "./SegmentItem.jsx";
import Search from "../parts/Search.jsx";
import Btn from "../parts/Btn.jsx";
import LoadingIndicator from "../parts/LoadingIndicator.jsx";
import NoRecords from "../parts/NoRecords.jsx";
import Template from "./SegmentItemTemplate.jsx";
import ReactTransitionGroup from "react-addons-transition-group";
// import TransitionGroup from 'react-transition-group/TransitionGroup'
import ModalConfirmation from "../parts/ModalConfirmation.jsx";

export default class Segments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      all_channels: [],
      is_template: false,
      loading: true,
      modal_confirmation: false
    };
    this.hideTemplate = this.hideTemplate.bind(this);
    this.showTemplate = this.showTemplate.bind(this);
    this.addSegment = this.addSegment.bind(this);
    this.delSegment = this.delSegment.bind(this);
    this.updateItems = this.updateItems.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  componentDidMount() {
    UI.query("/api/segments/").then(data => {
      // console.log("data from segments, ", data);
      this.setState({
        segments: data.segments,
        all_channels: data.all_channels,
        loading: false
      });
    });
  }

  showTemplate() {
    let s = this.state;
    s.is_template = true;
    this.setState(s);
  }

  hideTemplate() {
    let s = this.state;
    s.is_template = false;
    this.setState(s);
  }

  addSegment(data) {
    let s = this.state;
    s.segments.unshift(data);
    s.is_template = false;
    this.setState(s);
  }

  delSegment(id) {
    let s = this.state;
    s.modal_confirmation = true;
    s.segments.forEach(function (item, index) {
      if (item.id == id) {
        s.segments.splice(index, 1);
        return true;
      }
    });
    this.setState(s);
  }

  updateItems(ids) {
    this.state.segments.forEach(function (segment) {
      segment.hidden =
        typeof ids == "number" ? false : !ids.includes(segment.id);
    });
    this.setState(this.state);
  }

  closeModalConfirmation = () => {
    this.setState({ modal_confirmation: false });
  };

  render() {
    const list = this.state.segments.map(
      item =>
        !item.hidden && (
          <SegmentItem
            key={item.id}
            data={item}
            all_channels={this.state.all_channels}
            delHandler={this.delSegment}
            updateSegments={() => this.forceUpdate}
          />
        )
    );
    // const add_btn = <div className="control_buttons">
    //     <Btn text="Добавить" handler={this.showTemplate} />
    // </div>;
    const loading_ico = this.state.loading && <LoadingIndicator />;
    return (
      <div className="content">

        <div className="page_header">
          <div className="page_title">Сегменты</div>
          {/* {add_btn} */}
          <Search
            class="segment-search"
            updateItems={this.updateItems}
            items={this.state.segments}
            placeholder="Найти сегмент"
          />
          <button className="add-segment-button" onClick={this.showTemplate}>
            {" "}
            Создать сегмент
          </button>
        </div>
        <div className="sub_header"></div>
        {this.state.modal_confirmation && (
          <ModalConfirmation
            text="Сегмент удален"
            onDelete={this.closeModalConfirmation}
          />
        )}
        {loading_ico}
        {!this.state.loading ? (
          <div className="page_main">
            <div className="segments-table-headings">
              <div className="segments-cell-name">Название</div>{" "}
              <div className="segments-cell-comprise">Охват</div>
              <div className="segments-cell-channels">Каналы</div>{" "}
              <div className="segments-cell-created">Создан</div>
              <div className="segments-cell-updated">Изменен</div>{" "}
              <div className="segments-cell-status">Статус</div>
              <div className="segments-cell-dots"></div>
            </div>
            {this.state.is_template && (
              <Template
                crms={this.state.segments}
                hideHandler={this.hideTemplate}
                addHandler={this.addSegment}
              />
            )}
            {this.state.segments.length == 0 && <NoRecords />}
            <ReactTransitionGroup>{list}</ReactTransitionGroup>
          </div>
        ) : (
            false
          )}
      </div>
    );
  }
}
