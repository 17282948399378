import React from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Btn from "./Btn.jsx";
const Velocity = require("../../../../node_modules/velocity-react/lib/velocity-animate-shim");
import $ from "jquery";

export default class ModelConfirm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillEnter(callback) {
    let root = document.getElementsByClassName("js-model_confirm")[0];
    Velocity(root, "fadeIn", { duration: 300, display: "flex" }).then(callback);
  }

  componentWillLeave(callback) {
    let root = document.getElementsByClassName("js-model_confirm")[0];
    Velocity(root, "fadeOut", { duration: 300 }).then(callback);
  }

  imitateCancelButton = () => {
    $($(".modal_agreement_buttons").find("button")[1]).click();
  };

  render() {
    if ("confirm_button_text" in this.props) {
      var confirm_button_text = this.props.confirm_button_text;
    } else {
      var confirm_button_text = "Удалить";
    }
    return (
      <div className="model_wrapper js-model_confirm">
        <div className="modal_agreement">
          <div className="modal_agreement_content">

            <div className="modal_agreement_title">{this.props.msg} <div
              className="close_modal_agreement"
              onClick={this.imitateCancelButton}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </div></div>
            <div className="modal_agreement_text">
              {typeof this.props.text == "object"
                ? this.props.text.map((item, index) => (
                  <p key={index}>{item}</p>
                ))
                : this.props.text}
            </div>
            <div className="modal_agreement_buttons">
              <Btn
                color="blue"
                size="mid"
                text={confirm_button_text}
                handler={this.props.handlerConfirm}
              />
              <Btn
                color="red"
                size="mid"
                text="Отменить"
                handler={this.props.handlerCancel}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModelConfirm.propTypes = {
  handlerConfirm: PropTypes.func.isRequired,
  handlerCancel: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired
};
