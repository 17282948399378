import React from "react";

export default class ModalAgreement extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="model_wrapper">
        <div className="modal_agreement">
          <div className="modal_agreement_content">

            <div className="modal_agreement_title">{this.props.title} <div
              className="close_modal_agreement"
              onClick={this.props.onDelete}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </div></div>
            <div className="modal_agreement_text">{this.props.text}</div>
            <div className="modal_agreement_buttons">
              <button
                onClick={() => this.props.confirm({ index: this.props.index })}
              >{"button_text" in this.props ? this.props.button_text : "Удалить"}
              </button>
              <button onClick={this.props.onDelete}>Отмена</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
