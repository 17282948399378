import React from "react";
import Btn from "../parts/Btn.jsx";
import $ from "jquery";
import ModalConfirmation from "../parts/ModalConfirmation.jsx";

export default class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      editing: false,
      modal_confirmation: false,
      confirmation_fail: false,
      confirmation_text: ""
    };
  }

  handlerChange = () => {
    $("#old_pass").val() &&
      $("#new_pass").val() &&
      $("#new_pass_confirm").val() &&
      $("#new_pass").val() == $("#new_pass_confirm").val()
      ? this.setState({ editing: true })
      : this.setState({ editing: false });
  };

  cancel = () => {
    $("#old_pass").val("");
    $("#new_pass").val("");
    $("#new_pass_confirm").val("");
    this.setState({ editing: false });
  };

  update = hideIndicator => {
    // var data_to_send = this.props.data;
    var data_to_send = User;
    data_to_send["old_pass"] = $("#old_pass").val();
    data_to_send["new_pass"] = $("#new_pass").val();
    data_to_send["permissions"] = [];
    console.log(data_to_send);
    // if (UI.isFilled(data_to_send["fullname"]) && UI.isFilled(data_to_send["email"]) && UI.isFilled(data_to_send["role"]) && UI.isFilled(data_to_send["crm_id"])) {
    UI.put("/api/users/", data_to_send).then(
      data => {
        let s = this.state;
        s.confirmation_fail = false;
        s.confirmation_text = "Пароль изменен";
        s.modal_confirmation = true;
        this.setState(s, this.cancel());
        // hideIndicator();
        // this.cancel();
      },
      data => {
        let s = this.state;
        s.confirmation_fail = true;
        s.confirmation_text = data.message;
        s.modal_confirmation = true;
        this.setState(s);
        // hideIndicator();
      }
    );
    // }
    // else {
    //     // root.classList.add("row_error");
    //     // hideIndicator();
    //     console.log('Не прошел fill check')
    // }
  };

  closeModalConfirmation = () => {
    this.setState({ modal_confirmation: false });
  };

  render() {
    return (
      <div className="content">

        <div className="page_header"><div className="page_title">Настройки</div></div>
        <div className="sub_header"></div>
        <div className="page_main">
          <div className="settings_block">
            <div className="title">Смена пароля:</div>
            <div className="input-field">
              <input
                type="password"
                onChange={this.handlerChange}
                id="old_pass"
              />
              <label htmlFor="old_pass">Введите старый пароль</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                onChange={this.handlerChange}
                id="new_pass"
              />
              <label htmlFor="new_pass">Введите новый пароль</label>
            </div>
            <div className="input-field">
              <input
                type="password"
                onChange={this.handlerChange}
                id="new_pass_confirm"
              />
              <label htmlFor="new_pass_confirm">Подтвердите новый пароль</label>
            </div>
            <div className="buttons">
              {this.state.editing && (
                <Btn
                  iconClass="save"
                  handler={this.update}
                  text="Сохранить"
                  indicator={false}
                />
              )}
              {this.state.editing && (
                <Btn iconClass="cancel" handler={this.cancel} text="Отмена" />
              )}
            </div>
          </div>
          {this.state.modal_confirmation && (
            <ModalConfirmation
              text={this.state.confirmation_text}
              failure={this.state.confirmation_fail}
              onDelete={this.closeModalConfirmation}
            />
          )}
        </div>
      </div>
    );
  }
}
