import React from 'react'
import Btn from "../../parts/Btn.jsx"
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

export default class Arrow extends React.Component {

    constructor(props) {
        super(props);
        this.hover = this.hover.bind(this);
        this.del = this.del.bind(this);
        this.state = {
            hover: false
        }
    }

    hover() {
        if (this.props.params["deleteable"])
            this.setState({hover: !this.state.hover});
    }

    del() {
        if (this.props.params["deleteable"])
            UI.showConfirmModel(function () {
                this.props.onDel({
                    parent: this.props.params["parent"],
                    child: this.props.params["child"]
                });
            }.bind(this), ()=>{}, "Открепить?");
    }

    render() {
        let style_ha = {display: "none"};
        let style_text = {display: "none"};
        let style_va1 = {};
        let style_va2 = {};
        let style_e = {};
        let text = this.props.text;
        if (this.props.direction == "-1") {
            style_ha = {width: this.props.width+1+"px"};
            style_va1 = {marginLeft: this.props.width-2+"px"};
            style_va2 = {marginLeft: "0px"};
            style_e = {marginLeft: "-8.5px"};
            style_text = {marginLeft: this.props.width/3*2-10+"px"};
        } else if (this.props.direction == "1") {
            style_ha = {width: this.props.width+1+"px"};
            style_va1 = {marginLeft: "0px"};
            style_va2 = {marginLeft: this.props.width-2+"px"};
            style_e = {marginLeft: this.props.width-10.5 + "px"};
            style_text = {marginLeft: this.props.width/3-10+"px"};
        } else if (this.props.direction == "0" && text != "") {
            style_text = {marginLeft: -12+"px", textAlign: "right", width: "20px"};
        }
        if (this.props.height_type)
            style_va1["height"] = this.props.height + 15 + "px";
        else
            style_va2["height"] = this.props.height + 15 + "px";
        return (
            <div className={this.state.hover ? "arrow arrow_hover" : "arrow "} >
                <div className="arrow__vertical" style={style_va1} onClick={this.del} onMouseOver={this.hover} onMouseOut={this.hover}></div>
                <div className="arrow__text" style={style_text} onClick={this.del} onMouseOver={this.hover} onMouseOut={this.hover}>{text}</div>
                <div className="arrow__horizontal" style={style_ha} onClick={this.del} onMouseOver={this.hover} onMouseOut={this.hover}></div>
                <div className="arrow__vertical" style={style_va2} onClick={this.del} onMouseOver={this.hover} onMouseOut={this.hover}></div>
                { !this.props.is_custom && <div className="arrow__end" style={style_e} onClick={this.del} onMouseOver={this.hover} onMouseOut={this.hover}></div> }
            </div>
        )
    }
}

Arrow.propTypes = {
    direction: PropTypes.number.isRequired,  // -1 - слева, 0 - по центру, 1 - справа
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    height_type: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    onDel: PropTypes.func.isRequired,
    text: PropTypes.string,
    is_custom: PropTypes.bool,
};

Arrow.defaultProps = {
    text: "",
    is_custom: false,
};