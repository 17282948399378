import React from "react";
// import shortid from "shortid";

export default class Selection extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.state = {
      open: false
    };
  }

  switchOpen = () => {
    console.log("switch open function");

    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  };

  render() {
    var selection = this.props.selection;
    if (Object.keys(selection).length > 0) {
      var button = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {this.props.button}
          <div style={{ cursor: "pointer" }} onClick={this.switchOpen}>
            Скрыть
          </div>
        </div>
      );
    } else {
      var button = "";
    }
    return (
      <div className="selection">
        {this.state.open && (
          <div>
            {Object.keys(selection)
              .sort()
              .map((item, index) => (
                <div key={item} className="data-select-field">
                  <input
                    defaultValue={selection[item]}
                    onBlur={e =>
                      this.props.onChange({
                        parent: this.props.index,
                        item: "selection",
                        key: item,
                        value: e.target.value
                      })
                    }
                  ></input>
                  <svg
                    className="delete_input_field"
                    onClick={() =>
                      this.props.onClick({
                        parent: this.props.index,
                        item: "selection",
                        key: item
                      })
                    }
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.54"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z"
                      fill="black"
                    />
                  </svg>
                </div>
              ))}
            {button}
          </div>
        )}
        {!this.state.open && Object.keys(selection).length >= 1 && selection[0] != "" && (
          <p onClick={this.switchOpen}>
            {Object.keys(selection).map((item, index) => {
              if (index < 2) {
                return <span key={index}>{selection[item]}, </span>;
              } else if (
                index >= 2 &&
                index == Object.keys(selection).length - 1
              ) {
                return (
                  <span className="more" key={index}>
                    {" "}
                    еще {Object.keys(selection).length - 2} &#8595;
                  </span>
                );
              }
            })}
          </p>
        )}
        {Object.keys(selection).length <= 2 && this.state.open == false && (
          <p className="more" onClick={this.switchOpen}>
            Добавить
          </p>
        )}
      </div>
    );
  }
  componentDidMount() {
    var elems = document.querySelectorAll("select");
    var options = {};
    var instances = M.FormSelect.init(elems, options);
  }
  componentDidUpdate() {
    var elems = document.querySelectorAll("select");
    var options = {};
    var instances = M.FormSelect.init(elems, options);
  }
}
