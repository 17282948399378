import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

export default class Btn extends React.Component {

    constructor(props) {
        super(props);
        this.preHandler = this.preHandler.bind(this);
        this.afterHandler = this.afterHandler.bind(this);
        // let classes = "btn";
        let classes = "button";
        this.state = {
            loading: false
        };
        let color = this.props.color;
        if (color == undefined)
            color = "blue";
        classes += " btn_" + color;
        let size = this.props.size;
        if (size != undefined)
            classes += " btn_" + size;
        let marked = this.props.marked;
        if (marked)
            classes += " btn_marked";
        this.classes = classes;
        this.indicator = this.props.indicator || false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props !== nextProps;
    }

    afterHandler() {
        if (this.indicator) {
            this.setState({ loading: false });
        }
    }

    preHandler(e) {
        e.preventDefault();
        if (this.props.disabled != true) {
            if (this.indicator) {
                this.setState({ loading: true });
            }
            if ((this.indicator && this.state.loading == false) || !this.indicator) {
                this.props.handler(this.afterHandler, this.props.value);
            }
        }
    }

    render() {
        const classNames = this.classes + " " + this.props.iconClass + " " + (this.props.disabled == true ? "disabled" : "");
        const text = this.props.text || this.props.value || "";
        const indicator = this.indicator == true && this.state.loading && <span className="btn__loading_wrapper"><span className="loading_ico">&nbsp;</span></span>;
        let styles = {};
        if (!isNaN(this.props.width))
            styles["width"] = this.props.width + "px";

        return (
            <button disabled={this.props.disabled == true ? "disabled" : ""} className={classNames} style={styles} onClick={this.preHandler}>{text} {(text.length == 0 && !indicator) && <span>&nbsp;</span>} {indicator}</button>
        )
    }
}

Btn.propTypes = {
    marked: PropTypes.bool,
    text: PropTypes.string,
    handler: PropTypes.func.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    width: PropTypes.string,
    indicator: PropTypes.bool,
    iconClass: PropTypes.string,
    disabled: PropTypes.bool,
};