import React from 'react'
import PropTypes from 'prop-types';
import Btn from "../Btn";

export default class Calendar extends React.Component {

    constructor(props) {
        super(props);
        this.increaseYear = this.increaseYear.bind(this);
        this.increaseMonth = this.increaseMonth.bind(this);
        this.decreaseYear = this.decreaseYear.bind(this);
        this.decreaseMonth = this.decreaseMonth.bind(this);
        this.handlerChange = this.handlerChange.bind(this);
        this.selectRelativeDate = this.selectRelativeDate.bind(this);
        this.selectDay = this.selectDay.bind(this);

        this.months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
        this.days = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
        this.relatives = Calendar.getRelativesDates();
        this.state = {
            parts: {
                dates: this.props.has_dates,
                times: this.props.has_times,
                relatives: this.props.has_relatives,
                years: this.props.has_years,
            },
            value: {
                year: this.props.value.year || 2018,
                month: this.props.value.month || 0,
                day: this.props.value.day || 0,
                relative_date: this.props.value.relative_date || null,
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || nextProps !== this.props;
    }

    static getdaysInMonth(year, month) {
        return new Date(year, month+1, 0).getDate();
    }

    static getWeekDay(year, month) {
        let day_num = new Date(year, month, 1).getDay();
        let nums = [6, 0, 1, 2, 3, 4, 5];
        return nums[day_num];
    }

    static getRelativesDates() {
        if (UI.relative_dates != undefined)
            return UI.relative_dates;
        else {
            console.error("нету относительных дат")
        }
        return {};
    }

    static parseDateToStr(value) {
        let date = "";
        if (value != undefined && typeof value == "object")
            if ("day" in value && "month" in value && "year" in value && value.day > 0) {
                date += value.day + "." + (value.month + 1) + "." + value.year;
            } else if ("relative_date" in value && value.relative_date != null) {
                let relatives = Calendar.getRelativesDates();
                date = relatives[value.relative_date];
            }
        return date
    }

    increaseYear() {
        var s = Object.assign({}, this.state);
        s.value.year++;
        this.setState(s);
        this.handlerChange();
    }

    increaseMonth() {
        var s = Object.assign({}, this.state);
        if (s.value.month == 11)
            s.value.month = 0;
        else
            s.value.month++;
        this.setState(s);
        this.handlerChange();
    }

    decreaseYear() {
        var s = Object.assign({}, this.state);
        if (s.value.year > 1970)
            s.value.year--;
        this.setState(s);
        this.handlerChange();
    }

    decreaseMonth() {
        var s = Object.assign({}, this.state);
        if (s.value.month == 0)
            s.value.month = 11;
        else
            s.value.month--;
        this.setState(s);
        this.handlerChange();
    }

    selectDay(new_day) {
        var s = Object.assign({}, this.state);
        if (s.value.day != new_day) {
            Calendar.resetAll(s);
            s.value.day = new_day;
            this.setState(s);
            this.handlerChange();
        }
    }

    selectRelativeDate(new_date) {
        var s = Object.assign({}, this.state);
        if (s.value.relative_date != new_date) {
            Calendar.resetAll(s);
            s.value.relative_date = new_date;
            console.log(s.value.relative_date);
            this.setState(s);
            this.handlerChange();
        }
    }

    static resetAll(s) {
        s.value.day = 0;
        s.value.relative_date = null;
    }

    static isRelativeMode(value) {
        if ("relative_date" in value) {
            return value["relative_date"] != null;
        }
        return false;
    }

    handlerChange() {
        var s = Object.assign({}, this.state);
        if (s.value.day > 0 || s.value.relative_date != null) {
            this.props.onChange(s.value);
        }
    }

    render() {
        let s = this.state;
        let start_weekday = Calendar.getWeekDay(s.value.year, s.value.month);
        let count_days_in_month = Calendar.getdaysInMonth(s.value.year, s.value.month);
        if (s.value.day > count_days_in_month)
            s.value.day = 0;
        let i_rows = 1;
        let table = [];
        table.push(<div key="0" className="table_row">{this.days.map(function (day_name, index) {
            return <div className="table_cell cell_header" key={index}>{day_name}</div>
        })}</div>);
        let is_started = false;
        let current_day = 1;
        for (let week=0;week<=Math.ceil(count_days_in_month/7);week++) {
            let week_data = [];
            for (let day=0;day<7;day++) {
                if (day == start_weekday)
                    is_started = true;
                if (is_started && current_day <= count_days_in_month) {
                    week_data.push(<div className={"table_cell cell_active " + (current_day==s.value.day?"cell_selected":"")} onClick={this.selectDay.bind(this,current_day)} key={day}>{current_day}</div>);
                    current_day++;
                } else
                    week_data.push(<div className="table_cell" key={day}>&nbsp;</div>);
            }
            table.push(<div key={i_rows++} className="table_row">{week_data}</div>);
            if (current_day > count_days_in_month)
                break;
        }

        // Относительные даты
        let relative_dates = [];
        Object.keys(this.relatives).forEach( function (key, index) {
            relative_dates.push(<div key={index} onClick={this.selectRelativeDate.bind(this,key)} className={"relative_date_btn" + (s.value.relative_date==key?" relative_date_btn_selected":"")}>{this.relatives[key]}</div>)
        }.bind(this));

        return (
            <div className="calendar">
                { (this.props.has_dates && this.props.has_years) && <div className="calendar__counter_wrapper">
                    <div className="counter_wrapper__btn btn_prev" onClick={this.decreaseYear}>{"<"}</div>
                    <div className="counter_wrapper__btn btn_next" onClick={this.increaseYear}>{">"}</div>
                    <input type="text" readOnly="readOnly" onChange={()=>{}} className="counter_wrapper__field" name="year" value={s.value.year}/>
                </div> }
                { this.props.has_dates && <div className="calendar__counter_wrapper">
                    <div className="counter_wrapper__btn btn_prev" onClick={this.decreaseMonth}>{"<"}</div>
                    <div className="counter_wrapper__btn btn_next" onClick={this.increaseMonth}>{">"}</div>
                    <input type="text" readOnly="readOnly" onChange={()=>{}} className="counter_wrapper__field" name="month" value={this.months[s.value.month]}/>
                </div> }
                { this.props.has_dates && <div className="wrapper_group">
                    {table}
                </div> }
                { this.props.has_relatives && <div className="wrapper_group flex-center">
                    {relative_dates}
                </div> }
            </div>
        )
    }
}

Calendar.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    has_dates: PropTypes.bool,
    has_times: PropTypes.bool,
    has_relatives: PropTypes.bool,
    has_years: PropTypes.bool,
};

Calendar.defaultProps  = {
    has_dates: true,
    has_times: true,
    has_relatives: true,
    has_years: true,
};