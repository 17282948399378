import React from 'react'
import LoadingIndicator from "../parts/LoadingIndicator.jsx";


export default class HomeLeads extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="home_leads">
                <div className="first_row">
                    <div className="text">Всего лидов</div>
                    <div className="total_leads">{this.props.leads.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}</div>
                </div>
                <div className="second_row">
                    <div className="text">В сегментах</div>
                    <div className="leads_in_segments">{this.props.customers.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}</div>
                </div>
            </div>
        );
    }
}