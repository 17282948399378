import ChannelAccount from './ChannelAccount';
import PropTypes from 'prop-types';

export default class YAAccount extends ChannelAccount {

    constructor(params) {
        super();
        this.auth_url = "https://oauth.yandex.ru/authorize";
        this.auth_url += "?client_id="+params["client_id"]+"&redirect_uri=https://"+location.host+"/api/channel_auth/ya/init_token/&response_type=code&state="+localStorage.getItem("token");
    }

}

YAAccount.PropTypes = {
    params: PropTypes.number.isRequired,
};