import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import Btn from "./Btn.jsx"
const Velocity = require('../../../../node_modules/velocity-react/lib/velocity-animate-shim');

export default class ModelInfo extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillEnter(callback) {
        let root = this.refs.model_info;
        Velocity(root, 'fadeIn', { duration: 300, display: "flex" }).then(callback);
    }

    componentWillLeave(callback) {
        let root = this.refs.model_info;
        Velocity(root, 'fadeOut', { duration: 300}).then(callback);
    }

    render() {
        return (
            <div ref="model_info" className='model_wrapper'>
                <div className='model'>
                    <div className="model__ico ico_info">&nbsp;</div>
                    <div className='model__msg'>{this.props.msg}</div>
                    <div className='model__control_panel'>
                        <Btn color="blue" size="mid" text="Хорошо" handler={this.props.handlerCancel}/>
                    </div>
                </div>
            </div>
        );
    }
}

ModelInfo.propTypes = {
    handlerCancel: PropTypes.func.isRequired,
    // msg: PropTypes.isRequired,
};