import React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import App from "./components/App.jsx";
import "../styles/index.scss";
import UI from "./interfaces/ui.js";
import URLMAP from "./url.js";
import { createBrowserHistory } from "history";
import "../../node_modules/materialize-css/dist/js/materialize.js";

const history = createBrowserHistory();

class User {
  constructor() {
    this.logged_in = localStorage.getItem("token") ? true : false;
    this.is_checked = false;
    this.fullname = localStorage.getItem("fullname")
      ? localStorage.getItem("fullname")
      : "";
    this.role = "user";
    this.id = localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : 0;
  }
}

window.User = new User();
window.UI = new UI();
window.URLMAP = URLMAP;
render(
  <Router history={history}>
    <App history={history} />
  </Router>,
  document.getElementById("root")
);
