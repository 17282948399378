import React from 'react';
import $ from 'jquery';


export default class ModalConfirmation extends React.Component {

    constructor(props) {
        super(props);
    }

    checkTarget = (e) => {
        if ($(e.target).hasClass('model_wrapper')) {
            this.props.onDelete();
        }
    }

    render() {
        return (
            <div className="model_wrapper" onClick={this.checkTarget}>
                <div className="modal_success">
                    <div className="modal_success_content">
                        <div className="close_modal_success" onClick={this.props.onDelete}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z" fill="black" fillOpacity="0.54" />
                            </svg>
                        </div>
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">

                            {this.props.failure ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width="56px" height="56px"><path fill="#FF0000" d="M40,2C19,2,2,19,2,40s17,38,38,38s38-17,38-38S61,2,40,2z M12,40c0-15.5,12.5-28,28-28 c5.9,0,11.4,1.9,15.9,5L17,55.9C13.9,51.4,12,45.9,12,40z M40,68c-5.9,0-11.4-1.9-15.9-5L63,24.1c3.1,4.5,5,10,5,15.9 C68,55.5,55.5,68,40,68z" /><path fill="#c74343" d="M40,78.5C18.8,78.5,1.5,61.2,1.5,40C1.5,18.8,18.8,1.5,40,1.5c21.2,0,38.5,17.3,38.5,38.5 C78.5,61.2,61.2,78.5,40,78.5z M40,2.5C19.3,2.5,2.5,19.3,2.5,40S19.3,77.5,40,77.5S77.5,60.7,77.5,40S60.7,2.5,40,2.5z M40,68.5 c-5.8,0-11.4-1.8-16.2-5.1l-0.5-0.3l39.8-39.8l0.3,0.5c3.3,4.8,5.1,10.4,5.1,16.2C68.5,55.7,55.7,68.5,40,68.5z M24.8,62.9 c4.5,3,9.7,4.6,15.2,4.6c15.2,0,27.5-12.3,27.5-27.5c0-5.4-1.6-10.6-4.6-15.2L24.8,62.9z M16.9,56.7l-0.3-0.5 c-3.3-4.8-5.1-10.4-5.1-16.2c0-15.7,12.8-28.5,28.5-28.5c5.8,0,11.4,1.8,16.2,5.1l0.5,0.3L16.9,56.7z M40,12.5 c-15.2,0-27.5,12.3-27.5,27.5c0,5.4,1.6,10.6,4.6,15.2l38.1-38.1C50.6,14.1,45.4,12.5,40,12.5z" /></svg> : <path fillRule="evenodd" clipRule="evenodd" d="M27.9998 4.66675C15.1198 4.66675 4.6665 15.1201 4.6665 28.0001C4.6665 40.8801 15.1198 51.3334 27.9998 51.3334C40.8798 51.3334 51.3332 40.8801 51.3332 28.0001C51.3332 15.1201 40.8798 4.66675 27.9998 4.66675ZM27.9999 46.6667C17.7099 46.6667 9.33324 38.29 9.33324 28C9.33324 17.71 17.7099 9.33338 27.9999 9.33338C38.2899 9.33338 46.6666 17.71 46.6666 28C46.6666 38.29 38.2899 46.6667 27.9999 46.6667ZM23.3331 33.0635L38.7098 17.6868L41.9998 21.0002L23.3331 39.6668L13.9998 30.3335L17.2898 27.0435L23.3331 33.0635Z" fill="#00F399" />}

                        </svg>
                        <div className="modal_success_text"> {this.props.text}</div>
                    </div>
                </div>
            </div>
        );
    }
}