import React from "react";
import UserItem from "./UserItem.jsx";
import Btn from "../parts/Btn.jsx";
import LoadingIndicator from "../parts/LoadingIndicator.jsx";
import Search from "../parts/Search.jsx";
import Template from "./UserItemTemplate.js";
import ModalConfirmation from "../parts/ModalConfirmation.jsx";
// import ReactTransitionGroup from 'react-addons-transition-group';
import $ from "jquery";

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      crms: [],
      permissions: [],
      is_template: false,
      loading: true,
      modal_confirmation: false,
      confirmation_fail: false,
      confirmation_text: ""
    };
    this.hideTemplate = this.hideTemplate.bind(this);
    this.updateItems = this.updateItems.bind(this);
    this.showTemplate = this.showTemplate.bind(this);
    this.addUser = this.addUser.bind(this);
    this.delUser = this.delUser.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  componentDidMount() {
    UI.get("/api/users/").then(
      function (data) {
        data.users.forEach(function (user) {
          user.hidden = false;
        });
        this.setState({
          users: data.users,
          crms: data.crms,
          permissions: data.permissions,
          loading: false
        });
      }.bind(this),
      function () {
        this.props.history.push("/");
      }.bind(this)
    );
  }

  showTemplate() {
    let s = this.state;
    s.is_template = true;
    this.setState(s);
  }

  hideTemplate() {
    let s = this.state;
    s.is_template = false;
    this.setState(s);
  }

  addUser(data) {
    let s = this.state;
    s.users.unshift(data);
    s.is_template = false;
    this.setState(s);
  }

  delUser(id, active) {
    let s = this.state;
    active == true
      ? (s.confirmation_text = "Пользователь заблокирован.")
      : (s.confirmation_text = "Пользователь разблокирован.");
    s.confirmation_fail = false;
    s.modal_confirmation = true;
    s.is_template = false;
    s.users.forEach(function (item, index) {
      if (item.id == id) {
        s.users[index].is_active = !active;

        // s.users.splice(index, 1);
        // this.setState(s);
        return;
      }
    });
    this.setState(s);
  }

  updateItems(ids) {
    this.state.users.forEach(function (user) {
      user.hidden = typeof ids == "number" ? false : !ids.includes(user.id);
    });
    this.setState(this.state);
  }

  closeModalConfirmation = () => {
    this.setState({ modal_confirmation: false });
  };

  failure_confirmation = data => {
    // console.log("data failure confirmation, ", data);
    let s = this.state;
    s.modal_confirmation = true;
    s.confirmation_fail = true;
    // s.confirmation_text = "Не удалось добавить пользователя.";
    //test
    s.confirmation_text = data;
    if (data == "Не удалось отправить письмо на почту.") {
      $('input.email').addClass('row_error');
    } else if (data.substring(0, 30) == "Достигнут лимит пользователей." || data == "Недостаточно данных.") {
      s.is_template = false;
    }
    this.setState(s);
  };

  render() {
    const list =
      !this.state.loading &&
      this.state.users
        .sort((a, b) => {
          if (a.is_active && !b.is_active) {
            return -1;
          } else if (!a.is_active && b.is_active) {
            return 1;
          } else {
            return 0;
          }
        })
        .map(item => {
          return (
            !item.hidden && (
              <UserItem
                key={item.id + item.is_active.toString()}
                data={item}
                permissions={this.state.permissions}
                delHandler={this.delUser}
              />
            )
          );
        });
    // const add_btn = User.is_m && <div className="control_buttons">
    //     <Btn text="Добавить" handler={this.showTemplate} />
    // </div>;
    // Для тестов----------------------
    const add_btn = (
      <div className="control_buttons">
        <button
          className="add-user-button"
          onClick={this.showTemplate}
          style={{ fontSize: "16px" }}
        >
          {" "}
          Добавить пользователя
        </button>
        {/* <Btn text="Добавить пользователя" handler={this.showTemplate} /> */}
      </div>
    );
    //--------------------------------
    const loading_ico = this.state.loading && <LoadingIndicator />;

    return (
      <div className="content">

        <div className="page_header">
          <div className="page_title">Пользователи</div>
          <Search
            class="user-search"
            updateItems={this.updateItems}
            items={this.state.users}
            placeholder="Найти пользователя"
          />
          {User.role == "admin" && add_btn}

        </div>
        <div className="sub_header"></div>
        {loading_ico}
        {this.state.modal_confirmation && (
          <ModalConfirmation
            text={this.state.confirmation_text}
            failure={this.state.confirmation_fail}
            onDelete={this.closeModalConfirmation}
          />
        )}
        {!this.state.loading ? (
          <div className="page_main">
            {this.state.is_template && (
              <Template
                crms={this.state.crms}
                hideHandler={this.hideTemplate}
                addHandler={this.addUser}
                failure={this.failure_confirmation}
              />
            )}
            <div className="users-table-headings">
              <div className="users-table-heading-login">Логин</div>
              <div className="users-table-heading-rights">Права доступа</div>
              <div className="users-table-heading-added">Добавлен</div>
              <div ></div>
              <div ></div>
            </div>
            {/* <ReactTransitionGroup> */}
            {list}
            {/* </ReactTransitionGroup> */}
          </div>
        ) : (
            false
          )}
      </div>
    );
  }
}
