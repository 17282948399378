import Rule from  "./Rule.js"
import RuleValNote from "./values/RuleValNote.js"
import RuleValFilter from "./values/RuleValFilter.js"
import RuleValTask from "./values/RuleValTask";
import RuleValMail from "./values/RuleValMail";
import RuleValAdd from "./values/RuleValAdd";

export default class Rules {

    constructor(props) {
        this.init = this.init.bind(this);
        this.addEmpty = this.addEmpty.bind(this);
        this.delItem = this.delItem.bind(this);
        this.hasFilled = this.hasFilled.bind(this);
        this.delEmpty = this.delEmpty.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.setMode = this.setMode.bind(this);
        this.clear = this.clear.bind(this);
        this.rules = [];
        this.type = props.type;
        this.mode = props.mode;
        this.initHandler();
    }

    setMode(mode) {
        this.mode = mode;
        this.clear();
        this.initHandler();
    }

    initHandler () {

        const MODENOTE = "note";
        const MODEMAIL = "mail";
        const MODETASK = "task";
        const MODEADD = "add";
        const MODEDEL = "del";
        const MODEFILTER = "filters";
        const MODEGOAL = "goal";
        const MODELINK = "link";

        let valueHandlers = {};
        valueHandlers[MODENOTE] = RuleValNote;
        valueHandlers[MODEMAIL] = RuleValMail;
        valueHandlers[MODETASK] = RuleValTask;
        valueHandlers[MODEFILTER] = RuleValFilter;
        valueHandlers[MODEADD] = RuleValAdd;
        valueHandlers[MODEDEL] = RuleValAdd;
        this.valueHandler = valueHandlers[this.mode];
    }

    init(list) {
        list.forEach(function (rule_data) {
            let new_rule = new Rule(this.valueHandler);
            new_rule.val.init(rule_data);
            this.rules.push(new_rule)
        }.bind(this));
    }

    addEmpty() {
        let has_empty = this.rules.some((rule) => rule.isEmpty());
        if (!has_empty)
            this.rules.push(new Rule(this.valueHandler));
    }

    hasFilled() {
        return this.rules.some((rule) => !rule.isEmpty());
    }

    // удаляем пустые и проверяем есть ли заполенные
    delEmpty() {
        this.rules.forEach(function (rule, index) {
            if (rule.isEmpty())
                this.rules.splice(index, 1);
        }.bind(this));
    }

    delItem(index) {
        this.rules.splice(index,1);
    }

    clear() {
        this.rules = [];
    }

    isEmpty () {
        return this.rules.length == 0 || (this.rules.length == 1 && this.rules[0].val.isEmpty())
    }

    map(callback) {
        return this.rules.map(callback);
    }

    serialize() {
        return this.rules.map((rule)=>rule.val.val);
    }
}