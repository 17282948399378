import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import RuleVal from "./RuleVal"

export default class RuleValFilter extends RuleVal {

    constructor(props) {
        super(props);
        this.handlerChange =this.handlerChange.bind(this);
        this.title = "Фильтр";
        this.val = {
            "field": null,
            "condition": null,
            "value": ""
        };
    }

    isEmpty() {
        return this.val.value.length == 0;
    }

    render() {
        return (
            <div className="rule_wrapper">
                <select defaultValue={this.val.field} name="field" className="metrics__field_list" onChange={this.handlerChange}>
                    <option value={null}>---------</option>
                    { ["contacts", "companies", "leads"].map(function (entity_name, index) {
                        return <optgroup key={index} label={{
                            "contacts": "Контакт",
                            "companies": "Компания",
                            "leads": "Сделка"
                        }[entity_name]}>
                            {Object.keys(UI.amoFields[entity_name]).map(function (field_code, index) {
                                return <option key={index} value={field_code}>{UI.amoFields[entity_name][field_code]}</option>
                            }.bind(this))}
                        </optgroup>
                    }.bind(this)) }
                </select>
                <select defaultValue={this.val.condition} name="condition" className="metrics__field_list" onChange={this.handlerChange}>
                    <option value={null}>---------</option>
                    {Object.keys(UI.comparisons).map(function (code, index) {
                        return <option key={index} value={code}>{UI.comparisons[code]}</option>
                    }.bind(this))}
                </select>
                <input defaultValue={this.val.value} name="value" className="metrix_input_text" onChange={this.handlerChange}/>
            </div>
        )
    }
}