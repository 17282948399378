import React from "react";
import { Link, Route, Switch } from "react-router-dom";
import Menu from "./Menu.jsx";
import Segment from "./pages/Segment.jsx";
// import CRMS from "./pages/CRMS.jsx";
import Users from "./pages/Users.jsx";
import History from "./pages/History.jsx";
import Settings from "./pages/Settings.jsx";
import Balance from "./pages/Balance.jsx";
import Channels from "./pages/Channels.jsx";
import Segments from "./pages/Segments.jsx";
import Home from "./pages/Home.jsx";
import Sources from "./pages/Sources.jsx";
import Data from "./pages/Data.js";
// import NotFound404 from "./pages/NotFound404.jsx";
import Models from "./parts/Models.jsx";
import GoTopBtn from "./parts/GoTopBtn.jsx";
import Diagram from "./pages/diagram/Diagram.jsx";
import Raquo from "./parts/Raquo.jsx";

export default class Body extends React.Component {
  constructor(props) {
    super(props);
    this.handleLeftColumn = this.handleLeftColumn.bind(this);
    // this.handleDirectionChange = this.handleDirectionChange.bind(this);
    this.state = {
      left_column: "open",
      user_email: "",
      crm: ""
    };
  }
  componentDidMount() {
    UI.get("/api/users/").then(
      function(data) {
        this.setState({
          user_email: data.current_user.email,
          crm: data.current_user.crm
        });
      }.bind(this)
    );
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }

  handleLeftColumn(direction) {
    let left_column = direction === "down" ? "open" : "folded";
    this.setState({ left_column: left_column });
  }

  handle_logout = () => {
    fetch("/user/logout/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`
      },
      method: "POST"
    })
      .then(res => res.json())
      .then(json => {
        localStorage.removeItem("token");
        localStorage.removeItem("fullname");
        User.logged_in = false;
        User.fullname = "";
        this.props.history.push(URLMAP.get("login"));
      });
  };

  render() {
    return (
      <div className="page">
        <div
          className={
            "left_block " +
            (this.state.left_column == "folded" ? "narrow" : "wide")
          }
        >
          <div
            className={
              "menu_header " +
              (this.state.left_column == "open"
                ? "with-padding"
                : "without-padding")
            }
          >
            <div className="MoreADS">
              {this.state.left_column == "open" ? (
                <svg
                  width="106"
                  height="18"
                  viewBox="0 0 106 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M48.7549 15.3728H57.6195V13.472H50.8255V9.87576H56.9643V7.97549H50.8255V4.48889H57.5088V2.58862H48.7549V15.3728Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M35.6365 4.4889H40.8153C41.1496 4.48686 41.4831 4.52059 41.8103 4.58953C42.1149 4.65451 42.406 4.77214 42.6707 4.93718C42.9238 5.10102 43.1321 5.32585 43.2768 5.59123C43.4354 5.9106 43.5132 6.26431 43.5035 6.62111C43.5133 6.97357 43.4354 7.32291 43.2768 7.63741C43.134 7.90304 42.9251 8.12697 42.6707 8.28743C42.407 8.45451 42.1156 8.57244 41.8103 8.63559C41.4834 8.70645 41.1497 8.74137 40.8153 8.73974H35.6365V15.3728H37.7086V10.5872H40.2912L44.1056 15.3728H46.5927L42.522 10.3688C42.9337 10.2713 43.3321 10.1241 43.7087 9.93061C44.0772 9.73942 44.411 9.48699 44.6958 9.18399C44.9889 8.8659 45.2162 8.49239 45.3644 8.08518C45.5368 7.6053 45.6216 7.09807 45.6147 6.58791C45.6236 6.04598 45.5286 5.50741 45.3349 5.00158C45.1644 4.57272 44.9055 4.18503 44.5751 3.86403C44.2547 3.55224 43.8819 3.29997 43.474 3.11892C43.0504 2.92902 42.6053 2.79183 42.1486 2.71039C41.6785 2.6286 41.2023 2.58786 40.7252 2.58863H35.6365V4.4889Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5353 2.58862L6.70236 9.91148L1.87091 2.58862H0V15.3728H2.07062V6.40476L6.08423 12.4854H7.31999L11.3356 6.42489V15.3728H13.4062V2.58862H11.5353Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.5659 9.00128C15.5656 7.22119 16.0905 5.481 17.074 4.00075C18.0576 2.5205 19.4558 1.36668 21.0918 0.685197C22.7277 0.00371329 24.528 -0.174825 26.2649 0.172159C28.0018 0.519143 29.5973 1.37606 30.8497 2.63456C32.1021 3.89306 32.9551 5.49661 33.3009 7.24242C33.6467 8.98824 33.4697 10.7979 32.7923 12.4426C32.1149 14.0873 30.9675 15.4932 29.4952 16.4824C28.023 17.4716 26.2919 17.9997 24.5211 18C22.1469 17.999 19.8702 17.0506 18.1911 15.3634C16.512 13.6761 15.5678 11.3878 15.5659 9.00128Z"
                      fill="#00E3FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.7458 4.82983L17.9519 8.77578V9.22204L20.7458 13.1685H23.2073L20.2808 9.00117L23.2073 4.82983H20.7458Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M28.2984 4.82983L31.0923 8.77578V9.22204L28.2984 13.1685H25.8369L28.7639 9.00017L25.8369 4.82983H28.2984Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M64.3509 4.82983L67.1468 8.77578V9.22204L64.3509 13.1685H61.8894L64.8184 9.00017L61.8894 4.82983H64.3509Z"
                      fill="#00E3FF"
                    />
                    <path
                      d="M76.1031 2.7583H77.054L82.5306 15.3745H81.3519L79.9065 12.0101H73.2316L71.7671 15.3745H70.6265L76.1031 2.7583ZM79.45 10.9973L76.56 4.23042L73.6696 10.9973H79.45Z"
                      fill="white"
                    />
                    <path
                      d="M84.3193 2.75854H88.1988C92.5533 2.75854 95.0448 5.05276 95.0448 9.06662C95.0448 13.0619 92.5538 15.3747 88.1988 15.3747H84.3193V2.75854ZM88.1798 14.3619C91.9071 14.3619 93.9232 12.4883 93.9232 9.06712C93.9232 5.64593 91.9071 3.77333 88.1798 3.77333H85.4225V14.3619H88.1798Z"
                      fill="white"
                    />
                    <path
                      d="M96.7969 14.2282V12.9302C97.7669 13.9625 99.4211 14.5743 101.284 14.5743C103.605 14.5743 104.898 13.638 104.898 12.013C104.898 10.732 104.097 10.0247 102.102 9.58543L100.03 9.12659C97.8615 8.64863 96.911 7.61724 96.911 6.01079C96.911 3.85091 98.6227 2.53174 101.475 2.53174C103.091 2.53174 104.536 2.93423 105.373 3.63859V4.89991C104.517 4.03958 103.071 3.56162 101.456 3.56162C99.2879 3.56162 98.0141 4.46018 98.0141 5.95092C98.0141 7.09802 98.7554 7.74805 100.676 8.16866L102.806 8.627C105.069 9.10496 106.001 10.2516 106.001 11.9531C106.001 14.2855 104.271 15.6042 101.266 15.6042C99.402 15.6042 97.7473 15.1076 96.7969 14.2282Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="106" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  width="88"
                  height="18"
                  viewBox="0 0 88 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M41.0364 13.2484H47.2249V11.9812H42.4819V9.58368H46.7675V8.31683H42.4819V5.99243H47.1476V4.72559H41.0364V13.2484Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M31.8784 5.99244H35.4938C35.7272 5.99108 35.96 6.01357 36.1884 6.05952C36.4011 6.10284 36.6043 6.18126 36.7891 6.29129C36.9658 6.40052 37.1112 6.55041 37.2122 6.72733C37.3229 6.94024 37.3773 7.17605 37.3705 7.41391C37.3774 7.64889 37.323 7.88178 37.2122 8.09144C37.1125 8.26853 36.9667 8.41782 36.7891 8.52479C36.605 8.63618 36.4016 8.7148 36.1884 8.7569C35.9602 8.80414 35.7273 8.82742 35.4938 8.82633H31.8784V13.2484H33.325V10.058H35.128L37.7909 13.2484H39.5271L36.6853 9.91239C36.9727 9.84734 37.2509 9.74925 37.5138 9.62025C37.771 9.49279 38.004 9.3245 38.2028 9.1225C38.4075 8.91044 38.5662 8.66143 38.6696 8.38996C38.79 8.07004 38.8492 7.73188 38.8443 7.39178C38.8505 7.03049 38.7842 6.67144 38.649 6.33422C38.53 6.04832 38.3492 5.78986 38.1186 5.57586C37.8949 5.368 37.6347 5.19982 37.3499 5.07912C37.0542 4.95252 36.7435 4.86106 36.4246 4.80676C36.0965 4.75224 35.764 4.72508 35.4309 4.72559H31.8784V5.99244Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.0529 4.72559L11.679 9.60749L8.30611 4.72559H7V13.2484H8.44553V7.26968L11.2475 11.3235H12.1102L14.9135 7.2831V13.2484H16.3591V4.72559H15.0529Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.8667 9.00069C17.8665 7.81397 18.2329 6.65384 18.9195 5.667C19.6062 4.68017 20.5823 3.91096 21.7244 3.45664C22.8665 3.00231 24.1232 2.88329 25.3358 3.11461C26.5483 3.34593 27.6622 3.91721 28.5365 4.75621C29.4108 5.59521 30.0063 6.66424 30.2477 7.82812C30.4891 8.992 30.3655 10.1985 29.8926 11.2949C29.4197 12.3914 28.6187 13.3286 27.5909 13.9881C26.5631 14.6476 25.3547 14.9996 24.1184 14.9998C22.4609 14.9991 20.8715 14.3669 19.6993 13.2421C18.5271 12.1172 17.868 10.5917 17.8667 9.00069Z"
                      fill="#00E3FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.4829 6.21973L19.5325 8.85035V9.14786L21.4829 11.7788H23.2013L21.1583 9.00062L23.2013 6.21973H21.4829Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M26.7555 6.21973L28.706 8.85035V9.14786L26.7555 11.7788H25.0371L27.0805 8.99995L25.0371 6.21973H26.7555Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M51.9242 6.21973L53.8761 8.85035V9.14786L51.9242 11.7788H50.2058L52.2506 8.99995L50.2058 6.21973H51.9242Z"
                      fill="#00E3FF"
                    />
                    <path
                      d="M60.1285 4.83838H60.7924L64.6156 13.2491H63.7928L62.7837 11.0063H58.1239L57.1015 13.2491H56.3052L60.1285 4.83838ZM62.465 10.3311L60.4475 5.81979L58.4296 10.3311H62.465Z"
                      fill="white"
                    />
                    <path
                      d="M65.8645 4.83887H68.5728C71.6127 4.83887 73.3521 6.36834 73.3521 9.04425C73.3521 11.7077 71.6131 13.2496 68.5728 13.2496H65.8645V4.83887ZM68.5595 12.5745C71.1616 12.5745 72.5691 11.3254 72.5691 9.04459C72.5691 6.76379 71.1616 5.51539 68.5595 5.51539H66.6346V12.5745H68.5595Z"
                      fill="white"
                    />
                    <path
                      d="M74.575 12.4851V11.6198C75.2521 12.308 76.4069 12.7159 77.7078 12.7159C79.3277 12.7159 80.2306 12.0917 80.2306 11.0083C80.2306 10.1544 79.6715 9.68278 78.2787 9.38996L76.8318 9.08407C75.3182 8.76543 74.6546 8.07783 74.6546 7.00687C74.6546 5.56695 75.8496 4.6875 77.8406 4.6875C78.9688 4.6875 79.9779 4.95583 80.5622 5.4254V6.26628C79.9647 5.69273 78.9549 5.37409 77.8276 5.37409C76.314 5.37409 75.4247 5.97313 75.4247 6.96695C75.4247 7.73169 75.9422 8.16504 77.2829 8.44545L78.77 8.751C80.3497 9.06965 81.0003 9.83405 81.0003 10.9684C81.0003 12.5234 79.7924 13.4025 77.6949 13.4025C76.3937 13.4025 75.2385 13.0714 74.575 12.4851Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect
                        width="74"
                        height="12"
                        fill="white"
                        transform="translate(7 3)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </div>
            <div className={"header_icons " + this.state.left_column}>
              <Link to={URLMAP.get("balance")}>
                <div className="wallet_div" title="Баланс">
                  <img
                    className="wallet"
                    src="../../static/images/white_wallet.png"
                    alt="Кошелёк"
                    height="16"
                    width="16"
                  ></img>
                </div>
              </Link>
              <div className="notifications_div" title="Уведомления">
                <svg
                  className="notifications"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9999 13.125V8.95833C14.9999 6.4 13.6416 4.25833 11.2499 3.69167V3.125C11.2499 2.43333 10.6916 1.875 9.99992 1.875C9.30825 1.875 8.74992 2.43333 8.74992 3.125V3.69167C6.36659 4.25833 4.99992 6.39167 4.99992 8.95833V13.125L3.33325 14.7917V15.625H16.6666V14.7917L14.9999 13.125ZM9.99992 18.125C10.9166 18.125 11.6666 17.375 11.6666 16.4583H8.33325C8.33325 17.375 9.08325 18.125 9.99992 18.125ZM6.66659 13.9583H13.3333V8.95833C13.3333 6.89167 12.0749 5.20833 9.99992 5.20833C7.92492 5.20833 6.66659 6.89167 6.66659 8.95833V13.9583Z"
                    fill="white"
                  />
                  <circle
                    cx="15"
                    cy="5"
                    r="2.5"
                    fill="#F7685B"
                    fillOpacity="0.9"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="profile">
            <div className="profile_photo">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="48"
                  height="48"
                >
                  <circle cx="24" cy="24" r="24" fill="#E5E5E5" />
                </mask>
                <g mask="url(#mask0)">
                  <circle cx="24" cy="24" r="24" fill="white" />
                  <path
                    opacity="0.54"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 16C27.64 16 30.67 18.59 31.35 22.04C33.95 22.22 36 24.36 36 27C36 29.76 33.76 32 31 32H18C14.69 32 12 29.31 12 26C12 22.9 14.35 20.36 17.36 20.04C18.61 17.64 21.11 16 24 16ZM18 30H31C32.65 30 34 28.65 34 27C34 25.35 32.65 24 31 24H29.5V23.5C29.5 20.47 27.03 18 24 18C22.2 18 20.61 18.88 19.6 20.22C22.14 20.92 24 23.24 24 26H22C22 23.79 20.21 22 18 22C15.79 22 14 23.79 14 26C14 28.21 15.79 30 18 30Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </g>
              </svg>
            </div>
            <div className="profile_data">
              <div
                className={
                  "dropdown-trigger profile_title " + this.state.left_column
                }
                data-target="exit"
              >
                {" "}
                {this.state.crm}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.83325 7.91675L9.99992 12.0834L14.1666 7.91675H5.83325Z"
                    fill="white"
                  />
                </svg>
              </div>
              <ul id="exit" className="dropdown-content">
                <li>
                  {" "}
                  <Link to={URLMAP.get("settings")}>Настройки</Link>
                </li>
                <li onClick={this.handle_logout}>
                  {" "}
                  <span>Выйти</span>
                </li>
              </ul>
              <div className={"profile_email " + this.state.left_column}>
                {this.state.user_email}
              </div>
            </div>
          </div>

          <Menu left_column={this.state.left_column} />
          <div className="grower"></div>
          <div className="left_footer">
            {/* <div className="raquo_div"> */}
            <Raquo
              direction_up={this.handleLeftColumn}
              left_column={this.state.left_column}
            />
            {/* <div className={"raquo_text " + this.state.left_column}>Скрыть</div> */}
            {/* </div> */}

            <div className="bottom_footer">© WEB-Regata 2019</div>
          </div>
        </div>
        <div className="right_block">
          <Switch>
            {/* {User.is_m && (
              <Route exact path={URLMAP.get("crm")}>
                <CRMS history={this.props.history} />
              </Route>
            )} */}
            {User.is_m && (
              <Route exact path={URLMAP.get("channels")}>
                <Channels history={this.props.history} />
              </Route>
            )}
            {User.is_m && (
              <Route exact path={URLMAP.get("users")}>
                <Users history={this.props.history} />
              </Route>
            )}
            {User.is_m && (
              <Route exact path={URLMAP.get("sources")}>
                <Sources history={this.props.history} />
              </Route>
            )}
            <Route exact path={URLMAP.get("segments")}>
              <Segments history={this.props.history} />
            </Route>
            <Route exact path={URLMAP.get("segment")}>
              <Segment history={this.props.history} />
            </Route>
            <Route exact path={URLMAP.get("data")}>
              <Data history={this.props.history} />
              {/* <Data data={data} /> */}
            </Route>
            <Route exact path={URLMAP.get("diagram")}>
              <Diagram history={this.props.history} />
            </Route>
            <Route exact path={URLMAP.get("history")}>
              <History history={this.props.history} />
            </Route>
            <Route exact path={URLMAP.get("settings")}>
              <Settings history={this.props.history} />
            </Route>
            <Route exact path={URLMAP.get("balance")}>
              <Balance history={this.props.history} />
            </Route>
            <Route exact path={URLMAP.get("home")}>
              <Home history={this.props.history} />
            </Route>
            {/* <Route exact path="*">
              <NotFound404 />
            </Route> */}
          </Switch>
        </div>
        <Models />
        <GoTopBtn />
      </div>
    );
  }
}
