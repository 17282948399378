import React from 'react'
import PropTypes from 'prop-types';

export default class GoTopBtn extends React.Component {

    constructor(props) {
        super(props);
        this.handlerGoTop = this.handlerGoTop.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            visibility: false
        };
        window.onscroll = this.toggle;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props !== nextProps;
    }

    toggle() {
        var s = this.state;
        if (document.body.scrollTop>0 || document.documentElement.scrollTop>0) {
            if (s.visibility == false) {
                s.visibility = true;
                this.setState(s);
            }
        } else {
            if (s.visibility == true) {
                s.visibility = false;
                this.setState(s);
            }
        }
    }

    handlerGoTop() {
        var inter = setInterval(function () {
            if (document.body.scrollTop>0 || document.documentElement.scrollTop>0) {
                window.scrollBy(0, -20);
            } else {
                clearInterval(inter);
            }
        }, 10);
    }

    render() {

        return (
            <div>
                {this.state.visibility && <div className="go_top_btn" onClick={this.handlerGoTop}>
                    <div className="go_top_ico"></div>
                </div>}
            </div>
        )
    }
}

GoTopBtn.propTypes = {
};