import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import Btn from "./Btn.jsx"
import types from "../pages/diagram/modes.json"
const Velocity = require('../../../../node_modules/velocity-react/lib/velocity-animate-shim');


export default class ModelBlockEdit extends React.Component {

    constructor(props) {
        super(props);
        this.types = types;
        this.preSave = this.preSave.bind(this);
        this.handlerChange = this.handlerChange.bind(this);
        this.addEmptyRule = this.addEmptyRule.bind(this);
        this.delRule = this.delRule.bind(this);
        this.getLimit = this.getLimit.bind(this);
        if (this.props.data.type == "operation") {
            this.limit_metric = "hour";
            if (this.props.data.limit % 24 == 0) {
                this.limit_metric = "day";
            }
        }
    }

    getLimit() {
        let limit_val = this.props.data.limit;
        if (this.limit_metric == "day") {
            limit_val /= 24;
        }
        return Math.round(limit_val);
    }

    componentWillEnter(callback) {
        let root = document.getElementsByClassName("js-model_confirm")[0];
        Velocity(root, 'fadeIn', { duration: 300, display: "flex" }).then(callback);
    }

    componentWillLeave(callback) {
        let root = document.getElementsByClassName("js-model_confirm")[0];
        Velocity(root, 'fadeOut', { duration: 300}).then(callback);
    }

    handlerChange(e) {
        switch (e.target.name) {
            case "type":
                document.querySelector("[name='mode']").value = "none";
                this.props.data.setMode(null);
                this.props.data.setType(e.target.value);
                break;
            case "pos":
                let tmp = this.props.data.parent.next[0];
                this.props.data.parent.next[0] = this.props.data.parent.next[1];
                this.props.data.parent.next[1] = tmp;
                tmp = this.props.data.parent.links[0];
                this.props.data.parent.links[0] = this.props.data.parent.links[1];
                this.props.data.parent.links[1] = tmp;
                this.props.data.pos = !this.props.data.pos;
                break;
            case "mode":
                this.props.data.setMode(e.target.value);
                break;
            case "limit_metric":
                this.limit_metric = e.target.value;
                break;
            case "limit_val":
                if (e.target.value >=0) {
                    let m = 1;
                    if (this.limit_metric == "day")
                        m = 24;
                    this.props.data.limit = e.target.value * m;
                }
        }
        this.forceUpdate();
    }

    preSave() {
        let flag = true;
        if (this.props.data.mode == "none")
            flag = false;
        if (flag && this.props.data.rules.hasFilled()) {
            this.props.data.rules.delEmpty();
            this.props.handlerConfirm();
        }
    }

    addEmptyRule(update=true) {
        this.props.data.rules.addEmpty();
        if (update)
            this.forceUpdate();
    }

    delRule(callback, value) {
        this.props.data.rules.delItem(value);
        this.forceUpdate();
    }

    render() {
        const data = this.props.data;
        const is_editable_type = typeof data.next[1] != "number" && typeof data.next[0] != "number";
        const is_condition = data.parent.type == "condition";
        const is_choose = is_condition && (data.parent.next[1] == null || data.parent.next[1] == data.inner_id) && (data.parent.next[0] == null  || data.parent.next[0] == data.inner_id);


        switch (data.mode) {
            case "note":
            case "task":
                if (data.rules.isEmpty())
                    this.addEmptyRule(false);
                break;
        }
        return (
            <div className='model_wrapper js-model_confirm'>
                <div className='model model__large'>
                    <div className='model__msg'>Редактирование</div>
                    { is_choose && <div className="model__param">
                        <div className="model__param_key">Позиция:</div>
                        <div className="model__param_val">
                            { console.log(data) }
                            <select onChange={this.handlerChange} className="metrics__field_list" name="pos" defaultValue={data.pos*1}>
                                <option value="1">Условие верно</option>
                                <option value="0">Условие не верно</option>
                            </select>
                        </div>
                    </div> }
                    { is_editable_type && <div className="model__param">
                        <div className="model__param_key">Тип блока:</div>
                        <div className="model__param_val"><select onChange={this.handlerChange} className="metrics__field_list field_mid" name="type" defaultValue={data.type}>
                        {Object.keys(this.types.block).map(function (k, i) {
                            let v = this.types.block[k];
                            return <option key={i} value={k}>{v}</option>;
                        }.bind(this))}
                    </select></div>
                    </div> }
                    <div className="model__param">
                        <div className="model__param_key">Тип {data.type == "operation" ? "действия" : "условия"}:</div>
                        <div className="model__param_val">
                            <select onChange={this.handlerChange} className="metrics__field_list field_xlarge" name="mode" defaultValue={data.mode}>
                                <option value="none">--------</option>
                                {Object.keys(this.types[data.type]).map(function (k, i) {
                                    let v = this.types[data.type][k];
                                    return <option key={i} value={k}>{v}</option>;
                                }.bind(this))}
                            </select>
                        </div>
                    </div>
                    { data.mode && data.rules.map(function (rule, index) {
                        return <div key={index} className="model__param">
                            <div className="model__param_key">
                                {rule.title}
                                <Btn handler={this.delRule} value={index} color="red" size="micro" text="✘"/>
                            </div>
                            <div className="model__param_val">
                                {rule.val.render()}
                            </div>
                        </div>
                    }.bind(this))}
                    { data.mode && <div className="model__param">
                        <Btn handler={this.addEmptyRule} color="green" text="Добавить"/>
                    </div> }
                    { data.type == "operation" && <div className="model__param">
                        <div className="model__param_key">
                            Выполнить через
                        </div>
                        <div className="model__param_val">
                            <input type="number" onChange={this.handlerChange} className="metrix_input_text field_micro" value={this.getLimit()} name="limit_val" />
                            <select onChange={this.handlerChange} className="metrics__field_list" name="limit_metric" defaultValue={this.limit_metric}>
                                {Object.keys(this.types["limit"]).map(function (k, i) {
                                    let v = this.types["limit"][k];
                                    return <option key={i} value={k}>{v}</option>;
                                }.bind(this))}
                            </select>
                        </div>
                    </div>}
                    <div className='model__control_panel'>
                        <Btn color="blue" size="large" text="Сохранить" handler={this.preSave}/>
                        <Btn color="red" size="large" text="Отмена" handler={this.props.handlerCancel}/>
                    </div>
                </div>
            </div>
        );
    }
}

ModelBlockEdit.propTypes = {
    handlerConfirm: PropTypes.func.isRequired,
    handlerCancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};