import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import RuleVal from "./RuleVal"

export default class RuleValTask extends RuleVal {

    constructor(props) {
        super(props);
        this.title = "Текст задачи";
        this.val = {
            "text": "",
            "user_id": "0",
            "task_type": "0",
            "complete_till_at": ""
        };
    }

    isEmpty() {
        return this.val.text.length == 0 || this.val.user_id == "0" || this.val.task_type == "0" || this.val.complete_till_at == "";
    }

    render() {
        return <div className="rule_wrapper">
            <span className="param__field_text">Выберите ответственного:</span>
            <select defaultValue={this.val.user_id} name="user_id" className="metrics__field_list field_mid" onChange={this.handlerChange}>
                <option value="0">---------</option>
                { Object.keys(UI.amo_users).map(function (user_id, index) {
                    return <option key={index} value={user_id}>{UI.amo_users[user_id]}</option>
                }.bind(this)) }
            </select><br/>
            <span className="param__field_text">Выберите тип задачи:</span>
            <select defaultValue={this.val.task_type} name="task_type" className="metrics__field_list field_mid" onChange={this.handlerChange}>
                <option value="0">---------</option>
                { Object.keys(UI.amo_tasks).map(function (task_id, index) {
                    return <option key={index} value={task_id}>{UI.amo_tasks[task_id]}</option>
                }.bind(this)) }
            </select><br/>
            <span className="param__field_text">Выберите время окончания:</span>
            <input type="date" defaultValue={this.val.complete_till_at} className="param_val__date" name="complete_till_at" onChange={this.handlerChange}/><br/>
            <textarea defaultValue={this.val.text} placeholder="Текст задачи" onChange={this.handlerChange} name="text" className="param_val__textarea"/>
        </div>
    }
}