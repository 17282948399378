import React from "react";
import $ from "jquery";
import DateField from "../parts/DateField.jsx";

export default class SegmentEntity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metric: {}
    };
    this.addField = this.addField.bind(this);
    this.deleteDataField = this.deleteDataField.bind(this);
    this.deleteEntity = this.deleteEntity.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    var displayed_data = this.props.metric;
    var initial_length = Object.entries(displayed_data).length;
    var data_state_array = Object.entries(this.props.metric);
    // console.log("displayed (=this.props.metric) data (before)", displayed_data);
    for (let i = 0; i < data_state_array.length; i++) {
      if (data_state_array[i][0] in this.props.entity.fields.data == false) {
        // console.log("shit to delete, ", displayed_data[data_state_array[i][0]]);
        delete displayed_data[data_state_array[i][0]];
      }
    }
    // console.log("displayed (=this.props.metric) data (after)", displayed_data);
    var final_length = Object.entries(displayed_data).length;
    if (final_length < initial_length) {
      this.props.metricChange(this.props.entity.model, displayed_data);
      this.props.saveMetrics();
    }

    this.setState({ metric: this.props.metric }, this.initializeMaterialize());
  }

  deleteEntity(e) {
    var name = $(e.target)
      .closest("div.segment_entity")
      .attr("name");
    this.props.onDelete(name);
  }

  addField(e) {
    var name = $(e.target).attr("name");
    var displayed_data = this.props.metric;
    displayed_data[name] = this.props.entity.fields.data[name];
    displayed_data[name].name = displayed_data[name].title;
    displayed_data[name].val = { val: "0", comparison: "is" };
    this.props.metricChange(this.props.entity.model, displayed_data);
  }
  deleteDataField(e) {
    name = $(e.target)
      .closest(".segment_entity_row")
      .attr("name");
    var displayed_data = this.props.metric;
    delete displayed_data[name];
    this.props.metricChange(this.props.entity.model, displayed_data);
  }

  handleValueChange(e) {
    var name = $(e.target)
      .closest("div.segment_entity_row")
      .attr("name");
    var displayed_data = this.props.metric;
    displayed_data[name].val.val = $(e.target).val();
    this.props.metricChange(this.props.entity.model, displayed_data);
  }
  handleDateChange(data) {
    var displayed_data = this.props.metric;
    var name = data.name;
    displayed_data[name].val.comparison = "is_date";
    displayed_data[name].val.val = data.value;
    this.props.metricChange(this.props.entity.model, displayed_data);
  }

  changeComparison = e => {
    var name = $(e.target)
      .closest("div.segment_entity_row")
      .attr("name");
    var displayed_data = this.props.metric;
    displayed_data[name].val.comparison = $(e.target).val();
    this.props.metricChange(this.props.entity.model, displayed_data);
  };

  render() {
    var data_props = this.props.entity.fields.data;
    var data_props_array = Object.entries(this.props.entity.fields.data);
    var metricable_fields = data_props_array.filter((item, index) => {
      if (
        ["number", "date", "select", "multiselect"].indexOf(item[1].type) != -1
      ) {
        return item;
      }
    });
    var data_state_array = Object.entries(this.state.metric);
    // var opt_group_array = [];
    var opt_group_object = {};
    if ("status_id" in this.props.entity.fields.data) {
      var selection = this.props.entity.fields.data.status_id.selection;
      for (let i in Object.keys(selection)) {
        let key = Object.keys(selection)[i];
        let value = selection[key];
        // console.log('key, ', key)
        // console.log('value, ', value)
        if (value.indexOf(this.props.delimiter) != -1) {
          if (
            value.split(this.props.delimiter)[0] in opt_group_object ==
            false
          ) {
            opt_group_object[value.split(this.props.delimiter)[0]] = {};
            opt_group_object[value.split(this.props.delimiter)[0]][
              key
            ] = value.split(this.props.delimiter)[1];
          } else {
            opt_group_object[value.split(this.props.delimiter)[0]][
              key
            ] = value.split(this.props.delimiter)[1];
          }
        } else {
          opt_group_object[key] = value;
        }
      }
    }
    return (
      <div className="segment_entity" name={this.props.entity.model}>
        <div className="segment_entity_heading">
          <div>{this.props.entity.name}</div>
          <div
            className="dots dropdown-trigger"
            data-target={"dropdown-" + this.props.entity.model}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12Z"
                fill="#C4C4C4"
              />
            </svg>
            <ul
              id={"dropdown-" + this.props.entity.model}
              className="dropdown-content"
            >
              <li name="role" onClick={this.deleteEntity}>
                <span>Удалить</span>
              </li>
            </ul>
          </div>
        </div>
        {data_state_array.map((data_field, index) => {
          return (
            <div
              className="segment_entity_row"
              key={data_field[0]}
              name={data_field[0]}
            >
              <div
                className="segment-entity-field-name"
                title={data_props[data_field[0]].title}
              >
                {data_props[data_field[0]].title}{" "}
              </div>
              {data_props[data_field[0]].type == "number" && (
                <div style={{ display: "flex" }} className="metrics_div">
                  <select
                    className="select-dropdown"
                    defaultValue={data_field[1].val.comparison}
                    onChange={this.changeComparison}
                  >
                    <option value="lt">&lt;</option>
                    <option value="lte">&le;</option>
                    <option value="is"> = </option>
                    <option value="gte">&ge;</option>
                    <option value="gt">&gt;</option>
                    <option value="not">&ne;</option>
                  </select>
                  <div className="input-field">
                    <input
                      min="0"
                      defaultValue={data_field[1].val.val}
                      onChange={this.handleValueChange}
                    ></input>
                  </div>
                </div>
              )}
              {(data_props[data_field[0]].type == "select" ||
                data_props[data_field[0]].type == "multiselect") && (
                  <div style={{ display: "flex" }} className="metrics_div">
                    <select
                      className="select-dropdown"
                      defaultValue={data_field[1].val.comparison}
                      onChange={this.changeComparison}
                    >
                      <option value="is"> = </option>
                      <option value="not">&ne;</option>
                    </select>

                    <select
                      multiple
                      className="select-dropdown"
                      onChange={this.handleValueChange}
                      defaultValue={
                        typeof data_field[1].val.val == "string"
                          ? [data_field[1].val.val]
                          : data_field[1].val.val
                      }
                    >
                      {data_field[0] != "status_id"
                        ? Object.keys(data_props[data_field[0]].selection).map(
                          (key, index) => {
                            return (
                              <option value={key} key={index}>
                                {data_props[data_field[0]].selection[key]}
                              </option>
                            );
                          }
                        )
                        : Object.keys(opt_group_object).map((item, index) => {
                          if (typeof opt_group_object[item] == "object") {
                            return (
                              <optgroup label={item} key={index}>
                                {Object.keys(opt_group_object[item]).map(
                                  (key, index2) => {
                                    return (
                                      <option value={key} key={index2}>
                                        {opt_group_object[item][key]}
                                      </option>
                                    );
                                  }
                                )}
                              </optgroup>
                            );
                          } else
                            return (
                              <option value={item} key={index}>
                                {opt_group_object[item]}
                              </option>
                            );
                        })}
                    </select>
                  </div>
                )}
              {data_props[data_field[0]].type == "date" && (
                <DateField
                  key={index}
                  name={data_field[0]}
                  onChange={this.handleDateChange}
                  defaultValue={data_field[1].val.val}
                />
              )}
              {/* <div className="grower"></div> */}
              <div className="delete_data_field" onClick={this.deleteDataField}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.66659 2H6.33325L5.66659 2.66667H3.33325V4H12.6666V2.66667H10.3333L9.66659 2ZM10.6666 6V12.6667H5.33325V6H10.6666ZM3.99998 4.66668H12V12.6667C12 13.4 11.4 14 10.6667 14H5.33332C4.59998 14 3.99998 13.4 3.99998 12.6667V4.66668Z"
                    fill="#F8776B"
                  />
                </svg>
              </div>
            </div>
          );
        })}
        <div className="grower"></div>
        {metricable_fields.length > data_state_array.length && (
          <div
            className="segment_entity_footer dropdown-trigger"
            data-target={"dropdown-" + this.props.entity.model + "-add"}
          >
            + Добавить
          </div>
        )}
        <ul
          id={"dropdown-" + this.props.entity.model + "-add"}
          className="dropdown-content"
        >
          {metricable_fields.map((item, index) => {
            if (item[0] in this.state.metric == false) {
              return (
                <li
                  name="role"
                  key={index}
                  name={item[0]}
                  onClick={this.addField}
                >
                  {item[1].title}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }

  initializeMaterialize = () => {
    var options = {};
    var elems = document.querySelectorAll(".dropdown-trigger");
    var instances = M.Dropdown.init(elems, options);
    var elems2 = document.querySelectorAll("select");
    var instances2 = M.FormSelect.init(elems2, options);
  };
  componentDidUpdate() {
    var options = {};
    var elems = document.querySelectorAll(".dropdown-trigger");
    var instances = M.Dropdown.init(elems, options);
    var elems2 = document.querySelectorAll("select");
    var instances2 = M.FormSelect.init(elems2, options);
  }
}
