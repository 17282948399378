import React from "react";
import PropTypes from "prop-types";

export default class BtnSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.preHandler = this.preHandler.bind(this);
    this.afterHandler = this.afterHandler.bind(this);
    let classes = "switcher_one_val ";
    this.state = {
      loading: false
    };
    let size = this.props.size;
    if (size != undefined) classes += " btn_" + size;
    this.classes = classes;
    this.indicator = this.props.indicator || false;
    this.state.value = this.props.value;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState || this.props !== nextProps;
  }

  /*
    значения параметра value:
    "auto" - значение измениться на противоположное
    true - значение измениться на положительное
    false - значение измениться на отрицательное
    "nochange" - значение не измениться
    значения параметра indicator:
    "hide" - скрыть после нажатия
    "show" - не скрывать после нажатия
     */
  afterHandler(value = "auto", indicator = "hide") {
    var s = this.state;
    if (this.indicator) {
      s.loading = false;
    }
    switch (value) {
      case "auto":
        s.value = !s.value;
        break;
      case true:
        s.value = true;
        break;
      case false:
        s.value = false;
        break;
      case "nochange":
        break;
    }
    if (indicator == "hide") {
      this.setState({ loading: false });
    }
  }

  preHandler(e) {
    e.stopPropagation();
    if (this.indicator) {
      this.setState({ loading: true });
    }
    if ((this.indicator && this.state.loading == false) || !this.indicator) {
      this.props.handler(this.afterHandler, this.props.return_data);
    }
  }

  render() {
    const titles = this.props.titles;
    const iconClass = this.props.iconClass;
    const classNames =
      this.classes +
      " " +
      (this.props.is_transparent ? "transparent" : "") +
      " " +
      (this.state.value ? "switch switch_on" : "switch switch_off");
    const indicator = this.indicator == true && this.state.loading && (
      <span className="btn__loading_wrapper">
        <span className="loading_ico">&nbsp;</span>
      </span>
    );
    return (
      // <label className={classNames} onClick={!this.props.is_disabled && this.preHandler}>{this.state.value ? <span className={"val1 " + (iconClass.length == 2 ? iconClass[0] : "")}>{titles[1]}&nbsp;</span> : <span className={"val2 " + (iconClass.length == 2 ? iconClass[1] : "")}>{titles[0]}&nbsp;</span>} {indicator}</label>
      <div
        className={classNames}
        onClick={!this.props.is_disabled && this.preHandler}
      >
        <div>
          <div></div>
        </div>
      </div>
    );
  }
}

BtnSwitch.propTypes = {
  handler: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  size: PropTypes.string,
  indicator: PropTypes.bool,
  is_disabled: PropTypes.bool,
  titles: PropTypes.array,
  iconClass: PropTypes.array,
  is_transparent: PropTypes.bool
};

BtnSwitch.defaultProps = {
  indicator: false,
  is_transparent: false,
  return_data: null,
  iconClass: [],
  titles: ["выкл", "вкл"]
};
