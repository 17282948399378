import React from 'react'


export default class PopUp extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ position: "relative", bottom: this.props.bottom, left: this.props.left }}>
                <svg style={{ position: "relative", top: "6px", left: "88px" }} width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.4594 0.867508C11.0746 0.506272 10.4754 0.506272 10.0906 0.867508L0.362503 9.99998L21.1875 9.99998L11.4594 0.867508Z" fill="#2F2E41" />
                </svg>
                <div className="pop-up">
                    {this.props.text}
                </div>
            </div>
        );
    }
}