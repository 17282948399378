import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import RuleVal from "./RuleVal"

export default class RuleValMail extends RuleVal {

    constructor(props) {
        super(props);
        this.title = "Текст письма";
        this.val = {
            "text": "",
            "title": ""
        };
    }

    isEmpty() {
        return this.val.text.length == 0 || this.val.title.length == 0;
    }

    render() {
        return <div className="rule_wrapper">
            <input type="text" defaultValue={this.val.title} name="title" onChange={this.handlerChange} className="param_val__text"/>
            <textarea defaultValue={this.val.text} onChange={this.handlerChange} name="text" className="param_val__textarea"/>
        </div>
    }
}