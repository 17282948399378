import React from "react";
import { Link, Redirect } from "react-router-dom";
import Btn from "../parts/Btn.jsx";
import PropTypes from "prop-types";
import $ from "jquery";
import PopUp from "../parts/PopUp.jsx";

export default class SegmentItemTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.saveSegment = this.saveSegment.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.state = { name_prompt: false };
    this.data = {
      name: ""
    };
  }

  saveSegment(hideIndicator) {
    var root = document.querySelector(".js-new_segment");
    var data_to_send = this.data;
    if (UI.isFilled(data_to_send["name"])) {
      UI.post("/api/segments/", data_to_send).then(
        function(data) {
          var new_segment = data_to_send;
          new_segment["id"] = data.id;
          new_segment["count_customers"] = "0";
          new_segment["active_channels"] = [];
          new_segment["is_active"] = false;
          new_segment["created"] = data.new_segment.created;
          new_segment["updated_at"] = data.new_segment.updated_at;
          // hideIndicator();
          this.props.addHandler(new_segment);
          root.classList.remove("row_error");
        }.bind(this),
        function() {
          root.classList.add("row_error");
          // hideIndicator();
        }
      );
    } else {
      // root.classList.add("row_error");
      this.setState({ name_prompt: true });
      // $(root).append(``);
      // hideIndicator();
    }
  }

  hideNamePrompt = () => {
    this.setState({ name_prompt: false });
  };

  handlerChange(e) {
    let d = this.data;
    d[e.target.name] = e.target.value;
    this.forceUpdate();
  }

  imitateCancelButton = e => {
    $(
      $(e.target)
        .closest(".new_segment")
        .find("div.new_segment_buttons")
        .find("button")[1]
    ).click();
  };

  render() {
    return (
      // <a className="js-new_segment">
      //     <div className="row__left">
      //         <div className="row__title"><input type="text" className="input_text" placeholder="Название" onChange={this.handlerChange} name="name" /> </div>
      //     </div>
      //     <div className="row__info">
      //         <div className="row__item row__item_btns">
      //             <Btn color="green" size="min" handler={this.saveSegment} indicator={true} text="+" />
      //             <Btn color="red" size="min" handler={this.props.hideHandler} text="&#10008;" /></div>
      //     </div>
      // </a>
      <div className="model_wrapper js-model_confirm">
        <div className="new_segment js-new_segment">
          <div className="new_segment_title_row">
            <div className="new_segment_title">Создание сегмента</div>
            <div
              className="close_modal_new_segment"
              onClick={this.imitateCancelButton}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </div>
          </div>
          <div className="new_segment_content">
            <div>Назавание </div>
            <input
              type="text"
              className="input_text"
              onChange={this.handlerChange}
              onFocus={this.hideNamePrompt}
              name="name"
            />
          </div>
          <div className="new_segment_buttons">
            <Btn
              color="green"
              size="min"
              handler={this.saveSegment}
              indicator={false}
              text="Создать"
            />
            <Btn
              color="red"
              size="min"
              handler={this.props.hideHandler}
              text="Отмена"
            />
          </div>
          {this.state.name_prompt && (
            <PopUp
              text="Введите название сегмента"
              bottom="100px"
              left="100px"
            />
          )}
        </div>
      </div>
    );
  }
}

SegmentItemTemplate.propTypes = {
  hideHandler: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired
};
