import React from 'react'
import HistoryItem from './HistoryItem.jsx';
import Btn from "../parts/Btn.jsx"
import LoadingIndicator from "../parts/LoadingIndicator.jsx";
import Search from "../parts/Search.jsx"
// import ReactTransitionGroup from 'react-addons-transition-group'
// import { TransitionGroup } from 'react-transition-group'

export default class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "logs": [],
            "count": 50,
            "page": 1,
            "max_pages": 1,
            "loading": true,
            "first_loading": true

        };
        this.updateItems = this.updateItems.bind(this);
        this.loadData = this.loadData.bind(this);
        this.switchPage = this.switchPage.bind(this);
        this.switchCount = this.switchCount.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        UI.query('/api/history/?page=' + this.state.page + "&count=" + this.state.count).then(function (data) {
            data.logs.forEach(function (log) {
                log.hidden = false;
            });
            this.setState({ logs: data.logs, loading: false, first_loading: false, max_pages: data.max_pages });
        }.bind(this), function () {
            this.props.history.push("/")
        }.bind(this));
    }

    updateItems(ids) {
        this.state.logs.forEach(function (log) {
            log.hidden = typeof ids == "number" ? false : !ids.includes(log.id);
        });
        this.setState(this.state);
    }

    switchPage(callback, value) {
        let s = this.state;
        if (!s.loading && value <= s.max_pages) {
            if (s.page != value) {
                s.page = value;
                s.loading = true;
                this.setState(s);
                this.loadData();
            }
        }
    }

    switchCount(callback, value) {
        let s = this.state;
        if (!s.loading && value <= 500) {
            if (s.count != value) {
                s.count = value;
                s.loading = true;
                this.setState(s);
                this.loadData();
            }
        }
    }

    render() {
        const list = this.state.logs.map(item =>
            !item.hidden && <HistoryItem key={item.id} data={item} />
        );
        const cp = this.state.page;  // current page
        const mp = this.state.max_pages;  // max page
        let range_pages = [];
        for (let i = 0; i < this.state.max_pages; i++) {
            range_pages.push(i + 1)
        }
        range_pages = range_pages.slice(cp - 1 > 3 ? cp - 3 : 0, cp - 1 + 3 > mp ? mp : cp - 1 + 3);
        if (cp - 1 > 3) {
            range_pages.unshift("...");
            range_pages.unshift(1);
        }
        if (cp - 1 + 3 < mp) {
            range_pages.push("...");
            range_pages.push(mp);
        }
        let btns_nav = range_pages.map(function (item, index) {
            let is_active = item == cp;
            if (item == "...") {
                return <div key={index} style={{ color: "#bad9ff", fontSize: "18px", display: "inline-block", }}>...</div>;
            } else
                return <Btn key={index + 100 * is_active} marked={is_active} color="light_blue" size="micro" value={item.toString()} handler={this.switchPage} />
        }.bind(this));
        let btns_filters = !this.state.first_loading && <div className="control_nav">
            <i>Записей</i>:
                <Btn key={1 + 100 * (this.state.count == 50)} color="light_blue" size="micro" value="50" marked={this.state.count == 50} handler={this.switchCount} />
            <Btn key={2 + 100 * (this.state.count == 100)} color="light_blue" size="micro" value="100" marked={this.state.count == 100} handler={this.switchCount} />
            <Btn key={3 + 100 * (this.state.count == 200)} color="light_blue" size="micro" value="200" marked={this.state.count == 200} handler={this.switchCount} />
            <Btn key={4 + 100 * (this.state.count == 500)} color="light_blue" size="micro" value="500" marked={this.state.count == 500} handler={this.switchCount} />
            <center style={{ margin: "30px 0 20px 0" }}>{btns_nav}</center>
        </div>;
        const loading_ico = this.state.loading && <LoadingIndicator />;
        return (
            <div className="content">

                <div className="page_header">
                    <div className="page_title">История</div>
                    <Search class="history-search" updateItems={this.updateItems} items={this.state.logs} placeholder="Найти событие" />
                </div>
                <div className="sub_header"></div>
                {/* {btns_filters} */}
                {loading_ico}
                {!this.state.loading ?
                    <div className="page_main">
                        {/* <div className="history-table-headings">
                        </div> */}
                        {/* <ReactTransitionGroup> */}
                        {list}
                        {/* </ReactTransitionGroup> */}
                    </div>
                    : false}
            </div>
        );
    }
}