import React from "react";
import { Link } from "react-router-dom";
import Btn from "../parts/Btn.jsx";
import ChannelIconDistributor from "../parts/ChannelIconDistributor.jsx";
import PropTypes from "prop-types";
const Velocity = require("../../../../node_modules/velocity-react/lib/velocity-animate-shim");
import "../../../styles/parts/_icons.scss";
import $ from "jquery";

export default class SegmentItem extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.del = this.del.bind(this);
    this.rememberCurrentSegmentName = this.rememberCurrentSegmentName.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.toggle_status = this.toggle_status.bind(this);
    this.state = {
      editing: false,
      status: this.props.data.is_active == false ? "switched_off" : "switched_on",
      save_button: true,
      active_channels: [],
    };
  }

  componentWillAppear(callback) {
    // console.log("segmentItem: componentWillAppear");
    let root = document.getElementById(this.props.data.id);
    Velocity(root, "fadeIn", { duration: 500, display: "flex" }).then(callback);
  }
  componentWillEnter(callback) {
    // console.log("segmentItem: componentWillEnter");
    let root = document.getElementById(this.props.data.id);
    Velocity(root, "fadeIn", { duration: 0, display: "flex" });
    Velocity(root, "slideDown", { duration: 500, display: "flex" }).then(callback);
  }
  componentWillLeave(callback) {
    // console.log("segmentItem: componentWillLeave");
    let root = document.getElementById(this.props.data.id);
    Velocity(root, "fadeIn", { duration: 0, display: "flex" });
    Velocity(root, "slideUp", { duration: 300, display: "flex" }).then(callback);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState || this.props.data !== nextProps.data;
  }

  edit() {
    let s = this.state;
    s.editing = true;
    this.setState(s);
    this.tmp_date = Object.assign({}, this.props.data);
  }

  update(e) {
    // console.log("SegmentItem: update");
    var data_to_send = this.props.data;
    let new_name = $(e.target).closest("div.new_segment").find("input[name=name]").val();
    data_to_send.name = new_name;
    if (UI.isFilled(data_to_send["name"])) {
      UI.put("/api/segments/" + data_to_send["id"] + "/", data_to_send).then(
        function (data) {
          this.props.updateSegments();
          this.setState({ editing: false });
          console.log("name changed successfully");
        }.bind(this),
        function () {
          console.log("name change failed (status < 300)");
        }.bind(this),
      );
    } else {
      console.log("name change failed (status > 300)");
    }
  }

  handlerChange(e) {
    $(e.target).closest("input[name=name]").val().trim() ? this.setState({ save_button: true }) : this.setState({ save_button: false });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  cancel(returnValues = true) {
    var root = document.getElementById(this.props.data.id);
    root.classList.remove("row_error");
    let s = this.state;
    s.editing = false;
    if (returnValues) {
      Object.keys(this.tmp_date).forEach(
        function (key) {
          this.props.data[key] = this.tmp_date[key];
        }.bind(this),
      );
    }
    this.setState(s);
  }

  del(hideIndicator) {
    UI.showConfirmModel(
      function () {
        var root = document.getElementById(this.props.data.id);
        var data_to_send = {
          id: this.props.data.id,
        };
        UI.delete("/api/segments/" + data_to_send["id"] + "/", data_to_send).then(
          function (data) {
            root.classList.remove("row_error");
            // hideIndicator();
            this.props.delHandler(data_to_send["id"]);
          }.bind(this),
          function () {
            root.classList.add("row_error");
            // hideIndicator();
          }.bind(this),
        );
      }.bind(this),
      hideIndicator,
      "Удалить сегмент?",
      "Удаление сегмента приведет к потере данных. Восстановить их будет невозможно",
    );
  }

  toggle_status() {
    let d = this.props.data;
    if (this.state.status == "switched_off") {
      d.is_active = true;
      UI.put("/api/segments/" + d["id"] + "/", d).then(
        function (data) {
          this.setState({ status: "switched_on" });
        }.bind(this),
      );
    } else {
      this.setState({ status: "switched_off" });
      d.is_active = false;
      UI.put("/api/segments/" + d["id"] + "/", d).then(
        function (data) {
          this.setState({ status: "switched_off" });
        }.bind(this),
      );
    }
  }

  rememberCurrentSegmentName() {
    UI.current_segment = {
      name: this.props.data.name,
      id: this.props.data.id,
    };
  }

  hideHandler() {
    let s = this.state;
    s.editing = false;
    this.setState(s);
  }

  render() {
    const segment = this.props.data;
    const channels_list = this.props.all_channels.map((code, index) => {
      // return <div key={index} className={"channel_ico ico_" + code + " " + (!segment.active_channels.includes(code) && " channel_ico_off")}>&nbsp;</div>
      if (this.props.data.active_channels.indexOf(code) != -1) {
        let show_warning = segment.count_customers < 100;
        return <ChannelIconDistributor status="active" code={code} key={index} warning={show_warning} title={show_warning && "Необходим охват не менее 100 лидов."} />;
      } else {
        return <ChannelIconDistributor status="inactive" code={code} key={index} />;
      }
    });
    const img_code = {
      switched_off: (
        <div className="switch switch_off">
          <div>
            <div></div>
          </div>
        </div>
      ),
      switched_on: (
        <div className="switch switch_on">
          <div>
            <div></div>
          </div>
        </div>
      ),
    };
    return (
      <div className="segment-row" id={segment.id} style={{ opacity: "0" }}>
        <div className="segments-cell-name segments-table-cell" title={segment.name}>
          {segment.name}
        </div>
        <div className="segments-cell-comprise segments-table-cell">{segment.count_customers}</div>
        <div className="segments-cell-channels segment__item_icons">{channels_list}</div>
        <div className="segments-cell-created segments-table-cell">{"created" in this.props.data && this.props.data.created}</div>
        <div className="segments-cell-updated segments-table-cell">{"updated_at" in this.props.data && this.props.data.updated_at}</div>
        <div className="segments-cell-status">
          <div onClick={this.toggle_status}>{img_code[this.state.status]}</div>

          {this.props.data.is_active && this.props.data.status == "В обработке" && (
            <div className="warning" title={this.props.data.status}>
              <img src="../static/images/warning.png" alt="Редактировать" height="24" width="24"></img>
            </div>
          )}
        </div>
        <div className="segments-cell-dots dropdown-trigger" data-target={"dropdown-" + this.props.data.id}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM10 12C10 10.9 10.9 10 12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12Z"
              fill="#C4C4C4"
            />
          </svg>
          {/* {!this.state.editing && <Btn size="min" text="&#128736;" handler={this.edit} />} */}
          {/* {this.state.editing && <Btn color="green" size="min" handler={this.update} text="&#10003;" indicator={true} />}
                    {this.state.editing && <Btn color="orange" size="min" handler={this.cancel} text="отмена" />} */}
          {/* {!this.state.editing && <Btn size="min" color="red" handler={this.del} indicator={true} text="&#10008;" />} */}
        </div>
        <ul id={"dropdown-" + this.props.data.id} className="dropdown-content">
          {/* <li name="role" onClick={this.edit}>Переименовать</li> */}
          <li name="role">
            <Link to={URLMAP.get("segment", segment.id)} onClick={this.rememberCurrentSegmentName}>
              Редактировать
            </Link>
          </li>
          <li
            name="role"
            onClick={() => {
              this.setState({ editing: true });
            }}>
            <span>Переименовать</span>
          </li>
          <li name="role" onClick={this.del}>
            <span>Удалить</span>
          </li>
        </ul>
        {this.state.editing && (
          <div className="model_wrapper js-model_confirm">
            <div className="new_segment js-new_segment">
              <div className="new_segment_title_row">
                <div className="new_segment_title">Переименовать сегмент</div>
                <div className="close_modal_new_segment" onClick={() => this.setState({ editing: false })}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z" fill="black" fillOpacity="0.54" />
                  </svg>
                </div>
              </div>
              <div className="new_segment_content">
                <div>Название </div>
                <input defaultValue={segment.name} type="text" className="input_text" onChange={this.handlerChange} name="name" />
              </div>
              <div className="new_segment_buttons">
                {this.state.save_button ? <button onClick={this.update}> Сохранить </button> : <div style={{ width: "160px" }}></div>}
                <Btn color="red" size="min" handler={() => this.setState({ editing: false })} text="Отмена" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  componentDidMount() {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }
  componentDidUpdate() {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }
}

SegmentItem.propTypes = {
  data: PropTypes.object.isRequired,
  delHandler: PropTypes.func.isRequired,
  all_channels: PropTypes.array.isRequired,
};
