import ChannelAccount from './ChannelAccount';
import PropTypes from 'prop-types';

export default class VKAccount extends ChannelAccount {

    constructor(params) {
        super();
        this.auth_url = "https://oauth.vk.com/authorize";
        this.auth_url += "?client_id="+params["client_id"]+"&redirect_uri=https://"+location.host+"/api/channel_auth/vk/init_token/&v=5.92&scope=ads,offline&response_type=code&state="+localStorage.getItem("token");
    }

}

VKAccount.PropTypes = {
    params: PropTypes.number.isRequired,

};