import React from "react";
import Btn from "../parts/Btn.jsx";
import BtnSwitch from "../parts/BtnSwitch.jsx";
import PropTypes from "prop-types";

export default class UserItem extends React.Component {
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.del = this.del.bind(this);
    this.handlerChange = this.handlerChange.bind(this);
    this.handlerChangePerm = this.handlerChangePerm.bind(this);
    this.cancel = this.cancel.bind(this);
    this.state = {
      editing: false
    };
  }

  edit() {
    let s = this.state;
    s.editing = true;
    this.setState(s);
    this.tmp_date = Object.assign({}, this.props.data);
  }

  update(hideIndicator) {
    var root = document.getElementById(this.props.data.id);
    var data_to_send = this.props.data;
    if (
      UI.isFilled(data_to_send["fullname"]) &&
      UI.isFilled(data_to_send["email"]) &&
      UI.isFilled(data_to_send["role"]) &&
      UI.isFilled(data_to_send["crm_id"])
    ) {
      UI.put("/api/users/", data_to_send).then(
        function(data) {
          root.classList.remove("row_error");
          // hideIndicator();
          this.cancel(false);
        }.bind(this),
        function() {
          root.classList.add("row_error");
          // hideIndicator();
        }.bind(this)
      );
    } else {
      root.classList.add("row_error");
      // hideIndicator();
    }
  }

  // handlerChange(e) {
  //     let d = this.props.data;
  //     d[e.target.name] = e.target.value;
  //     this.setState(this.state);
  // }

  handlerChange(e) {
    let d = this.props.data;
    d["role"] = e.target.innerText;
    this.setState(this.state, () => {
      UI.put("/api/users/", d);
    });
  }

  handlerChangePerm(callback, id) {
    callback();
    if (!this.props.data["permissions"].includes(id * 1)) {
      this.props.data["permissions"].push(id * 1);
    } else {
      this.props.data["permissions"].splice(
        this.props.data["permissions"].indexOf(id * 1),
        1
      );
    }
  }

  cancel(returnValues = true) {
    var root = document.getElementById(this.props.data.id);
    root.classList.remove("row_error");
    let s = this.state;
    s.editing = false;
    if (returnValues) {
      Object.keys(this.tmp_date).forEach(
        function(key) {
          this.props.data[key] = this.tmp_date[key];
        }.bind(this)
      );
    }
    this.setState(s);
  }

  del(hideIndicator) {
    UI.showConfirmModel(
      function() {
        var root = document.getElementById(this.props.data.id);
        var data_to_send = {
          id: this.props.data.id
        };
        UI.delete("/api/users/", data_to_send).then(
          function(data) {
            root.classList.remove("row_error");
            // hideIndicator();
            this.props.delHandler(
              data_to_send["id"],
              this.props.data.is_active
            );
          }.bind(this),
          function() {
            root.classList.add("row_error");
            // hideIndicator();
          }.bind(this)
        );
      }.bind(this),
      hideIndicator,
      this.props.data.is_active
        ? "Заблокировать пользователя?"
        : "Разблокировать пользователя?",
      this.props.data.is_active
        ? "Блокировка пользователя приведет к тому, что он не сможет войти в сервис. Его сегменты сможет редактировать только модератор."
        : "Пользователь сможет создавать и редактировать сегменты.",
      this.props.data.is_active ? "Заблокировать" : "Разблокировать"
    );
  }

  render() {
    const user = this.props.data;
    const user_crm = user.crm_name;
    // const user_role = !this.state.editing || User.role == "user" || user.role == "admin" ? <div className="row__pre_title field_user_role">{user.role}</div> :
    //     <select name="role" onChange={this.handlerChange} defaultValue={user.role.toLowerCase()}>
    //         <option value="moderator">moderator</option>
    //         <option value="user">user</option>
    //     </select>;
    const user_name = this.state.editing ? (
      <input
        type="text"
        className="input_text"
        placeholder="ФИО"
        onChange={this.handlerChange}
        name="fullname"
        defaultValue={user.fullname}
      />
    ) : (
      user.fullname
    );
    const user_email = this.state.editing ? (
      <input
        type="email"
        className="input_text"
        placeholder="Email"
        onChange={this.handlerChange}
        name="email"
        defaultValue={user.email}
      />
    ) : (
      user.email
    );
    const perms =
      this.state.editing &&
      "permissions" in user &&
      Object.keys(this.props.permissions).map(
        function(perm_id, index) {
          let perm_title = this.props.permissions[perm_id];

          return (
            <BtnSwitch
              key={index + item.is_active}
              handler={this.handlerChangePerm}
              return_data={perm_id * 1}
              value={user["permissions"].includes(perm_id * 1)}
              size="xlarge"
              titles={[perm_title, perm_title]}
            />
          );
        }.bind(this)
      );

    return (
      <div
        className={"user-row " + (!user.is_active ? "darkgray" : "")}
        id={user.id}
        // style={{ opacity: "0" }}
      >
        {/* {User.is_a && <div className="row__sup_title">{user_crm}</div>} */}
        <div className="user-login">
          {user_email}
          {/* {console.log(user)} */}
          {/* <div className="row__item row__item_btns">
                        {console.log(user)}
                        {(((User.is_a && user.role == "moderator") || (User.is_m && user.role == "user") || User.id == user.id) && !this.state.editing) && <Btn size="min" text="&#128736;" handler={this.edit} />}
                        {this.state.editing && <Btn color="green" size="min" handler={this.update} text="&#10003;" indicator={true} />}
                        {this.state.editing && <Btn color="orange" size="min" handler={this.cancel} text="отмена" />}
                        {(((User.is_a && user.role == "moderator") || (User.is_m && user.role == "user")) && User.id != user.id && !this.state.editing) && <Btn size="min" color="red" handler={this.del} indicator={true} text="&#10008;" />}
                    </div> */}
        </div>
        <div className="user-rights">
          {user.role}
          {/* <div className="row__title">{user_name}</div> */}
        </div>
        <div className="user-added">{this.props.data.created_at}</div>

        <div className="user-change-rights">
          {(User.role == "admin" && user.role != "admin") ||
          (User.role == "moderator" && user.role == "user") ? (
            <span
              className="dropdown-trigger"
              data-target={"dropdown-" + this.props.data.email}
            >
              Изменить роль
            </span>
          ) : (
            <span></span>
          )}
          <ul
            id={"dropdown-" + this.props.data.email}
            className="dropdown-content"
          >
            <li name="role" onClick={this.handlerChange}>
              <span>user</span>
            </li>
            <li name="role" onClick={this.handlerChange}>
              <span>moderator</span>
            </li>
          </ul>
        </div>
        {((User.role == "admin" && user.role != "admin") ||
          (User.role == "moderator" && user.role == "user")) && (
          <div className="user-delete" onClick={this.del}>
            {user.is_active ? "Заблокировать" : "Разблокировать"}
          </div>
        )}
        {/* <Btn size="min" color="red" handler={this.del} indicator={true} text="Удалить" /> */}
        {/* -----------------------the following block is currently not used------------------ */}
        {this.state.editing ? (
          <div className="row__options js-options">
            {user.id == User.id ? (
              <div className="options_group js-edit_pass_group">
                <div className="options__title">Редактирование пароля:</div>
                <input
                  type="password"
                  className="input_text input_text_min"
                  onChange={this.handlerChange}
                  placeholder="Введите старый пароль"
                  name="old_pass"
                />
                <input
                  type="password"
                  className="input_text input_text_min"
                  onChange={this.handlerChange}
                  placeholder="Введите новый пароль"
                  name="new_pass"
                />
              </div>
            ) : (
              false
            )}
            {user.role == "user" && user.id != User.id && perms ? (
              <div className="options_group">
                <div className="options__title">Редактирование доступов:</div>
                {perms}
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          false
        )}
        {/* ----------------------------------------------------------- */}
      </div>
    );
  }
  componentDidMount() {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }
  componentDidUpdate() {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var options = {};
    var instances = M.Dropdown.init(elems, options);
  }
}

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  delHandler: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired
};
