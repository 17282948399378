import RuleVal from "./values/RuleVal.js"

export default class Rule {
    constructor(valueHandler) {
        this.val = new valueHandler();
        this.title = this.val.title;
    }

    isEmpty() {
        if (this.val == null) {
            throw DOMException("value is null");
        } else
            return this.val.isEmpty();
    }



}