import React from 'react'

export default class NoRecords extends React.PureComponent {

    render() {
        return (
            <div className="norecords">записей нет</div>
        );
    }
}
