import React from "react";
import ChannelIconDistributor from "../parts/ChannelIconDistributor.jsx";
import $ from "jquery";

export default class SelectCabinets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: true
    }
  }

  hide_modal = () => {
    this.setState({ display: false })
  }

  triggerCheckbox = (e) => {
    let new_value = $(e.target).prop('checked');
    $(e.target).closest('div').find('input[type=checkbox]').prop('checked', new_value);
    if (new_value == true && $(e.target).closest('div').parent('div').hasClass('clients_content')) {
      $(e.target).closest('div').parent('div').parent('div').children('label').find('input[type=checkbox]').prop('checked', new_value);
    } else if (new_value == false && $(e.target).closest('div').parent('div').hasClass('clients_content') && $(e.target).closest('div').siblings('div').find("input:checkbox:checked").length == 0) {
      $(e.target).closest('div').parent('div').parent('div').children('label').find('input[type=checkbox]').prop('checked', new_value);
    }
  }


  send_tree = () => {
    for (let i = 0; i < this.props.auth_required.length; i++) {
      let new_tree = this.props.auth_required[i].access_tree;
      let channel_id = this.props.auth_required[i].id;
      let channel = $('.modal_content').find(`.channel#${channel_id}`);
      let channel_cabinets = $(channel).children('.channel_content').children('div');
      for (let j = 0; j < channel_cabinets.length; j++) {
        let cabinet = channel_cabinets[j]
        if (!$(cabinet).children('label').find('input[type=checkbox]').prop('checked')) {
          delete new_tree[$(cabinet).attr('id')]
        } else {
          if ($(cabinet).find('div.clients_content').length) {
            let inner_cabinets = $(cabinet).find('div.clients_content').children('div');
            for (let k = 0; k < inner_cabinets.length; k++) {
              let inner_cabinet = inner_cabinets[k];
              if (!$(inner_cabinet).children('label').find('input[type=checkbox]').prop('checked')) {
                delete new_tree[$(cabinet).attr('id')].clients[$(inner_cabinet).attr('id')]
              }
            }
          }
        }
      }
      console.log('new_tree, ', new_tree);
      UI.put(`/api/channels/${channel_id}/channel_access/`, new_tree).then(
        (data) => {
          console.log('Предоставление доступа к кабинетам ', data);
        },
        () => {
          alert(`Не удалось выбрать кабинеты канала ${channel_id}`);
        }
      );
    }
    this.setState({ display: false });
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.display && <div className="model_wrapper">
            <div className="select_cabinets">
              <div className="title_row">
                <div className="title">Выберите кабинеты</div>
                <div
                  className="close_modal"
                  onClick={this.hide_modal}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
                      fill="black"
                      fillOpacity="0.54"
                    />
                  </svg>
                </div>
              </div>
              <div className="modal_content">
                {this.props.auth_required.map(channel => {
                  return (
                    <div key={channel.id} id={channel.id} className="channel">
                      <div className="channel_title">
                        <ChannelIconDistributor status="active" code={this.props.id_code_name[channel.id][0]} />
                        <div className="channel_name">{this.props.id_code_name[channel.id][1]}:</div>
                      </div>
                      <div className="channel_content">
                        {Object.entries(channel.access_tree).map(cabinet => {
                          if (cabinet[1].type == "single") {
                            return (
                              <div className="channel" id={cabinet[0]} key={cabinet[0]}> <label onClick={(e) => this.triggerCheckbox(e)}>
                                <input type="checkbox" />
                                <span>{cabinet[1].name}</span>
                              </label> </div>
                            )
                          } else if (cabinet[1].type == "multi") {
                            return (
                              <div className="channel" id={cabinet[0]} key={cabinet[0]}> <label style={Object.keys(cabinet[1].clients).length ? { color: "black" } : {}} onClick={(e) => this.triggerCheckbox(e)}>
                                <input type="checkbox" />
                                <span>{cabinet[1].name}</span>

                              </label><div className="clients_content" >
                                  {Object.entries(cabinet[1].clients).map(inner_cabinet => {
                                    return (
                                      <div id={inner_cabinet[0]} key={inner_cabinet[0]}> <label onClick={(e) => this.triggerCheckbox(e)}>
                                        <input type="checkbox" />
                                        <span>{inner_cabinet[1].name}</span>
                                      </label></div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>

                    </div>
                  )
                })}
              </div>
              <div className="new_user_buttons">
                <button onClick={this.send_tree}> Принять </button>
                <button onClick={this.hide_modal} > Отмена</button>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}
