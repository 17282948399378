import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import "../../styles/pages/_login.scss";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_visibility: "invisible",
      first_time: false,
      status: ""
    };
  }

  handle_login = (e, data) => {
    // console.log('data, ', data);
    e.preventDefault();
    fetch("/token-auth/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        // console.log('json, ', json);
        if ("non_field_errors" in json == false) {
          localStorage.setItem("token", json.token);
          User.logged_in = true;
          User.is_checked = true;
          User.fullname = json.user.fullname;
          User.role = json.user.user_role;
          User.id = json.user.id;
          User.is_a = User.role == "admin";
          User.is_m = User.is_a || User.role == "moderator";
          localStorage.setItem("fullname", json.user.fullname);
          this.props.updateApp();
          // this.props.history.push("/");
        } else {
          this.setState({ first_time: false, status: "error" });
        }
      });
  }

  handle_change = (e) => {
    const name = e.target.name;
    if ($(e.target).attr('name') == 'email') {
      var value = e.target.value.toLowerCase();
    } else {
      var value = e.target.value;
    }
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  }

  toggle_eye = () => {
    if (this.state.password_visibility == "invisible") {
      this.setState({ password_visibility: "visible" });
      document.querySelector("#id_password").setAttribute("type", "text");
    } else {
      this.setState({ password_visibility: "invisible" });
      document.querySelector("#id_password").setAttribute("type", "password");
    }
  }

  componentDidMount() {
    location.href.includes("reg=1")
      ? this.setState({ first_time: true })
      : false;
  }

  render() {
    const eye_code = {
      invisible: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.9085 5.43742L1.67516 3.20409L2.85016 2.02075L17.6252 16.8041L16.4502 17.9791L13.6002 15.1291C12.4835 15.5708 11.2668 15.8124 10.0002 15.8124C5.8335 15.8124 2.27516 13.2208 0.833496 9.56242C1.47516 7.92075 2.55016 6.50408 3.9085 5.43742ZM10.0001 4.97908C13.1585 4.97908 15.9751 6.75408 17.3501 9.56241C16.8585 10.5791 16.1668 11.4541 15.3418 12.1624L16.5168 13.3374C17.6751 12.3124 18.5918 11.0291 19.1668 9.56241C17.7251 5.90408 14.1668 3.31241 10.0001 3.31241C8.94181 3.31241 7.92514 3.47908 6.96681 3.78741L8.34181 5.16241C8.88348 5.05408 9.43348 4.97908 10.0001 4.97908ZM9.10851 5.92908L10.8335 7.65408C11.3085 7.86241 11.6918 8.24574 11.9002 8.72074L13.6252 10.4457C13.6918 10.1624 13.7418 9.86241 13.7418 9.55408C13.7502 7.48741 12.0668 5.81241 10.0002 5.81241C9.69184 5.81241 9.40018 5.85408 9.10851 5.92908ZM7.92516 9.4541L10.1002 11.6291C10.0668 11.6374 10.0335 11.6458 10.0002 11.6458C8.85016 11.6458 7.91683 10.7124 7.91683 9.56243C7.91683 9.5416 7.91891 9.52493 7.921 9.50827C7.92308 9.4916 7.92516 9.47493 7.92516 9.4541ZM6.55017 8.07908L5.09184 6.62075C4.08351 7.38742 3.23351 8.37075 2.65017 9.56242C4.02517 12.3707 6.84184 14.1457 10.0002 14.1457C10.7918 14.1457 11.5585 14.0291 12.2918 13.8291L11.4752 13.0124C11.0252 13.2041 10.5252 13.3124 10.0002 13.3124C7.93351 13.3124 6.25017 11.6291 6.25017 9.56242C6.25017 9.03742 6.35851 8.53742 6.55017 8.07908Z"
            fill="black"
            fillOpacity="0.54"
          />
        </svg>
      ),
      visible: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.833496 10C2.27516 6.34167 5.8335 3.75 10.0002 3.75C14.1668 3.75 17.7252 6.34167 19.1668 10C17.7252 13.6583 14.1668 16.25 10.0002 16.25C5.8335 16.25 2.27516 13.6583 0.833496 10ZM17.3502 10C15.9752 7.19167 13.1585 5.41667 10.0002 5.41667C6.84184 5.41667 4.02517 7.19167 2.65017 10C4.02517 12.8083 6.84184 14.5833 10.0002 14.5833C13.1585 14.5833 15.9752 12.8083 17.3502 10ZM10.0002 7.91667C11.1502 7.91667 12.0835 8.85 12.0835 10C12.0835 11.15 11.1502 12.0833 10.0002 12.0833C8.85016 12.0833 7.91683 11.15 7.91683 10C7.91683 8.85 8.85016 7.91667 10.0002 7.91667ZM6.25016 10C6.25016 7.93333 7.9335 6.25 10.0002 6.25C12.0668 6.25 13.7502 7.93333 13.7502 10C13.7502 12.0667 12.0668 13.75 10.0002 13.75C7.9335 13.75 6.25016 12.0667 6.25016 10Z"
            fill="black"
            fillOpacity="0.54"
          />
        </svg>
      )
    };
    return (
      <div className="page full-form">
        <div className="form_page_content">
          <div className="form_plus_picture">
            <form
              id="loginform"
              onSubmit={e => this.handle_login(e, this.state)}
            >
              <div className="title">
                <svg
                  width="212"
                  height="36"
                  viewBox="0 0 212 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M97.5098 30.7456H115.239V26.9441H101.651V19.7515H113.929V15.951H101.651V8.97779H115.018V5.17725H97.5098V30.7456Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M71.2729 8.97781H81.6306C82.2991 8.97373 82.9662 9.04119 83.6206 9.17906C84.2299 9.30902 84.8121 9.54428 85.3414 9.87436C85.8476 10.202 86.2643 10.6517 86.5536 11.1825C86.8707 11.8212 87.0265 12.5286 87.0071 13.2422C87.0267 13.9471 86.8708 14.6458 86.5536 15.2748C86.2679 15.8061 85.8503 16.2539 85.3414 16.5749C84.814 16.909 84.2311 17.1449 83.6206 17.2712C82.9667 17.4129 82.2994 17.4827 81.6306 17.4795H71.2729V30.7456H75.4172V21.1744H80.5825L88.2113 30.7456H93.1854L85.0441 20.7377C85.8674 20.5425 86.6643 20.2482 87.4175 19.8612C88.1545 19.4788 88.8219 18.974 89.3915 18.368C89.9778 17.7318 90.4324 16.9848 90.7289 16.1704C91.0737 15.2106 91.2432 14.1961 91.2294 13.1758C91.2471 12.092 91.0572 11.0148 90.6698 10.0032C90.3288 9.14545 89.8109 8.37007 89.1503 7.72807C88.5095 7.10448 87.7639 6.59994 86.948 6.23784C86.1008 5.85804 85.2106 5.58366 84.2973 5.42078C83.3571 5.2572 82.4046 5.17573 81.4504 5.17727H71.2729V8.97781Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23.0706 5.17725L13.4047 19.823L3.74183 5.17725H0V30.7456H4.14124V12.8095L12.1685 24.9709H14.64L22.6712 12.8498V30.7456H26.8124V5.17725H23.0706Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M31.1318 18.0026C31.1312 14.4424 32.1809 10.962 34.1481 8.0015C36.1153 5.041 38.9116 2.73336 42.1835 1.37039C45.4555 0.00742658 49.056 -0.34965 52.5298 0.344318C56.0036 1.03829 59.1946 2.75213 61.6994 5.26912C64.2042 7.78612 65.9103 10.9932 66.6018 14.4849C67.2934 17.9765 66.9393 21.5958 65.5845 24.8852C64.2297 28.1746 61.9349 30.9863 58.9904 32.9647C56.0459 34.9431 52.5839 35.9994 49.0422 36C44.2937 35.9979 39.7403 34.1013 36.3821 30.7267C33.0239 27.3522 31.1355 22.7757 31.1318 18.0026Z"
                      fill="#00E3FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M41.4915 9.65967L35.9038 17.5516V18.4441L41.4915 26.337H46.4146L40.5616 18.0023L46.4146 9.65967H41.4915Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M56.5969 9.65967L62.1846 17.5516V18.4441L56.5969 26.337H51.6738L57.5278 18.0003L51.6738 9.65967H56.5969Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M128.702 9.65967L134.294 17.5516V18.4441L128.702 26.337H123.779L129.637 18.0003L123.779 9.65967H128.702Z"
                      fill="#00E3FF"
                    />
                    <path
                      d="M152.206 5.5166H154.108L165.061 30.7489H162.704L159.813 24.0202H146.463L143.534 30.7489H141.253L152.206 5.5166ZM158.9 21.9947L153.12 8.46084L147.339 21.9947H158.9Z"
                      fill="black"
                    />
                    <path
                      d="M168.639 5.51709H176.398C185.107 5.51709 190.09 10.1055 190.09 18.1332C190.09 26.1237 185.108 30.7494 176.398 30.7494H168.639V5.51709ZM176.36 28.7238C183.814 28.7238 187.846 24.9766 187.846 18.1342C187.846 11.2919 183.814 7.54666 176.36 7.54666H170.845V28.7238H176.36Z"
                      fill="black"
                    />
                    <path
                      d="M193.593 28.4564V25.8603C195.533 27.9251 198.842 29.1487 202.568 29.1487C207.209 29.1487 209.796 27.2761 209.796 24.0259C209.796 21.4641 208.194 20.0493 204.204 19.1709L200.059 18.2532C195.722 17.2973 193.821 15.2345 193.821 12.0216C193.821 7.70182 197.245 5.06348 202.949 5.06348C206.181 5.06348 209.072 5.86846 210.746 7.27719V9.79982C209.034 8.07916 206.141 7.12324 202.912 7.12324C198.575 7.12324 196.028 8.92037 196.028 11.9018C196.028 14.196 197.51 15.4961 201.351 16.3373L205.612 17.254C210.137 18.2099 212.001 20.5031 212.001 23.9062C212.001 28.5711 208.541 31.2084 202.531 31.2084C198.804 31.2084 195.494 30.2153 193.593 28.4564Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="212" height="36" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              {this.state.first_time && (
                <div className="login_msg">
                  <div>Регистрация успешно завершена</div>
                  <div>Войдите в систему</div>
                </div>
              )}
              {this.state.status == "error" && (
                <div className="msg_error">Неверный логин или пароль</div>
              )}
              {/* <div className="form-field-name">Электронная почта</div> */}
              <input
                type="text"
                name="email"
                maxLength="254"
                id="id_username"
                className="form_input"
                placeholder="Электронная почта"
                value={this.state.email}
                onChange={this.handle_change}
              />
              {/* <div className="form-field-name">Пароль</div> */}
              <input
                type="password"
                name="password"
                required=""
                id="id_password"
                className="form_input"
                placeholder="Пароль"
                value={this.state.password}
                onChange={this.handle_change}
              />
              <div className="eye" onClick={this.toggle_eye}>
                {eye_code[this.state.password_visibility]}
              </div>
              <button className="btn" type="submit">
                Войти
              </button>
              <div className="login-footer">
                <Link to={URLMAP.get("reg")} id="register">
                  Подключиться
                </Link>
                <Link to={URLMAP.get("restore")} id="forgot">
                  Забыли пароль?
                </Link>
                <div style={{ height: "64px" }}></div>
                <div className="help">Помощь</div>
                <div className="author">2019 WEB-Regata</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
