import ChannelAccount from './ChannelAccount';
import PropTypes from 'prop-types';

export default class FBAccount extends ChannelAccount {

    constructor(params) {
        super();
        this.auth_url = "https://www.facebook.com/v3.2/dialog/oauth";
        this.auth_url += "?client_id="+params["client_id"]+"&redirect_uri=https://"+location.host+"/api/channel_auth/fb/init_token/&scope=ads_management&state="+localStorage.getItem("token");
    }

}

FBAccount.PropTypes = {
    params: PropTypes.number.isRequired,

};