import React from "react";
import $ from "jquery";

export default class ChannelCabinet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "text"
    }
  }
  componentDidMount() {
    var options = {};
    var elems = document.querySelectorAll(".dropdown-trigger");
    var instances = M.Dropdown.init(elems, options);
    var elems2 = document.querySelectorAll("select");
    var instances2 = M.FormSelect.init(elems2, options);
  }

  show_options = () => {
    this.setState({ view: "checkboxes" })
  }

  hide_options = (e) => {
    this.props.triggerCheckbox(e)
    this.setState({ view: "text" }, this.forceUpdate())
  }

  render() {
    const cabinet = this.props.cabinet;
    const selected_object = this.props.selected_object;
    // console.log('selected_object, ', selected_object)
    // console.log('this.state.checked_groups, ', this.state.checked_groups);
    console.log('cabinet, ', cabinet)
    return (
      <div id={cabinet[0]} className="cabinet">
        <span title={cabinet[1].name}>{cabinet[1].name}</span>
        {this.state.view == "checkboxes" && <div className="groups checkboxes">
          <div className="option_holder"
            // onMouseLeave={(e) => this.props.triggerCheckbox(e)}
            onMouseLeave={(e) => this.hide_options(e)}
          >{Object.entries(cabinet[1].groups).length ? Object.entries(cabinet[1].groups).map(group => {
            return (
              <div id={group[0]} key={group[0]}>
                <label>
                  <input type="checkbox" defaultChecked={selected_object.indexOf(group[0]) != -1 ? true : false} />
                  <span>{group[1]}</span>
                </label>
              </div>
            )
          }) : <div >Нет созданных групп</div>}</div>
        </div>}
        {this.state.view == "text" && <div className="groups text" onClick={this.show_options}>{selected_object.length ? selected_object.map((group, index) => {
          return (
            <span id={group} key={group}>
              {index < selected_object.length - 1 ? `${cabinet[1].groups[group]}, ` : cabinet[1].groups[group]}
            </span>
          )
        }) : (Object.entries(cabinet[1].groups).length ? <div style={{ fontWeight: "bold" }}>Добавить группы</div> : <div style={{ fontWeight: "bold" }}>Будет создана новая группа</div>)}</div>}
        <div className="remove" onClick={(e) => this.props.remove_cabinet(e)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 6.4L17.6 5L12 10.6L6.4 5L5 6.4L10.6 12L5 17.6L6.4 19L12 13.4L17.6 19L19 17.6L13.4 12L19 6.4Z"
              fill="black"
              fillOpacity="0.54"
            />
          </svg>
        </div>
      </div>
    );
  }
  // componentDidUpdate() {
  // var options = {};
  // var elems = document.querySelectorAll(".dropdown-trigger");
  // var instances = M.Dropdown.init(elems, options);
  // var elems2 = document.querySelectorAll("select");
  // var instances2 = M.FormSelect.init(elems2, options);
  // }
}
