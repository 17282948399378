import React from 'react'

export default class LoadingIndicator extends React.PureComponent {

    render() {
        return (
            <span className="loading_wrapper loading_wrapper__transparent">
                <span className="loading_ico_black">&nbsp;</span>
            </span>
        )
    }
}