import React from 'react'
import { Link, Redirect } from 'react-router-dom'

export default class RuleVal extends React.Component {

    constructor(props) {
        super(props);
        this.init =this.init.bind(this);
        this.handlerChange =this.handlerChange.bind(this);
        this.isEmpty =this.isEmpty.bind(this);

        this.val = {};
    }

    handlerChange(e) {
        this.val[e.target.name] = e.target.value;
    }

    init(data) {
        this.val = data;
    }

    isEmpty() {
        return true;
    }
}