import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

export default class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "values": "",
        };
        this.handlerChange = this.handlerChange.bind(this);
        this.search = this.search.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState || this.props.items !== nextProps.items;
    }

    handlerChange(e) {
        let s = this.state;
        s[e.target.name] = e.target.value;
        this.setState(this.state);
        this.props.updateItems(this.search());
    }

    search() {
        var text = this.state.values.split(",");
        var ids = [];  // айди удовлетворяющих записей
        if (text[0].length == 0) {
            return 0;
        } else {
            this.props.items.forEach(function (item) {
                Object.keys(item).forEach(function (key) {
                    let value = item[key];
                    let flag = true;
                    if (typeof value == "string") {
                        value = value.toLowerCase();
                    } else if (typeof value == "number") {
                        value = value.toString();
                    } else {
                        flag = false;
                        // continue
                    }

                    if (flag) {
                        text.forEach(function (t) {
                            t = t.toLowerCase().trim();
                            if (t.length > 0 && value.includes(t)) {
                                if (!ids.includes(item.id))
                                    ids.push(item.id);
                            }
                        });
                    }
                });
            });
        }
        return ids;
    }

    render() {
        return (
            <input type="text" name="values" className={"search_field " + this.props.class} onChange={this.handlerChange} defaultValue={this.state.values} placeholder={this.props.placeholder} />
        );
    }
}

Search.propTypes = {
    updateItems: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired
};