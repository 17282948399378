import React from 'react'
import PropTypes from 'prop-types';
import '../../../styles/parts/_tip.scss'
import classnames from "classnames";

export default class ToolTip extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            "visibility": false,
        };
    }

    static positions () {
        return {
            BOTTOM: 1,
            RIGHT: 2,
        }
    }

    toggle() {
        this.setState({
            visibility: !this.state.visibility
        })
    }

    render() {
        const {visibility} = this.state;
        const {text, position, is_hidden} = this.props;

        const containerClasses = classnames({
            "container_tip": true,
            "container_tip_right": position == ToolTip.positions().RIGHT
        });

        const wrapperClasses = classnames({
            "wrapper_tip": true,
            "wrapper_tip_right": position == ToolTip.positions().RIGHT
        });

        const tipClasses = classnames({
            "tip": true,
            "tip_right": position == ToolTip.positions().RIGHT
        });

        const arrowClasses = classnames({
            "tip__arrow": true,
            "tip__arrow_right": position == ToolTip.positions().RIGHT
        });

        return (
            <div className={containerClasses} onMouseEnter={this.toggle} onMouseLeave={this.toggle}>
                { this.props.children}
                { (is_hidden==false && visibility) &&
                    <div className={wrapperClasses}>
                        <div className={tipClasses}>
                            <div className={arrowClasses}>&nbsp;</div>
                            <div className="tip__content">{text}</div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

ToolTip.propTypes = {
    children: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    is_hidden: PropTypes.bool,
};

ToolTip.defaultProps = {
    position: ToolTip.positions().BOTTOM,
    is_hidden: false
};