import React from 'react'
import Btn from "../../parts/Btn.jsx"
import BtnSwitch from "../../parts/BtnSwitch.jsx"
import LoadingIndicator from "../../parts/LoadingIndicator.jsx"
import Block from "./logic/Block.js"
import DiagramTree from "./DiagramTree.jsx"
import '../../../../styles/pages/_diagram.scss'
import "dragscroll"

export default class Diagram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "loading": true,
            "menu_block": {
                "visible": false,
                "x": "0%",
                "y": "0%",
                "block_id": null,
                "is_pos_options": null,
                "default_pos": null,
            },
            "blocks": {},
        };
        this.cancel = this.cancel.bind(this);
        this.parceBlocks = this.parceBlocks.bind(this);
        this.saveBlocks = this.saveBlocks.bind(this);
        this.showMenuBlock = this.showMenuBlock.bind(this);
        this.bindBlock = this.bindBlock.bind(this);
        this.unbindBlock = this.unbindBlock.bind(this);
        this.editBlock = this.editBlock.bind(this);
        this.markBlocks = this.markBlocks.bind(this);
        this.delBlock = this.delBlock.bind(this);
        this.addBlock = this.addBlock.bind(this);
        this.addBlockCondition = this.addBlockCondition.bind(this);
        this.addBlockOperation = this.addBlockOperation.bind(this);
        this.hideMenuBlock = this.hideMenuBlock.bind(this);
        this.initBlocks = this.initBlocks.bind(this);
        this.initLocations = this.initLocations.bind(this);
        this.clearEmptyLevels = this.clearEmptyLevels.bind(this);
        this.init = this.init.bind(this);
        this.switchPosNewBlock = this.switchPosNewBlock.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState;
    }

    componentDidMount() {
        let segment_id = location.pathname.split("/")[2];
        if (!isNaN(segment_id) && segment_id > 0) {
            UI.get('/api/diagram/' + segment_id + '/').then(function (data) {
                UI.set_current_segment(data.segment.name, data.segment.id);
                UI.amoFields = data.fields_entities;
                UI.comparisons = data.comparisons;
                UI.amo_tasks = data.amo_tasks;
                UI.amo_users = data.amo_users;
                UI.channel_codes = data.channel_codes;
                UI.channel_groups = data.channel_groups;
                this.setState({ blocks: this.parceBlocks(data.blocks), loading: false });
            }.bind(this));
        }
    }

    parceBlocks(blocks) {
        let parced_blocks = {};
        Object.keys(blocks).forEach(function (code) {
            let block = blocks[code];
            parced_blocks[code] = new Block({ limit: block.limit, count_customers: block.count_customers, links: block.links, inner_id: block.inner_id, type: block.type, mode: block.mode, rules: block.rules, next: block.next, });
        });
        return parced_blocks;
    }

    initBlocks(block, level = 0) {
        let counts = [];
        let next = block.next;

        // 1 тут мы определяем нужно ли сдвинуть родителя исходя из уровней его детей links, но которых нет в next
        // мы сдвинем блок к ребёнку с минимальным уровнем.
        // при этом чтобы произошел сдвиг, нужно чтобы минимальный уровен среди детей был выше уровня родителя
        let min_level = 99999;
        let flag_level = false;
        block["links"].forEach(function (next_block_code) {
            if (next_block_code != null && !block.next.includes(next_block_code)) {
                let child_block = this.state.blocks[next_block_code];
                if (child_block.level < min_level) {
                    min_level = child_block.level;
                    flag_level = true;
                }
            }
        }.bind(this));
        if (flag_level && min_level > level)
            level = min_level - 1;
        block["level"] = level;

        // 2 здесь мы создадим рекурсию
        block["links"].map(function (next_block_code) {
            if (next_block_code != null) {
                let child_block = this.state.blocks[next_block_code];
                let next_level = level + 1;
                next_level = child_block.level == undefined ? next_level : child_block.level < next_level ? next_level : child_block.level;
                let res = this.initBlocks(child_block, next_level);
                if (next.includes(next_block_code)) {
                    child_block.setMainParent(block);
                    block.setChildren(child_block.children.concat(next));
                    counts.push(res);
                } else {
                    child_block.setParents(block);
                }
            }
        }.bind(this));

        // 3 здесь мы подсичтаем ширину внутренних блоков и вернём её
        let main_count = block.setCountBlocksWidth(counts);
        return main_count;
    }

    // инициализирует location каждого блока. Нужно запускать после initBlocks, чтобы поле countBlocksWidth уже было заполнено
    initLocations(block, location = 0) {
        let next = block["next"];
        block.location = location;
        let flag = next[0] != null && next[1] != null ? -1 : 0;
        next.map(function (next_block_code) {
            if (next_block_code != null) {
                let child_block = this.state.blocks[next_block_code];
                let countWidth = 0;
                if (flag != 0) {
                    let sibling = this.state.blocks[next[flag == -1 ? 1 : 0]];
                    countWidth = sibling.countBlocksWidth;
                }
                //console.log(location + " " + flag + " " +countWidth);
                let next_location = location + flag * countWidth;
                this.initLocations(child_block, next_location);
            }
            if (flag == -1)
                flag = 1;
        }.bind(this));
    }

    // Убираем уровни, на которых нет блоков
    clearEmptyLevels(block, decrement_level = 0) {
        let level = block.level;
        let next_level = level + 1;
        let has_next_level = false;
        block.next.map(function (code) {
            if (code != null) {
                let child_block = this.state.blocks[code];
                if (child_block.level == next_level)
                    has_next_level = true;
            }
        }.bind(this));
        if (!has_next_level) {
            decrement_level++;
        }
        block.next.map(function (code) {
            if (code != null) {
                let child_block = this.state.blocks[code];
                child_block.level -= decrement_level;
                this.clearEmptyLevels(child_block, decrement_level);
            }
        }.bind(this));
    }

    init() {
        this.initBlocks(this.state.blocks[0]);
        this.initLocations(this.state.blocks[0]);
        this.clearEmptyLevels(this.state.blocks[0]);
        window.blocks = this.state.blocks;
    }

    showMenuBlock(options) {
        let s = this.state.menu_block;
        let current_block = this.state.blocks[options.block_id];
        s.block_id = options.block_id;
        s.is_pos_options = current_block.type == "condition";
        //s.default_pos = false;
        s.default_pos = typeof current_block.links[0] == "number";
        s.visible = true;
        s.x = options.x;
        s.y = options.y;
        this.setState({ menu_block: s });
    }

    hideMenuBlock() {
        let s = this.state.menu_block;
        if (s.visible) {
            s.visible = false;
            this.setState({ menu_block: s });
        }
    }

    switchPosNewBlock(callback) {
        let s = this.state.menu_block;
        let current_block = this.state.blocks[s.block_id];
        let flag = false;
        if ((current_block.links[1] == null && !s.default_pos) || (current_block.links[0] == null && s.default_pos))
            flag = true;
        callback(flag);
        if (flag) {
            s.default_pos = !s.default_pos;
            this.setState({ menu_block: s });
        }
    }

    cancel() {
        let s = this.state;
        let flag = false;
        if (s.menu_block.visible) {
            s.menu_block.visible = false;
            flag = true;
        }
        let blocks = this.state.blocks;
        for (let block_code in blocks) {
            let block = blocks[block_code];
            if (block["marked"] === true) {
                block["marked"] = false;
                flag = true;
            }
        }
        if (flag)
            this.setState(s);
    }

    addBlock(type) {
        let blocks = this.state.blocks;
        let menu_block = this.state.menu_block;
        let parent_block = blocks[menu_block.block_id];
        let countChildren = 0;
        parent_block.next.forEach(function (item_code) {
            if (item_code != null) {
                countChildren += 1;
            }
        });
        if ((countChildren < 2 && parent_block.type == "condition") || (countChildren < 1 && ["operation", "start"].includes(parent_block.type))) {
            let new_id = Math.max(...Object.keys(blocks)) + 1;
            if (menu_block.visible) {
                menu_block.visible = false;
            }
            let data = this.parceBlocks([{
                inner_id: new_id,
                type: type,
                next: [],
                links: [],
                rules: [],
                mode: null,
                count_customers: 0,
                limit: 0,
            }])[0];
            data["parent"] = blocks[parent_block.inner_id];
            data["pos"] = menu_block.default_pos;
            UI.showBlockEditModel(data).then(function () {
                if (parent_block.type == "condition") {
                    parent_block.next[data["pos"] ? 1 : 0] = new_id;
                    parent_block.links[data["pos"] ? 1 : 0] = new_id;
                    if (parent_block.next[data["pos"] ? 0 : 1] == undefined)
                        parent_block.next[data["pos"] ? 0 : 1] = null;
                    if (parent_block.links[data["pos"] ? 0 : 1] == undefined)
                        parent_block.links[data["pos"] ? 0 : 1] = null;
                } else {
                    parent_block.next = [new_id];
                    parent_block.links = [new_id];
                }

                blocks[new_id] = data;
                this.init();
                this.forceUpdate();
            }.bind(this), function () {
                parent_block.next[data["pos"] ? 1 : 0] = null;
                this.forceUpdate();
            }.bind(this));
        }
    }

    addBlockOperation(e) {
        e.stopPropagation();
        this.addBlock("operation");
    }

    addBlockCondition(e) {
        e.stopPropagation();
        this.addBlock("condition");
    }

    markBlocks(e) {
        e.stopPropagation();
        let menu_block = this.state.menu_block;
        if (menu_block.visible) {
            menu_block.visible = false;
        }
        let blocks = this.state.blocks;
        for (let block_code in blocks) {
            let block = blocks[block_code];
            if (block_code == 0 || block.children.includes(menu_block.block_id) || block.inner_id == menu_block.block_id || blocks[menu_block.block_id].links.includes(block.inner_id) || blocks[block.inner_id].links.includes(menu_block.block_id))
                block["marked"] = false;
            else
                block["marked"] = true;
        }
        this.forceUpdate();
    }

    bindBlock(id) {
        let menu_block = this.state.menu_block;
        let parent_block = this.state.blocks[menu_block.block_id];
        parent_block.links[menu_block.default_pos ? 1 : 0] = id;
        this.init();
        this.forceUpdate();
    }

    unbindBlock(params) {
        let child_id = params.child.inner_id;
        ["next", "links"].forEach(function (prop) {
            let index = params.parent[prop].indexOf(child_id);
            params.parent[prop][index] = null;
        });
        this.forceUpdate();
    }

    delBlock(id) {
        let blocks = this.state.blocks;
        blocks[id].del();
        delete blocks[id];
        this.setState({ blocks: blocks });
    }

    editBlock(id) {
        let blocks = this.state.blocks;
        let data = blocks[id].clone(); //Object.assign({}, blocks[id]);
        UI.showBlockEditModel(data).then(function () {
            blocks[id] = data;
            this.forceUpdate();
        }.bind(this), () => { });
    }

    saveBlocks(hideIndicator) {
        let segment_id = location.pathname.split("/")[2];
        let data_for_save = {};
        Object.keys(this.state.blocks).forEach(function (code) {
            let block = this.state.blocks[code];
            data_for_save[code] = block.serialize();
        }.bind(this));
        if (!isNaN(segment_id) && segment_id * 1 > 0) {
            UI.put('/api/diagram/' + segment_id + '/', {
                blocks: data_for_save,
            }).then(hideIndicator, hideIndicator);
        }
    }

    render() {
        if (this.state.blocks[0] != undefined) {
            this.init();
        }
        const loading_ico = this.state.loading && <LoadingIndicator />;
        const menu_blocks = <div className="menu_blocks js-menu_blocks" style={{ top: this.state.menu_block.y, left: this.state.menu_block.x, visibility: this.state.menu_block.visible ? "visible" : "hidden" }}>
            {this.state.menu_block.is_pos_options && <div className="menu_blocks__block_menu_options">
                Позиция: <BtnSwitch handler={this.switchPosNewBlock} value={this.state.menu_block.default_pos} size="min" titles={["Нет", "Да"]} />
            </div>}
            <div className="menu_blocks__wrapper">
                <div className="menu_blocks__block_menu_item" onClick={this.addBlockCondition}>
                    <div className="block_menu_image block_menu_image_condition"></div>
                    Условие
                </div>
                <div className="menu_blocks__block_menu_item" onClick={this.addBlockOperation}>
                    <div className="block_menu_image block_menu_image_operation"></div>
                    Действие
                </div>
                <div className="menu_blocks__block_menu_item" onClick={this.markBlocks}>
                    Выделить блок
                </div>
            </div>
        </div>;
        const panel = <div className="diagram__panel">
            <Btn handler={this.saveBlocks} text="сохранить" indicator={true} />
        </div>;
        return (
            <div className="content" onClick={this.cancel}>
                <div className="diagram_wrapper dragscroll">
                    {!this.state.loading ? panel : false}
                    {loading_ico}
                    {!this.state.loading ? <DiagramTree onUnbind={this.unbindBlock} onClick={this.bindBlock} onEdit={this.editBlock} onDel={this.delBlock} onMenu={this.showMenuBlock} blocks={this.state.blocks} start={[0]} /> : false}
                    {menu_blocks}
                </div>
            </div>
        );
    }
}