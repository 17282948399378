import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import Btn from "./Btn.jsx"
const Velocity = require('../../../../node_modules/velocity-react/lib/velocity-animate-shim');

export default class ModelForm extends React.Component {

    constructor(props) {
        super(props);
        this.handlerChange = this.handlerChange.bind(this);
    }

    componentWillEnter(callback) {
        let root = document.getElementsByClassName("js-model_form")[0];
        Velocity(root, 'fadeIn', { duration: 300, display: "flex" }).then(callback);
    }

    componentWillLeave(callback) {
        let root = document.getElementsByClassName("js-model_form")[0];
        Velocity(root, 'fadeOut', { duration: 300}).then(callback);
    }

    handlerChange(e) {
        let i = e.target.id;
        let field = this.props.fields[i];
        field.value = e.target.value;
    }

    render() {
        const fields = this.props.fields.map(function (field, index) {
            return <label key={index} className="model__field"><div className="model__field_text">{field.title}</div><input type="text" className="input_text model__field_input" defaultValue={field.value} id={index} onChange={this.handlerChange} name={field.name}/></label>
        }.bind(this));
        return (
            <div className='model_wrapper js-model_form'>
                <div className='model model__mid'>
                    <div className='model__msg'>{this.props.texts["title"]}</div>
                    <div className="model__wrapper_fields">
                        {fields}
                    </div>
                    <div className='model__control_panel control_panel__center'>
                        <Btn color="blue" width="100" text={this.props.texts["btn_ok"]} handler={this.props.handlerConfirm}/>
                        <Btn color="red" width="100" text="Отмена" handler={this.props.handlerCancel}/>
                    </div>
                </div>
            </div>
        );
    }
}

ModelForm.propTypes = {
    handlerConfirm: PropTypes.func.isRequired,
    handlerCancel: PropTypes.func.isRequired,
    texts: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
};