import React from "react";
import { Link, Route, Switch, Redirect } from "react-router-dom";
// import Header from "./Header.jsx"
import Body from "./Body.jsx";
import Login from "./Login.jsx";
import Reg from "./Reg.jsx";
import Restore from "./Restore.jsx";
import LoadingIndicator from "./parts/LoadingIndicator.jsx";
import NotFound404 from "./pages/NotFound404.jsx";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      has_interfase: false
    };
    this.render = this.render.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.onResolve = this.onResolve.bind(this);
    this.auth_by_morekey = this.auth_by_morekey.bind(this);
    this.onReject = this.onReject.bind(this);
    setInterval(
      function () {
        this.refreshToken();
      }.bind(this),
      1000 * 60 * 20
    );
  }

  componentDidMount() {
    window.is_iframe = false;
    let url = new URL(location.href);
    let morekey = url.searchParams.get("morekey");
    let user_id = url.searchParams.get("user_id");
    if (morekey != null && user_id != null) {
      window.is_iframe = true;
      this.auth_by_morekey({
        morekey: morekey,
        user_id: user_id
      });
      // TODO add auth by morekey
    }
    this.refreshToken()
      .then(this.getCurrentUser, this.onReject)
      .then(this.onResolve, this.onReject);
  }

  auth_by_morekey(data) {
    fetch("/auth-by-morekey/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        localStorage.setItem("token", json.token);
        User.logged_in = true;
        User.is_checked = true;
        User.fullname = json.user.fullname;
        User.role = json.user.user_role;
        User.id = json.user.id;
        User.is_a = User.role == "admin";
        User.is_m = User.is_a || User.role == "moderator";
        localStorage.setItem("fullname", json.user.fullname);
        this.props.history.push("/segments/");
      });
  }

  onResolve(json) {
    User.id = json.id;
    User.is_checked = true;
    User.role = json.user_role;
    User.is_a = User.role == "admin";
    User.is_m = User.is_a || User.role == "moderator";
    User.fullname = json.fullname;
    localStorage.setItem("fullname", json.fullname);
    localStorage.setItem("user_id", User.id);
    this.forceUpdate();
  }

  onReject() {
    return new Promise(
      function (resolve, reject) {
        localStorage.removeItem("token");
        localStorage.removeItem("fullname");
        localStorage.removeItem("user_id");
        User.role = "";
        User.fullname = "";
        User.logged_in = false;
        User.id = 0;
        User.is_checked = false;
        this.props.history.push(URLMAP.get("login"));
        this.setState({ checked: false });
      }.bind(this)
    );
  }

  getCurrentUser() {
    return new Promise(
      (resolve, reject) => {
        if (User.logged_in) {
          fetch("/user/current_user/", {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`
            }
          })
            .then(res => {
              if (res.status == 200) return res.json();
              else return { status: "error" };
            })
            .then(json => {
              if (json["status"] != undefined && json["status"] == "error") {
                reject();
              } else {
                resolve(json);
              }
            });
        }
      }
    );
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("token") != null) {
        fetch("/token-refresh/", {
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            token: localStorage.getItem("token")
          }),
          method: "POST"
        }).then(res => {
          if (res.status == 200) {
            console.log("refreshed");
            resolve(res);
          } else {
            reject();
          }
        });
      }
    });
  }

  updateMe = () => {
    this.forceUpdate();
  };

  render() {
    return (
      <React.Fragment>
        {/*<Header history={this.props.history}/>*/}
        {User.logged_in == false &&
          location.pathname != URLMAP.get("reg") &&
          location.pathname != URLMAP.get("login") &&
          location.pathname != URLMAP.get("restore") ? (
            <Redirect to={URLMAP.get("reg")} />
          ) : (
            false
          )}
        {User.logged_in == true && location.pathname == URLMAP.get("login") ? (
          <Redirect to={URLMAP.get("home")} />
        ) : (
            false
          )}
        <Switch>
          <Route exact path={URLMAP.get("reg")}>
            <Reg history={this.props.history} />
          </Route>
          <Route exact path={URLMAP.get("login")}>
            <Login history={this.props.history} updateApp={this.updateMe} />
          </Route>
          <Route exact path={URLMAP.get("restore")}>
            <Restore history={this.props.history} />
          </Route>
          <Route exact path={URLMAP.get("channels")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          {/* )} */}
          <Route exact path={URLMAP.get("users")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("segments")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("sources")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("data")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("segment")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("diagram")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("history")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("settings")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("balance")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path={URLMAP.get("home")}>
            {User.is_checked ? (
              <Body history={this.props.history} />
            ) : (
                <LoadingIndicator />
              )}
          </Route>
          <Route exact path="*">
            {/* {User.is_checked ? <Body history={this.props.history} /> : <LoadingIndicator />} */}
            <NotFound404 />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}
